export const Document = {
  name: 'Document',
  attrs: {
    editable: {
      name: 'editable',
      id: 'Document.editable',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'Document.id',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    path: {
      name: 'path',
      id: 'Document.path',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    publicUrl: {
      name: 'publicUrl',
      id: 'Document.publicUrl',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
