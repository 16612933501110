export const AdminNotificationAuditListItem = {
  name: 'AdminNotificationAuditListItem',
  attrs: {
    body: {
      name: 'body',
      id: 'AdminNotificationAuditListItem.body',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    createdAt: {
      name: 'createdAt',
      id: 'AdminNotificationAuditListItem.createdAt',
      type: {
        name: 'date'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    emailAddress: {
      name: 'emailAddress',
      id: 'AdminNotificationAuditListItem.emailAddress',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    emailId: {
      name: 'emailId',
      id: 'AdminNotificationAuditListItem.emailId',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'AdminNotificationAuditListItem.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    payload: {
      name: 'payload',
      id: 'AdminNotificationAuditListItem.payload',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    title: {
      name: 'title',
      id: 'AdminNotificationAuditListItem.title',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
