export const MyContractFilter = {
  name: 'MyContractFilter',
  attrs: {
    statuses: {
      name: 'statuses',
      id: 'MyContractFilter.statuses',
      type: {
        name: 'list',
        of: {
          name: 'string'
        }
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
