export const BoxGeometry = {
  name: 'BoxGeometry',
  attrs: {
    points: {
      name: 'points',
      id: 'BoxGeometry.points',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'Point'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    textPosition: {
      name: 'textPosition',
      id: 'BoxGeometry.textPosition',
      type: {
        name: 'object',
        of: 'Point'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
