export const AdminContractListItem = {
  name: 'AdminContractListItem',
  attrs: {
    boxName: {
      name: 'boxName',
      id: 'AdminContractListItem.boxName',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    contractBusinessId: {
      name: 'contractBusinessId',
      id: 'AdminContractListItem.contractBusinessId',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    createdAt: {
      name: 'createdAt',
      id: 'AdminContractListItem.createdAt',
      type: {
        name: 'date'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'AdminContractListItem.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    state: {
      name: 'state',
      id: 'AdminContractListItem.state',
      type: {
        name: 'enum',
        id: 'Contract$Status',
        of: ['ACTIVE', 'TERMINATING', 'EVICTED', 'TERMINATED']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'Contract$Status'
      }
    },
    tenant: {
      name: 'tenant',
      id: 'AdminContractListItem.tenant',
      type: {
        name: 'object',
        of: 'Tenant'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    terminationAt: {
      name: 'terminationAt',
      id: 'AdminContractListItem.terminationAt',
      type: {
        name: 'date'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    warehouse: {
      name: 'warehouse',
      id: 'AdminContractListItem.warehouse',
      type: {
        name: 'object',
        of: 'WarehouseInfo'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    // hasUnsolvedCancellationRequest: {
    //   name: 'hasUnsolvedCancellationRequest',
    //   id: 'AdminContractListItem.hasUnsolvedCancellationRequest',
    //   type: {
    //     name: 'boolean'
    //   },
    //   required: true,
    //   validationRules: [],
    //   extra: {}
    // }
  }
};
