export const ClientUserFilter = {
  name: 'ClientUserFilter',
  attrs: {
    businessId: {
      name: 'businessId',
      id: 'ClientUserFilter.businessId',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    email: {
      name: 'email',
      id: 'ClientUserFilter.email',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'ClientUserFilter.name',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    phoneNumber: {
      name: 'phoneNumber',
      id: 'ClientUserFilter.phoneNumber',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
