export const SetLocaleCmd = {
  name: 'SetLocaleCmd',
  attrs: {
    userLanguage: {
      name: 'userLanguage',
      id: 'SetLocaleCmd.userLanguage',
      type: {
        name: 'enum',
        id: 'Language',
        of: ['SK', 'CS', 'EN', 'UK', 'PL']
      },
      required: false,
      validationRules: [],
      extra: {
        enumId: 'Language'
      }
    }
  }
};
