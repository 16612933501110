export const Day = {
  name: 'Day',
  attrs: {
    day: {
      name: 'day',
      id: 'Day.day',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    month: {
      name: 'month',
      id: 'Day.month',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    year: {
      name: 'year',
      id: 'Day.year',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
