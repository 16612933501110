import { HigherApiClient } from '../../api/client/ApiClient'
import { apiOps } from '../../api/defs/apiOps'
import { RysAuditListItem } from '../../api/defs/RysAuditListItem.type'
import { RysAuditFilter } from '../../api/defs/RysAuditFilter.type'
import { strIdToNum } from '../../domain/strIdToNum'
import { DayInterval } from '../../api/defs/DayInterval.type'
import { ApiPageResponse } from '../../api/collection/ApiPageRequest'

export type RysFilter = {
    interval: DayInterval
    warehouseId: string | null
    contractId: string | null
}

export const toApiRysAuditFilter = (filter: RysFilter): RysAuditFilter => ({
    interval: filter.interval,
    warehouseId:
        filter.warehouseId == null ? undefined : strIdToNum(filter.warehouseId),
    contractId:
        filter.contractId == null ? undefined : strIdToNum(filter.contractId)
})

export class RysAuditApi {
    constructor(private api: HigherApiClient, private isOwner: () => boolean) {}

    // list = (req: ListReq<NoListFilter>): Promise<Page<RysAuditListItem>> =>
    //     this.api.getPage<RysAuditListItem, NoListFilter>(
    //         apiOps.RysAuditListItem.list,
    //         m.RysAuditListItem,
    //         req
    //     )

    rysAudits = (
        filter: RysFilter
    ): Promise<ApiPageResponse<RysAuditListItem>> =>
        this.api.do<ApiPageResponse<RysAuditListItem>>(
            this.rysaAuditOps().rysAudits,
            {
                queryParams: toApiRysAuditFilter(filter)
            }
        )

    private rysaAuditOps = () =>
        this.isOwner() ? apiOps.OwnerUserRysAudit : apiOps.AdminRysAudit
}
