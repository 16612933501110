export const WarehouseForOwnerFilter = {
  name: 'WarehouseForOwnerFilter',
  attrs: {
    code: {
      name: 'code',
      id: 'WarehouseForOwnerFilter.code',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    deactivated: {
      name: 'deactivated',
      id: 'WarehouseForOwnerFilter.deactivated',
      type: {
        name: 'boolean'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'WarehouseForOwnerFilter.name',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
