export const BooleanValue = {
  name: 'BooleanValue',
  attrs: {
    value: {
      name: 'value',
      id: 'BooleanValue.value',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
