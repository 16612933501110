import React, { ComponentType } from 'react'
import { Form, FormProps } from '@ps-aux/react-app-core'

export type UiFormProps<Data> = Omit<FormProps<Data>, 'children'> & {
    children: React.ReactNode
}

export const UiForm: ComponentType<UiFormProps<any>> = ({
    children,
    ...rest
}) => (
    <Form {...rest}>
        {({ handleSubmit }) => <form onSubmit={handleSubmit}>{children}</form>}
    </Form>
)
