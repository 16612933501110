export const Money = {
  name: 'Money',
  attrs: {
    value: {
      name: 'value',
      id: 'Money.value',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
