import { Ref, useEffect, useRef } from 'react'

export const onClickOutside = <T extends Element>(
    handle: () => void
): Ref<T> => {
    const node = useRef<T>(null)

    const handleClick = (e: MouseEvent) => {
        const target = e.target as T
        if (!node.current?.contains(target)) {
            handle()
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClick)

        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [])
    return node
}
