export const PromoDiscount = {
  name: 'PromoDiscount',
  attrs: {
    rate: {
      name: 'rate',
      id: 'PromoDiscount.rate',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    type: {
      name: 'type',
      id: 'PromoDiscount.type',
      type: {
        name: 'enum',
        id: 'PromoType',
        of: [
          'Easter',
          'Christmas',
          'Snowflake',
          'Gem',
          'Gift',
          'Heart',
          'Leaf',
          'Sun',
          'Umbrella'
        ]
      },
      required: false,
      validationRules: [],
      extra: {
        enumId: 'PromoType'
      }
    }
  }
};
