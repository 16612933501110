import { HigherApiClient } from 'src/api/client/ApiClient'
import { ListReq, NoListFilter } from 'src/model/api/ListReq'
import { Page } from 'src/model/api/Page'
import { apiOps } from 'src/api/defs/apiOps'
import { JobRun } from 'src/api/defs/JobRun.type'
import { m } from 'src/api/model/model'
import { SuperAdminJobsRestApi$Job } from 'src/api/defs/enums.type'
import { Day } from 'src/api/defs/Day.type'

const toLocalDateTimeStr = (d: Day): string => {
    const pad = (n: number): string => n.toString().padStart(2, '0')

    return `${d.year}-${pad(d.month)}-${pad(d.day)}T12:00`
}

export class JobsApi {
    constructor(private api: HigherApiClient) {}

    runList = (req: ListReq<NoListFilter>): Promise<Page<JobRun>> =>
        this.api.getPage<JobRun, NoListFilter>(
            apiOps.Jobs.runList,
            m.JobRun,
            req
        )

    runDetail = (id: string): Promise<JobRun> =>
        this.api.doForId<JobRun>(apiOps.Jobs.runDetail, m.JobRun, id)

    startJob = (job: SuperAdminJobsRestApi$Job, day: Day): Promise<void> =>
        this.api.do(apiOps.Jobs.startJob, {
            pathParams: {
                job
            },
            data: {
                time: toLocalDateTimeStr(day)
            }
        })
}
