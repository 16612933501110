import { ERROR_SK } from './error_sk'

export const ERROR_EN = {
    lang: 'en',
    messages: {
        'error.invalid-credentials': 'Invalid credentials',
        'error.invalid-email': 'Invalid email',
        'error.mustBeChecked': 'Must be checked',
        'error.passwordsMustMatch': 'Passwords must match',
        'error.validation/invalid-args': 'Invalid arguments',

        'error.revalid/rule/basic/not-null': 'This field is required',
        'error.revalid/rule/basic/not-empty': 'Must not be empty',
        'error.revalid/rule/basic/regex': 'Incorrect Format',
        'error.revalid/rule/object/matches-schema': 'Incomplete Information',

        'error.podorys/invalidGeometryFormat': 'Invalid geometry format',
        'error.Warehouse/invalid-geometry-input': 'Invalid geometry input',

        'error.lock-v2/too-far-away': 'You are too far away from the Lock.',

        'error.isGreaterThanZero/invalid': 'Value must be greater than 0',

        // Specific error codes from the provided error object
        'AssertTrue.createBoxCmd.lock.versionWithLockSet':
            'The lock version must be true for createBoxCmd.',
        'AssertTrue.lock.versionWithLockSet': 'The lock version must be true.',
        'AssertTrue.versionWithLockSet': 'The version must be true.',
        'AssertTrue.boolean': 'This field must be true.',
        AssertTrue: 'The value must satisfy the assertion.',

        // Argument-specific error
        'lock.versionWithLockSet': 'The lock version with lock set is invalid.',

        // Fallbacks for unknown cases
        'error.unknown': 'An unknown error occurred.',

        'Internal Server Error': 'Server error'
    }
}

export type ErrorKeys = keyof typeof ERROR_EN.messages | string
