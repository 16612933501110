import React, { useState, useEffect, useRef, ComponentType } from 'react'
import type { CountryCodes, CustomLabel, CustomLabels, OnSelect } from './types'
import { Icon } from 'src/ui/basic/icon/Icon'
import { icon } from 'src/ui/basic/icon/icons'
import { css } from '../style/css'
import { View } from '../basic/View'
import { Language } from 'src/api/defs/enums.type'
import flags from './flags'

// for flags .svg files check https://www.npmjs.com/package/react-flags-select

export const getCountryCodes = (countries?: CountryCodes): CountryCodes => {
    const fullCountryCodes = Object.keys(Language)

    if (!countries) {
        return fullCountryCodes
    }

    return countries
}

type Props = {
    selected: string
    onSelect: OnSelect
    showSelectedLabel?: boolean
    showOptionLabel?: boolean
    customLabels?: CustomLabels
    countries?: CountryCodes
    id?: string
}

export const CountrySelector: ComponentType<Props> = ({
    selected,
    onSelect,
    showSelectedLabel = true,
    showOptionLabel = true,
    customLabels = {},
    countries,
    id
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
    const [countriesOptions, setCountriesOptions] = useState<CountryCodes>([])
    const selectedFlagRef = useRef(null)
    const optionsRef = useRef(null)
    const filterTextRef = useRef(null)

    const validSelectedValue = countriesOptions.includes(selected)
        ? selected
        : Language.EN

    const getFlag = (name: string): React.ReactElement => {
        const flagName = name.toUpperCase()
        return (
            <img
                className={'flag'}
                src={flags[flagName as keyof typeof flags]}
                alt={name}
            />
        )
    }

    const closeDropdown = (e: MouseEvent) => {
        if (
            e.target !== selectedFlagRef.current &&
            e.target !== optionsRef.current &&
            e.target !== filterTextRef.current
        ) {
            setIsDropdownOpen(false)
        }
    }

    useEffect(() => {
        setCountriesOptions(getCountryCodes(countries))
    }, [countries])

    useEffect(() => {
        window.addEventListener('click', closeDropdown)

        return () => {
            window.removeEventListener('click', closeDropdown)
        }
    }, [])

    const displayLabel = customLabels[validSelectedValue]
    return (
        <View horizontal>
            <div className={'flagsSelect'} id={id} data-testid="rfs">
                <button
                    ref={selectedFlagRef}
                    id="rfs-btn"
                    type="button"
                    className={'selectBtn'}
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    aria-labelledby="rfs-btn"
                    aria-haspopup="listbox"
                    aria-expanded={isDropdownOpen}
                    data-testid="rfs-btn"
                >
                    <span className={'selectValue'}>
                        <>
                            <span
                                className={'selectFlag'}
                                data-testid="rfs-selected-flag"
                            >
                                {getFlag(validSelectedValue)}
                            </span>
                            {showSelectedLabel && (
                                <span className={'label'}>
                                    {typeof displayLabel === 'object'
                                        ? (displayLabel as CustomLabel).primary
                                        : displayLabel}
                                </span>
                            )}
                            <span
                                className={'icon'}
                                data-testid="rfs-selected-flag"
                            >
                                <Icon
                                    icon={
                                        isDropdownOpen
                                            ? icon.UpSimpleArrow
                                            : icon.DownSimpleArrow
                                    }
                                    css={css`
                                        font-size: 1rem;
                                    `}
                                />
                            </span>
                        </>
                    </span>
                </button>
                {isDropdownOpen && (
                    <ul
                        tabIndex={-1}
                        role="listbox"
                        ref={optionsRef}
                        className={'selectOptions'}
                    >
                        {countriesOptions.map(countryCode => {
                            const countryLabel = customLabels[countryCode]

                            return (
                                <li
                                    key={countryCode}
                                    id={`rfs-${countryCode}`}
                                    role="option"
                                    tabIndex={0}
                                    className={'selectOption'}
                                    onClick={() => onSelect(countryCode)}
                                >
                                    <span className={'selectOptionValue'}>
                                        <span className={'flagOption'}>
                                            {getFlag(countryCode)}
                                        </span>
                                        {showOptionLabel && (
                                            <span className={'label'}>
                                                {typeof countryLabel ===
                                                'object'
                                                    ? (
                                                          countryLabel as CustomLabel
                                                      ).primary
                                                    : countryLabel}
                                            </span>
                                        )}
                                    </span>
                                </li>
                            )
                        })}
                    </ul>
                )}
            </div>
        </View>
    )
}
