export const AdminRentFilter = {
  name: 'AdminRentFilter',
  attrs: {
    box: {
      name: 'box',
      id: 'AdminRentFilter.box',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    owner: {
      name: 'owner',
      id: 'AdminRentFilter.owner',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    paid: {
      name: 'paid',
      id: 'AdminRentFilter.paid',
      type: {
        name: 'boolean'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    tenant: {
      name: 'tenant',
      id: 'AdminRentFilter.tenant',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    warehouse: {
      name: 'warehouse',
      id: 'AdminRentFilter.warehouse',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
