import { Theme } from 'src/ui/style/theme/Theme'
import { css } from 'src/ui/style/css'
import { mediaMaxWidth } from 'src/ui/style/mixins/helpers'
import { SerializedStyles } from '@emotion/utils'

export const columnMaxWidth =
    (maxWidth = '20em') =>
    (th: Theme): SerializedStyles =>
        css`
            > * {
                max-width: ${maxWidth};
                width: 100%;
                // Turns off max-width limitation
                ${mediaMaxWidth(th.screens.md)} {
                    max-width: none;
                }
            }
        `
