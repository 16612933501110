export const WarehouseCreateCmd = {
  name: 'WarehouseCreateCmd',
  attrs: {
    accessibility: {
      name: 'accessibility',
      id: 'WarehouseCreateCmd.accessibility',
      type: {
        name: 'object',
        of: 'Accessibility'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    address: {
      name: 'address',
      id: 'WarehouseCreateCmd.address',
      type: {
        name: 'object',
        of: 'Address'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    code: {
      name: 'code',
      id: 'WarehouseCreateCmd.code',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [
        {
          name: 'length',
          value: {
            min: 3,
            max: 3
          }
        }
      ],
      extra: {}
    },
    coords: {
      name: 'coords',
      id: 'WarehouseCreateCmd.coords',
      type: {
        name: 'object',
        of: 'Coordinates'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    discounts: {
      name: 'discounts',
      id: 'WarehouseCreateCmd.discounts',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'WarehouseDiscount'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    features: {
      name: 'features',
      id: 'WarehouseCreateCmd.features',
      type: {
        name: 'object',
        of: 'Features'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    lock: {
      name: 'lock',
      id: 'WarehouseCreateCmd.lock',
      type: {
        name: 'object',
        of: 'WarehouseLock'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'WarehouseCreateCmd.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    ownerId: {
      name: 'ownerId',
      id: 'WarehouseCreateCmd.ownerId',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    prices: {
      name: 'prices',
      id: 'WarehouseCreateCmd.prices',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'WarehouseBoxPrice'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
