export const WarehouseEditCmd = {
  name: 'WarehouseEditCmd',
  attrs: {
    accessibility: {
      name: 'accessibility',
      id: 'WarehouseEditCmd.accessibility',
      type: {
        name: 'object',
        of: 'Accessibility'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    address: {
      name: 'address',
      id: 'WarehouseEditCmd.address',
      type: {
        name: 'object',
        of: 'Address'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    coords: {
      name: 'coords',
      id: 'WarehouseEditCmd.coords',
      type: {
        name: 'object',
        of: 'Coordinates'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    discounts: {
      name: 'discounts',
      id: 'WarehouseEditCmd.discounts',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'WarehouseDiscount'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    features: {
      name: 'features',
      id: 'WarehouseEditCmd.features',
      type: {
        name: 'object',
        of: 'Features'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'WarehouseEditCmd.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    prices: {
      name: 'prices',
      id: 'WarehouseEditCmd.prices',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'WarehouseBoxPrice'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
