import { useMemo } from 'react'

export const useNonRerenderState = <T>(
    val: T
): [{ value: T }, (t: T) => void] => {
    const holder = useMemo(() => ({ value: val }), [])
    return [
        holder,
        (val: T) => {
            holder.value = val
        }
    ]
}
