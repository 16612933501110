export const WarehouseV1Lock = {
  name: 'WarehouseV1Lock',
  attrs: {
    deviceId: {
      name: 'deviceId',
      id: 'WarehouseV1Lock.deviceId',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
