import { InputComponent } from 'src/ui/basic/input/types'
import React, { ComponentType, ReactNode } from 'react'
import { Field } from '@ps-aux/react-app-core'
import { FieldUi } from 'src/form/ui/FieldUi'

export type UiFieldProps<C extends InputComponent> = {
    name: string
    comp: C
    label: ReactNode
    required?: boolean
    labelPosition?: LabelPosition
    props?: any
}
type LabelPosition = 'top' | 'right'

export type FieldUiProps = {
    label: ReactNode
    required?: boolean
    labelPosition?: LabelPosition
    errors?: string[]
    children: ReactNode
}

export const UiField: ComponentType<UiFieldProps<any>> = ({
    name,
    comp: Comp,
    label,
    required = false,
    labelPosition,
    props
}) => {
    return (
        <Field name={name}>
            {({ errors, input, ...p }) => (
                <FieldUi
                    label={label}
                    required={required}
                    labelPosition={labelPosition}
                    errors={errors}
                >
                    <Comp
                        {...p}
                        {...props}
                        name={name}
                        input={{
                            value: input.value,
                            onChange: input.onChange
                        }}
                    />
                </FieldUi>
            )}
        </Field>
    )
}
