import { getAppContext } from 'src/context/AppContext'
import React, { ComponentType } from 'react'
import { TextInput } from 'src/ui/basic/input/TextInput'
import { UiField } from 'src/form/ui/UiField'
import { notNull, treeValidator } from '@ps-aux/revalid'
import { PageContent } from 'src/layout/PageContent'
import { SimpleForm } from 'src/form/ui/SimpleForm'
import { useValidate } from 'src/validation/useValidate'
import { Heading } from 'src/ui/text/Heading'
import { useTran } from '@ps-aux/react-app-core'
import { msg } from 'src/i18n/texts/keys'

const validateForm = treeValidator({
    username: [notNull()],
    password: [notNull()]
}).validate

export const LoginPage: ComponentType = () => {
    const logIn = getAppContext().user.service.logIn
    const t = useTran()

    return (
        <PageContent>
            <Heading level={1}>{t(msg.loginPage.title)}</Heading>
            <SimpleForm
                onSubmit={data =>
                    logIn({
                        username: data.username,
                        password: data.password
                    })
                }
                validate={useValidate(validateForm)}
                submitText={t(msg.loginPage.title)}
            >
                <UiField name="username" comp={TextInput} label="Username" />
                <UiField
                    name="password"
                    comp={(props: any) => <TextInput password {...props} />}
                    label="Password"
                />
            </SimpleForm>
        </PageContent>
    )
}
