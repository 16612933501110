export const MyRentFilter = {
  name: 'MyRentFilter',
  attrs: {
    unpaid: {
      name: 'unpaid',
      id: 'MyRentFilter.unpaid',
      type: {
        name: 'boolean'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    yearPaid: {
      name: 'yearPaid',
      id: 'MyRentFilter.yearPaid',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
