import { useWindowResize } from 'src/ui/dom/useWindowResize'
import { useMountedState } from 'src/ui/react/useMountedState'

const sizes = {
    md: 768
}

export const ScreenSize = {
    sizes,
    isMobileScreen: (size: number) => size <= sizes.md
}

export const isMobileScreen = () => {
    const [v, setV] = useMountedState(
        ScreenSize.isMobileScreen(window.innerWidth)
    )

    useWindowResize(w => setV(ScreenSize.isMobileScreen(w)))

    return v
}
