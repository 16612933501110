export const AutoregistrationInvoicingDetailErrors = {
  name: 'AutoregistrationInvoicingDetailErrors',
  attrs: {
    email: {
      name: 'email',
      id: 'AutoregistrationInvoicingDetailErrors.email',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    phone: {
      name: 'phone',
      id: 'AutoregistrationInvoicingDetailErrors.phone',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
