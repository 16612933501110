export const InvoiceItems = {
  name: 'InvoiceItems',
  attrs: {
    items: {
      name: 'items',
      id: 'InvoiceItems.items',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'InvoiceItem'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    totalPrice: {
      name: 'totalPrice',
      id: 'InvoiceItems.totalPrice',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
