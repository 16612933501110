export const BoxLock = {
  name: 'BoxLock',
  attrs: {
    v1: {
      name: 'v1',
      id: 'BoxLock.v1',
      type: {
        name: 'object',
        of: 'BoxV1Lock'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    v2: {
      name: 'v2',
      id: 'BoxLock.v2',
      type: {
        name: 'object',
        of: 'BoxV2Lock'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    version: {
      name: 'version',
      id: 'BoxLock.version',
      type: {
        name: 'enum',
        id: 'LockVersion',
        of: ['V1', 'V2']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'LockVersion'
      }
    }
  }
};
