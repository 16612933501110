export const EntityCreated = {
  name: 'EntityCreated',
  attrs: {
    id: {
      name: 'id',
      id: 'EntityCreated.id',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
