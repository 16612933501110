export const DayCmd = {
  name: 'DayCmd',
  attrs: {
    time: {
      name: 'time',
      id: 'DayCmd.time',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
