export const CancelContractCmd = {
  name: 'CancelContractCmd',
  attrs: {
    day: {
      name: 'day',
      id: 'CancelContractCmd.day',
      type: {
        name: 'object',
        of: 'Day'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
