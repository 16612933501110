export const AutoregistrationPlacedOrderResponse = {
  name: 'AutoregistrationPlacedOrderResponse',
  attrs: {
    info: {
      name: 'info',
      id: 'AutoregistrationPlacedOrderResponse.info',
      type: {
        name: 'object',
        of: 'PlacedOrderInfo'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    token: {
      name: 'token',
      id: 'AutoregistrationPlacedOrderResponse.token',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
