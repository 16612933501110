export const RegistrationResponse = {
  name: 'RegistrationResponse',
  attrs: {
    clientId: {
      name: 'clientId',
      id: 'RegistrationResponse.clientId',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    token: {
      name: 'token',
      id: 'RegistrationResponse.token',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
