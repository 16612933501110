import { css } from '@emotion/react'
import { Theme } from 'src/ui/style/theme/Theme'
import 'minireset.css'

export const createGlobalCss = (th: Theme) => css`
    body {
        font-family: ${th.fonts.primary};
        color: ${th.fontColor.primary};
        font-size: 0.85em;
        padding: 0;
        margin: 0;
        overflow-y: scroll;
        * {
            font-family: ${th.fonts.primary};
            &:focus {
                outline: ${th.outline};
            }
        }
    }
    a {
        color: ${th.colors.primary};
        text-decoration: none;
        &:hover {
            color: ${th.colors.secondary};
            text-decoration: none;
        }
    }
`
