export const ReportError = {
  name: 'ReportError',
  attrs: {
    context: {
      name: 'context',
      id: 'ReportError.context',
      type: {
        name: 'object',
        of: 'BrowserErrorContext'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    error: {
      name: 'error',
      id: 'ReportError.error',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    stacktrace: {
      name: 'stacktrace',
      id: 'ReportError.stacktrace',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
