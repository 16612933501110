export const RysAuditFilter = {
  name: 'RysAuditFilter',
  attrs: {
    interval: {
      name: 'interval',
      id: 'RysAuditFilter.interval',
      type: {
        name: 'object',
        of: 'Interval'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
