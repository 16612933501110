import React, { ComponentType } from 'react'
import { InputComponentProps } from 'src/ui/basic/input/types'
import { FormControl } from 'react-bootstrap'

export const TextInput: ComponentType<
    InputComponentProps & {
        placeholder?: string
        password?: boolean
    }
> = ({ input, onBlur, placeholder, password = false }) => (
    <FormControl
        value={input.value === null ? '' : input.value}
        type={password ? 'password' : 'text'}
        onBlur={onBlur}
        onChange={e => input.onChange(e.target.value)}
        placeholder={placeholder}
    />
)
