export const SwaggerPageable = {
  name: 'SwaggerPageable',
  attrs: {
    page: {
      name: 'page',
      id: 'SwaggerPageable.page',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    size: {
      name: 'size',
      id: 'SwaggerPageable.size',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    sort: {
      name: 'sort',
      id: 'SwaggerPageable.sort',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
