export const BoxCameraOption = {
  name: 'BoxCameraOption',
  attrs: {
    id: {
      name: 'id',
      id: 'BoxCameraOption.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    monthlyPrice: {
      name: 'monthlyPrice',
      id: 'BoxCameraOption.monthlyPrice',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
