export const InvoicePreview = {
  name: 'InvoicePreview',
  attrs: {
    details: {
      name: 'details',
      id: 'InvoicePreview.details',
      type: {
        name: 'object',
        of: 'InvoicingDetails'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    itemData: {
      name: 'itemData',
      id: 'InvoicePreview.itemData',
      type: {
        name: 'object',
        of: 'InvoiceItems'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
