import { AttributeRenderConfig } from 'src/model/attribute/rendering'
import React, { ComponentType } from 'react'
import { Text } from 'src/ui/basic/Text'
import { VatPrice } from 'src/api/defs/VatPrice.type'
import { RenderValue } from 'src/model/value'
import { useFormatters } from 'src/formatters/useFormatters'

const VatPriceValue: ComponentType<{ value: VatPrice }> = ({ value }) => {
    const { strMoney } = useFormatters()
    const money = strMoney(value.priceWithVat)
    return <Text>{money}</Text>
}

export const RenderVatPriceValue: RenderValue<VatPrice> = value => (
    <VatPriceValue value={value} />
)

export const VatPriceRenderingCfg: AttributeRenderConfig<VatPrice> = {
    renderer: RenderVatPriceValue,
    editor: () => {
        throw new Error('Editing unsupported')
    },
    defaultValue: () => ({})
}
