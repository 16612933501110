import { css } from 'src/ui/style/css'
import React, { ComponentType, Ref, useState } from 'react'
import { NavItem } from 'src/layout/header/Header'
import { NavLink } from 'src/layout/header/NavLink'
import { View } from 'src/ui/basic/View'
import { useTran } from '@ps-aux/react-app-core'
import { onClickOutside } from 'src/ui/dom/onClickOutside'
import { useTheme } from 'src/ui/style/theme/useTheme'
import { UserPanel } from 'src/layout/header/UserPanel'
import { Icon } from 'src/ui/basic/icon/Icon'
import { icon } from 'src/ui/basic/icon/icons'

type MobileNavigationProps = {
    navItems: NavItem[]
}

export const MobileNavigation: ComponentType<MobileNavigationProps> = ({
    navItems
}) => {
    const t = useTran()
    const th = useTheme()
    const [mobileMenuIsOpen, setMobileMenuState] = useState(false)
    const toggleMobileMenuState = () =>
        setMobileMenuState(prevState => !prevState)
    const refClickableOutside: Ref<HTMLDivElement> = onClickOutside(() =>
        setMobileMenuState(false)
    )
    return (
        <>
            <div
                ref={refClickableOutside}
                css={css`
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    background: ${th.colors.white};
                    display: ${mobileMenuIsOpen ? 'flex' : 'none'};
                    padding: ${th.spacing.md};
                    box-shadow: ${th.boxShadow.bottomOnly};
                    font-size: ${th.fontSize.md};
                    > *:not(:last-child) {
                        margin-bottom: ${th.spacing.md};
                    }
                `}
            >
                {navItems.map(([label, path]) => (
                    <NavLink
                        key={label}
                        to={path}
                        onClick={toggleMobileMenuState}
                    >
                        {t(label)}
                    </NavLink>
                ))}
                <View
                    horizontal
                    css={th => css`
                        flex-direction: column;
                        > *:not(:last-child) {
                            margin-bottom: ${th.spacing.md};
                        }
                    `}
                >
                    <UserPanel onClick={toggleMobileMenuState} />
                </View>
            </div>
            <Icon
                icon={mobileMenuIsOpen ? icon.Close : icon.Menu}
                onClick={toggleMobileMenuState}
                css={css`
                    cursor: pointer;
                    font-size: 3rem;
                `}
            />
        </>
    )
}
