export const PublicTransportAccessibility = {
  name: 'PublicTransportAccessibility',
  attrs: {
    available: {
      name: 'available',
      id: 'PublicTransportAccessibility.available',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    lines: {
      name: 'lines',
      id: 'PublicTransportAccessibility.lines',
      type: {
        name: 'list',
        of: {
          name: 'string'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
