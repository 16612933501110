export const RequestCancellationCmd = {
  name: 'RequestCancellationCmd',
  attrs: {
    day: {
      name: 'day',
      id: 'RequestCancellationCmd.day',
      type: {
        name: 'object',
        of: 'Day'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    note: {
      name: 'note',
      id: 'RequestCancellationCmd.note',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    reasons: {
      name: 'reasons',
      id: 'RequestCancellationCmd.reasons',
      type: {
        name: 'list',
        of: {
          name: 'string'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    wasEvictedAndCleaned: {
      name: 'wasEvictedAndCleaned',
      id: 'RequestCancellationCmd.wasEvictedAndCleaned',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
