import { apiOps } from 'src/api/defs/apiOps'
import { HigherApiClient } from 'src/api/client/ApiClient'
import { ListReq, NoListFilter } from 'src/model/api/ListReq'
import { Page } from 'src/model/api/Page'
import { m } from 'src/api/model/model'
import { Owner } from 'src/api/defs/Owner.type'
import { OwnerEditCmd } from 'src/api/defs/OwnerEditCmd.type'
import { AdminContractListItem } from 'src/api/defs/AdminContractListItem.type'
import { AdminWarehouseListItem } from 'src/api/defs/AdminWarehouseListItem.type'

const ops = apiOps.Owner

// eslint-disable-next-line @typescript-eslint/ban-types
export type OwnerListFilter = {}

export class OwnerApi {
    constructor(private api: HigherApiClient) {}

    all = (req: ListReq<OwnerListFilter>): Promise<Page<Owner>> =>
        this.api.getPage<Owner, OwnerListFilter>(ops.getAll, m.Owner, req)

    detail = (id: string): Promise<Owner> =>
        this.api.doForId<Owner>(ops.detail, m.Owner, id)

    edit = (id: string, cmd: OwnerEditCmd): Promise<any> =>
        this.api.doForId<void, OwnerEditCmd>(ops.edit, null, id, cmd)

    create = (cmd: OwnerEditCmd): Promise<any> =>
        this.api.do<OwnerEditCmd>(ops.create, { data: cmd })

    contracts = (
        id: string,
        req: ListReq<NoListFilter>
    ): Promise<Page<AdminContractListItem>> =>
        this.api.getPage<AdminContractListItem, any>(
            ops.contracts,
            m.AdminContractListItem,
            req,
            {
                id
            }
        )

    warehouses = (
        id: string,
        req: ListReq<NoListFilter>
    ): Promise<Page<AdminWarehouseListItem>> =>
        this.api.getPage<AdminWarehouseListItem, any>(
            ops.warehouses,
            m.WarehouseListItem,
            req,
            {
                id
            }
        )
}
