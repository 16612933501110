export const MySharedBox = {
  name: 'MySharedBox',
  attrs: {
    boxDimensions: {
      name: 'boxDimensions',
      id: 'MySharedBox.boxDimensions',
      type: {
        name: 'object',
        of: 'BoxDimensions'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    boxId: {
      name: 'boxId',
      id: 'MySharedBox.boxId',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    boxLockInfo: {
      name: 'boxLockInfo',
      id: 'MySharedBox.boxLockInfo',
      type: {
        name: 'object',
        of: 'BoxLockInfo'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    boxName: {
      name: 'boxName',
      id: 'MySharedBox.boxName',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    contractId: {
      name: 'contractId',
      id: 'MySharedBox.contractId',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    warehouseLockVersion: {
      name: 'warehouseLockVersion',
      id: 'MySharedBox.warehouseLockVersion',
      type: {
        name: 'enum',
        id: 'LockVersion',
        of: ['V1', 'V2']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'LockVersion'
      }
    }
  }
};
