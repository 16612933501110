export const Owner = {
  name: 'Owner',
  attrs: {
    address: {
      name: 'address',
      id: 'Owner.address',
      type: {
        name: 'object',
        of: 'Address'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    company: {
      name: 'company',
      id: 'Owner.company',
      type: {
        name: 'object',
        of: 'CompanyDetails'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    country: {
      name: 'country',
      id: 'Owner.country',
      type: {
        name: 'enum',
        id: 'Country',
        of: ['SVK', 'CZE', 'POL']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'Country'
      }
    },
    email: {
      name: 'email',
      id: 'Owner.email',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [
        {
          name: 'pattern',
          value: /^[a-zA-Z0-9+._%-]{1,256}@[a-zA-Z0-9][a-zA-Z0-9-]{0,64}(\.[a-zA-Z0-9][a-zA-Z0-9-]{0,64})*\.[a-zA-Z0-9][a-zA-Z0-9-]{0,25}$/
        }
      ],
      extra: {}
    },
    exportId: {
      name: 'exportId',
      id: 'Owner.exportId',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    firstName: {
      name: 'firstName',
      id: 'Owner.firstName',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'Owner.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    lastName: {
      name: 'lastName',
      id: 'Owner.lastName',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'Owner.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    phoneNumber: {
      name: 'phoneNumber',
      id: 'Owner.phoneNumber',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [
        {
          name: 'pattern',
          value: /^(\+|00)(\s?\d){7,15}$/
        }
      ],
      extra: {}
    }
  }
};
