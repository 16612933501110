export const ContractInvoicingOption = {
  name: 'ContractInvoicingOption',
  attrs: {
    id: {
      name: 'id',
      id: 'ContractInvoicingOption.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    invoicingType: {
      name: 'invoicingType',
      id: 'ContractInvoicingOption.invoicingType',
      type: {
        name: 'object',
        of: 'ContractInvoicingType'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    price: {
      name: 'price',
      id: 'ContractInvoicingOption.price',
      type: {
        name: 'object',
        of: 'BoxConsumerPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    savedAmountTotal: {
      name: 'savedAmountTotal',
      id: 'ContractInvoicingOption.savedAmountTotal',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
