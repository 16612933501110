export const TrackerInfo = {
  name: 'TrackerInfo',
  attrs: {
    facilityId: {
      name: 'facilityId',
      id: 'TrackerInfo.facilityId',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    lockId: {
      name: 'lockId',
      id: 'TrackerInfo.lockId',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    unlockTime: {
      name: 'unlockTime',
      id: 'TrackerInfo.unlockTime',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
