export const Tenant = {
  name: 'Tenant',
  attrs: {
    address: {
      name: 'address',
      id: 'Tenant.address',
      type: {
        name: 'object',
        of: 'Address'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    ico: {
      name: 'ico',
      id: 'Tenant.ico',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'Tenant.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
