export const AdminRent = {
  name: 'AdminRent',
  attrs: {
    id: {
      name: 'id',
      id: 'AdminRent.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    myRent: {
      name: 'myRent',
      id: 'AdminRent.myRent',
      type: {
        name: 'object',
        of: 'MyRent'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    ownerName: {
      name: 'ownerName',
      id: 'AdminRent.ownerName',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    paying: {
      name: 'paying',
      id: 'AdminRent.paying',
      type: {
        name: 'object',
        of: 'AdminRentPaying'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    tenant: {
      name: 'tenant',
      id: 'AdminRent.tenant',
      type: {
        name: 'object',
        of: 'Tenant'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    variableSymbol: {
      name: 'variableSymbol',
      id: 'AdminRent.variableSymbol',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    warehouseInfo: {
      name: 'warehouseInfo',
      id: 'AdminRent.warehouseInfo',
      type: {
        name: 'object',
        of: 'WarehouseInfo'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
