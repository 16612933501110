export const InvoiceItem = {
  name: 'InvoiceItem',
  attrs: {
    count: {
      name: 'count',
      id: 'InvoiceItem.count',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    description: {
      name: 'description',
      id: 'InvoiceItem.description',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    price: {
      name: 'price',
      id: 'InvoiceItem.price',
      type: {
        name: 'object',
        of: 'InvoiceItemVatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    subject: {
      name: 'subject',
      id: 'InvoiceItem.subject',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
