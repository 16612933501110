export const Coordinates = {
  name: 'Coordinates',
  attrs: {
    lat: {
      name: 'lat',
      id: 'Coordinates.lat',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    lng: {
      name: 'lng',
      id: 'Coordinates.lng',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
