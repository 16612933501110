export const IsoChronology = {
  name: 'IsoChronology',
  attrs: {
    calendarType: {
      name: 'calendarType',
      id: 'IsoChronology.calendarType',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'IsoChronology.id',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
