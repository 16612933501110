import { getAppContext } from 'src/context/AppContext'
import { useStoreValue } from '@ps-aux/react-app-core'
import { User } from 'src/auth/User'

export const useUser = (): User | null => {
    const u = useStoreValue(getAppContext().user.store)

    return u
}

export const usePresentUser = (): User => {
    const u = useUser()

    if (!u) throw new Error('User is required to be present')

    return u
}
