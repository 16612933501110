export const OwnerAndClientContractFilter = {
  name: 'OwnerAndClientContractFilter',
  attrs: {
    box: {
      name: 'box',
      id: 'OwnerAndClientContractFilter.box',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    businessId: {
      name: 'businessId',
      id: 'OwnerAndClientContractFilter.businessId',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    status: {
      name: 'status',
      id: 'OwnerAndClientContractFilter.status',
      type: {
        name: 'enum',
        id: 'Contract$Status',
        of: ['ACTIVE', 'TERMINATING', 'EVICTED', 'TERMINATED']
      },
      required: false,
      validationRules: [],
      extra: {
        enumId: 'Contract$Status'
      }
    },
    warehouse: {
      name: 'warehouse',
      id: 'OwnerAndClientContractFilter.warehouse',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
