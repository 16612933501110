import React, { ComponentType } from 'react'
import { css } from 'src/ui/style/css'
import brandAssets from 'src/brand/assets'
import { Image } from 'src/ui/img/Image'

export const Logo: ComponentType = () => (
    <Image
        src={brandAssets.Logo}
        css={css`
            // Adjust to parent height and keeps aspect ration
            height: 100%;
            object-fit: contain;
        `}
    />
)
