export const AppUserFilter = {
  name: 'AppUserFilter',
  attrs: {
    email: {
      name: 'email',
      id: 'AppUserFilter.email',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'AppUserFilter.name',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
