import { ApiOpDef, HigherApiClient } from 'src/api/client/ApiClient'
import { AppUser } from 'src/api/defs/AppUser.type'
import { Page } from 'src/model/api/Page'
import { ListReq } from 'src/model/api/ListReq'
import { apiOps } from 'src/api/defs/apiOps'
import { m } from 'src/api/model/model'
import { ClientUserFilter } from '../../../api/defs/ClientUserFilter.type'

export type AppUserFilter = {
    active: null | boolean
}

export class AppUserApi {
    constructor(private api: HigherApiClient) {}

    clientUsers = (req: ListReq<ClientUserFilter>) =>
        this.fetchAppUsers(apiOps.AppUser.getAllClients, req)

    admins = (req: ListReq<AppUserFilter>) =>
        this.fetchAppUsers(apiOps.AppUser.getAllAdmins, req)

    owners = (req: ListReq<AppUserFilter>) =>
        this.fetchAppUsers(apiOps.AppUser.getAllOwners, req)

    private fetchAppUsers = (
        op: ApiOpDef,
        req: ListReq<AppUserFilter | ClientUserFilter>
    ): Promise<Page<AppUser>> =>
        this.api.getPage<AppUser, AppUserFilter | ClientUserFilter>(
            op,
            m.AppUser,
            req
        )
}
