export const DeactivateWarehouseCmd = {
  name: 'DeactivateWarehouseCmd',
  attrs: {
    availableFrom: {
      name: 'availableFrom',
      id: 'DeactivateWarehouseCmd.availableFrom',
      type: {
        name: 'object',
        of: 'Day'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
