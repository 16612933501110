export const PlaceOrderCmd = {
  name: 'PlaceOrderCmd',
  attrs: {
    orderInputsCmd: {
      name: 'orderInputsCmd',
      id: 'PlaceOrderCmd.orderInputsCmd',
      type: {
        name: 'object',
        of: 'OrderInputs'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    subscribeToNewsletter: {
      name: 'subscribeToNewsletter',
      id: 'PlaceOrderCmd.subscribeToNewsletter',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
