export const ChangeMyPasswordCmd = {
  name: 'ChangeMyPasswordCmd',
  attrs: {
    newPassword: {
      name: 'newPassword',
      id: 'ChangeMyPasswordCmd.newPassword',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    oldPassword: {
      name: 'oldPassword',
      id: 'ChangeMyPasswordCmd.oldPassword',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
