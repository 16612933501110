export const AdminContract = {
  name: 'AdminContract',
  attrs: {
    contract: {
      name: 'contract',
      id: 'AdminContract.contract',
      type: {
        name: 'object',
        of: 'MyContract'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'AdminContract.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    invoicingDetails: {
      name: 'invoicingDetails',
      id: 'AdminContract.invoicingDetails',
      type: {
        name: 'object',
        of: 'InvoicingDetails'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
