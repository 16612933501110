import { Theme } from 'src/ui/style/theme/Theme'
import { css } from 'src/ui/style/css'

export const grid =
    ({
        minWidth,
        maxWidth = '1fr',
        gap,
        auto = 'fit'
    }: {
        minWidth: string
        maxWidth?: string
        gap?: string
        auto?: 'fit' | 'fill'
    }) =>
    (th: Theme) =>
        css`
            display: grid;
            grid-template-columns: repeat(
                ${`auto-${auto}`},
                minmax(${minWidth}, ${maxWidth})
            );
            ${gap && `grid-gap: ${gap};`}
        `

export const verticalChildSpacing = (spacing: string) => css`
    > *:not(:last-child) {
        margin-bottom: ${spacing};
    }
`

export const horizontalChildSpacing = (spacing: string) => css`
    > *:not(:last-child) {
        margin-right: ${spacing};
    }
`
