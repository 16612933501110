import { Day } from 'src/api/defs/Day.type'
import { formatLocale } from 'src/i18n/formatSettings'
import { LanguageFormat } from 'src/types/LanguageFormat'

export const strDay = (d: Day, format?: LanguageFormat): string => {
    const actualFormat = format ?? formatLocale
    return actualFormat === LanguageFormat.EN
        ? `${d.month}/${d.day}/${d.year}`
        : `${d.day}.${d.month}.${d.year}`
}
