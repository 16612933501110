import React, { ComponentType } from 'react'
import { Address } from 'src/api/defs/Address.type'
import { Text } from 'src/ui/basic/Text'

type InlineAddressProps = {
    value: Address
}

export const InlineAddress: ComponentType<InlineAddressProps> = ({
    value: { street, number, zip, city, countryCode }
}) => <Text>{`${street} ${number}, ${zip} ${city}, ${countryCode}`}</Text>
