export const BoxConsumerPrice = {
  name: 'BoxConsumerPrice',
  attrs: {
    discount: {
      name: 'discount',
      id: 'BoxConsumerPrice.discount',
      type: {
        name: 'object',
        of: 'Discount'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    original: {
      name: 'original',
      id: 'BoxConsumerPrice.original',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    promo: {
      name: 'promo',
      id: 'BoxConsumerPrice.promo',
      type: {
        name: 'object',
        of: 'PromoDiscountEffect'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    total: {
      name: 'total',
      id: 'BoxConsumerPrice.total',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
