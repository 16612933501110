import { commonPrefix } from 'src/multirole/commonPrefix'

export const RentPages = {
    List: {
        path: commonPrefix + '/rent',
        comp: () => import('src/business/Rent/RentListPage'),
        to: (): string => '/common/rent'
    },
    Detail: {
        path: commonPrefix + '/rent/:id',
        comp: () => import('src/business/Rent/RentDetailPage'),
        to: (id: string): string => commonPrefix + `/rent/${id}`
    }
}
