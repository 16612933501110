import React, { ComponentType, ReactNode } from 'react'
import { Header } from 'src/layout/header/Header'
import { PageContent } from 'src/layout/PageContent'

export type AppLayoutProps = {
    children: ReactNode
}

export const AppLayout: ComponentType<AppLayoutProps> = ({ children }) => (
    <div>
        <Header />
        <PageContent>{children}</PageContent>
    </div>
)
