import { Theme } from 'src/ui/style/theme/Theme'
import { css } from 'src/ui/style/css'

export const detailPropLabel =
    (topLabel = true) =>
    (th: Theme) =>
        css`
            margin-left: ${topLabel ? 0 : '0.25em'};
            margin-bottom: ${topLabel ? '0.25em' : 0};
            font-weight: ${th.fontWeight.bold};
        `
