export const ClientWarehouse = {
  name: 'ClientWarehouse',
  attrs: {
    accessibility: {
      name: 'accessibility',
      id: 'ClientWarehouse.accessibility',
      type: {
        name: 'object',
        of: 'Accessibility'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    address: {
      name: 'address',
      id: 'ClientWarehouse.address',
      type: {
        name: 'object',
        of: 'Address'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    code: {
      name: 'code',
      id: 'ClientWarehouse.code',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    deactivation: {
      name: 'deactivation',
      id: 'ClientWarehouse.deactivation',
      type: {
        name: 'object',
        of: 'DeactivationInfo'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    features: {
      name: 'features',
      id: 'ClientWarehouse.features',
      type: {
        name: 'object',
        of: 'Features'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'ClientWarehouse.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    images: {
      name: 'images',
      id: 'ClientWarehouse.images',
      type: {
        name: 'list',
        of: {
          name: 'string'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'ClientWarehouse.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
