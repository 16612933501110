export const BoxTo = {
  name: 'BoxTo',
  attrs: {
    areaTo: {
      name: 'areaTo',
      id: 'BoxTo.areaTo',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    priceTo: {
      name: 'priceTo',
      id: 'BoxTo.priceTo',
      type: {
        name: 'object',
        of: 'BoxConsumerPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
