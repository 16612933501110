import React from 'react'
import { InputComponent } from 'src/ui/basic/input/types'
import { css } from 'src/ui/style/css'

export const CheckBox: InputComponent<boolean> = ({ input, onBlur }) => (
    <input
        css={css`
            margin: 0.8rem 0 !important;
            width: 1rem;
        `}
        type="checkbox"
        checked={input.value}
        onBlur={onBlur}
        onChange={e => input.onChange(e.target.checked)}
    />
)
