import { Attribute } from '@ps-aux/swagger-codegen'
import { FluentModel } from '@ps-aux/api-model-extensions'

export const isValueObjectOfType = (
    attr: Attribute,
    // @ts-ignore TODO generics
    m: FluentModel
): boolean => {
    // @ts-ignore
    return attr.type.name === 'object' && attr.type.of === m._meta.name
}
