export const CurrentObsadenost = {
  name: 'CurrentObsadenost',
  attrs: {
    obsadenost: {
      name: 'obsadenost',
      id: 'CurrentObsadenost.obsadenost',
      type: {
        name: 'object',
        of: 'KpiObsadenost'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    warehouseName: {
      name: 'warehouseName',
      id: 'CurrentObsadenost.warehouseName',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
