export const WarehouseInfo = {
  name: 'WarehouseInfo',
  attrs: {
    address: {
      name: 'address',
      id: 'WarehouseInfo.address',
      type: {
        name: 'object',
        of: 'Address'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    code: {
      name: 'code',
      id: 'WarehouseInfo.code',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    deactivation: {
      name: 'deactivation',
      id: 'WarehouseInfo.deactivation',
      type: {
        name: 'object',
        of: 'DeactivationInfo'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    features: {
      name: 'features',
      id: 'WarehouseInfo.features',
      type: {
        name: 'object',
        of: 'Features'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'WarehouseInfo.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'WarehouseInfo.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
