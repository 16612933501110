import React from 'react'
import BsButton from 'react-bootstrap/Button'
import { ButtonComp } from 'src/ui/basic/api/Button'
import { css } from 'src/ui/style/css'

export const Button: ButtonComp = ({
    children,
    onClick,
    title,
    enabled = true,
    outline,
    leftContent,
    submittable,
    fullwidth
}) => {
    const variant = outline ? 'outline-primary' : 'primary'
    const type = submittable ? 'submit' : 'button'
    return (
        <BsButton
            title={title}
            onClick={onClick}
            disabled={!enabled}
            variant={variant}
            type={type}
            css={th => css`
                ${!outline && `color: ${th.colors.white}`};

                &:disabled {
                    background-color: ${th.colors.gray};
                    border-color: ${th.colors.gray};
                    color: ${th.colors.white};
                }

                &:hover {
                    ${outline && `color: ${th.colors.white}`};
                }

                > *:nth-child(2) {
                    margin-left: 0.5em;
                }

                ${fullwidth && 'width: 100%'};
            `}
        >
            {leftContent && leftContent()}
            <span>{children}</span>
        </BsButton>
    )
}
