import React, { ComponentType } from 'react'
import { Text } from 'src/ui/basic/Text'
import { Link } from 'react-router-dom'

export type TextWithLinkProps = {
    text: string
    link: { to: string; text: string }
    className?: string
}

export const TextWithLink: ComponentType<TextWithLinkProps> = ({
    text,
    link,
    className
}) => (
    <span className={className}>
        <Text>{text}</Text> <Link to={link.to}>{link.text}</Link>
    </span>
)
