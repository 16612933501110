export const SetInvocingDetailsCmd = {
  name: 'SetInvocingDetailsCmd',
  attrs: {
    address: {
      name: 'address',
      id: 'SetInvocingDetailsCmd.address',
      type: {
        name: 'object',
        of: 'Address'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    company: {
      name: 'company',
      id: 'SetInvocingDetailsCmd.company',
      type: {
        name: 'object',
        of: 'CompanyDetails'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    email: {
      name: 'email',
      id: 'SetInvocingDetailsCmd.email',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [
        {
          name: 'pattern',
          value: /^[a-zA-Z0-9+._%-]{1,256}@[a-zA-Z0-9][a-zA-Z0-9-]{0,64}(\.[a-zA-Z0-9][a-zA-Z0-9-]{0,64})*\.[a-zA-Z0-9][a-zA-Z0-9-]{0,25}$/
        }
      ],
      extra: {}
    },
    firstName: {
      name: 'firstName',
      id: 'SetInvocingDetailsCmd.firstName',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    lastName: {
      name: 'lastName',
      id: 'SetInvocingDetailsCmd.lastName',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    phoneNumber: {
      name: 'phoneNumber',
      id: 'SetInvocingDetailsCmd.phoneNumber',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [
        {
          name: 'pattern',
          value: /^(\+|00)(\s?\d){7,15}$/
        }
      ],
      extra: {}
    }
  }
};
