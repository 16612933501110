import { css } from 'src/ui/style/css'
import React, { ComponentType, ReactNode } from 'react'
import { NavLink as NavLinkComp } from 'react-router-dom'
import { useTheme } from 'src/ui/style/theme/useTheme'

type NavLinkProps = {
    children: ReactNode
    to: string
    onClick?: () => void
}

export const NavLink: ComponentType<NavLinkProps> = ({
    children,
    to,
    onClick
}) => {
    const th = useTheme()
    return (
        <NavLinkComp
            to={to}
            css={css`
                color: ${th.fontColor.primary};
                &:hover {
                    color: ${th.colors.primary};
                }
            `}
            activeStyle={{
                color: th.colors.primary
            }}
            onClick={onClick}
            exact
        >
            {children}
        </NavLinkComp>
    )
}
