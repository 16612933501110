import { PageRouteDef } from '@ps-aux/react-app-core'
import { WarehousePages } from 'src/business/Warehouse/WarehousePages'
import { DashBoardPages } from 'src/business/Dashboard/DashboardPages'
import { RentPages } from 'src/business/Rent/RentPages'
import { UserPages } from 'src/business/user/UserPages'
import { ClientUserPages } from 'src/business/ClientUser/ClientUserPages'
import { OwnerPages } from 'src/business/Owner/OwnerPages'
import { ContractPages } from 'src/business/Contract/ContractPages'
import { SuperadminPages } from 'src/superadmin/SuperadminPages'
import { BoxPages } from 'src/business/Box/BoxPages'
import { FranchiseContactPages } from 'src/business/FranchiseContact/FranchiseContactPages'
import { ServiceAccessPages } from '../business/ServiceAccess/ServiceAccessPages'
import { RysAuditPages } from '../business/RysAudit/RysAuditPages'

export const pages = {
    Warehouse: WarehousePages,
    Box: BoxPages,
    Dashboard: DashBoardPages,
    Rent: RentPages,
    User: UserPages,
    ClientUser: ClientUserPages,
    Owner: OwnerPages,
    Contract: ContractPages,
    Superadmin: SuperadminPages,
    FranchiseContact: FranchiseContactPages,
    ServiceAccess: ServiceAccessPages,
    RysAudit: RysAuditPages
}

const asTree: PageTree = pages
export type PageTree = Record<string, Record<string, PageRouteDef<any>>>

const links = new Set<string>()

export const pageList: PageRouteDef<any>[] = Object.values(asTree)
    .flatMap(x => {
        return Object.values(x)
    })
    .map(p => {
        const { path } = p
        if (links.has(path)) {
            throw new Error(`Path ${path} is already in use`)
        }
        links.add(path)

        return p
    })
