export const PromoWithBoxes = {
  name: 'PromoWithBoxes',
  attrs: {
    boxes: {
      name: 'boxes',
      id: 'PromoWithBoxes.boxes',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'Box'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    promo: {
      name: 'promo',
      id: 'PromoWithBoxes.promo',
      type: {
        name: 'object',
        of: 'PromoDiscount'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
