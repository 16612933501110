import {
    FaBars,
    FaCaretDown,
    FaCaretUp,
    FaCheck,
    FaCoins,
    FaEye,
    FaFileDownload,
    FaFilePdf,
    FaPercent,
    FaPlus,
    FaTimes,
    FaArrowUp,
    FaArrowDown
} from 'react-icons/fa/index'

export const icon = {
    Ok: FaCheck,
    Menu: FaBars,
    Close: FaTimes,
    CarretUp: FaCaretUp,
    CarretDown: FaCaretDown,
    Add: FaPlus,
    Coins: FaCoins,
    Eye: FaEye,
    PDF: FaFilePdf,
    Percent: FaPercent,
    FileDownload: FaFileDownload,
    UpSimpleArrow: FaArrowUp,
    DownSimpleArrow: FaArrowDown
}
