import { flatten } from 'src/i18n/lib/texts/utils'
import { MsgKeys } from 'src/i18n/lib/texts/index'

const setAtPath = (
    path: string[],
    obj: Record<string, unknown>,
    val: any
): void => {
    const [first, ...rest] = path

    if (rest.length === 0) {
        obj[first] = val
        return
    }

    // @ts-ignore
    setAtPath(rest, obj[first], val)
}

export const setPathValues = (obj: MsgKeys): void => {
    const paths: [string[], string][] = Object.keys(flatten(obj)).map(k => [
        k.split('.'),
        k
    ])

    paths.forEach(([path, key]) => {
        setAtPath(path, obj, key)
    })
}
