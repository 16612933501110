export const KpiObsadenostAreal = {
  name: 'KpiObsadenostAreal',
  attrs: {
    active: {
      name: 'active',
      id: 'KpiObsadenostAreal.active',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    inactive: {
      name: 'inactive',
      id: 'KpiObsadenostAreal.inactive',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    inactivePercentage: {
      name: 'inactivePercentage',
      id: 'KpiObsadenostAreal.inactivePercentage',
      type: {
        name: 'double'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    taken: {
      name: 'taken',
      id: 'KpiObsadenostAreal.taken',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    takenFromActivePercentage: {
      name: 'takenFromActivePercentage',
      id: 'KpiObsadenostAreal.takenFromActivePercentage',
      type: {
        name: 'double'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    takenFromAllPercentage: {
      name: 'takenFromAllPercentage',
      id: 'KpiObsadenostAreal.takenFromAllPercentage',
      type: {
        name: 'double'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
