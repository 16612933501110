import { HigherApiClient } from 'src/api/client/ApiClient'
import { ListReq, NoListFilter } from 'src/model/api/ListReq'
import { Page } from 'src/model/api/Page'
import { apiOps } from 'src/api/defs/apiOps'
import { m } from 'src/api/model/model'
import { AdminRent } from 'src/api/defs/AdminRent.type'

export class SuperadminRentApi {
    constructor(private api: HigherApiClient) {}

    waitingForManualPayment = (
        req: ListReq<NoListFilter>
    ): Promise<Page<AdminRent>> =>
        this.api.getPage<AdminRent, NoListFilter>(
            apiOps.SuperadminRent.waitingForManual,
            m.AdminRent,
            req
        )
}
