export const ClientInfo = {
  name: 'ClientInfo',
  attrs: {
    email: {
      name: 'email',
      id: 'ClientInfo.email',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'ClientInfo.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
