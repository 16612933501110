export const PlacedOrderInfo = {
  name: 'PlacedOrderInfo',
  attrs: {
    id: {
      name: 'id',
      id: 'PlacedOrderInfo.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    paymentId: {
      name: 'paymentId',
      id: 'PlacedOrderInfo.paymentId',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    paymentUrl: {
      name: 'paymentUrl',
      id: 'PlacedOrderInfo.paymentUrl',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
