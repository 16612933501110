import { css } from '@emotion/react'
import { Theme } from 'src/ui/style/theme/Theme'
import '../../../node_modules/minireset.css/minireset.css'
import { SerializedStyles } from '@emotion/utils'

export const createCountrySelectorStyle = (th: Theme): SerializedStyles => css`
    .flagsSelect {
        position: relative;
        vertical-align: inherit;
        text-align: left;
    }

    .selectBtn {
        cursor: pointer;
        width: 65px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: inherit;
        color: #4d4d4d;
        background: transparent;
        border: none;
        font-size: 1rem;
        padding: 6px 0;
    }

    .selectBtn:hover,
    .selectBtn:focus {
        outline: none !important;
    }

    .selectBtn:after,
    .selectBtn[aria-expanded='true']:after {
        content: ' ';
        width: 0;
        height: 0;
        display: inline-block;
        margin-left: 5px;
    }

    .label {
        font-size: 1rem;
        padding-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${th.colors.tertiary};
    }

    .selectValue {
        cursor: pointer;
        padding: 4px;
        white-space: nowrap;
        width: 100%;
    }

    .selectOption {
        cursor: pointer;
        padding: 4px 5px 2px 5px;
        margin: 4px 0;
        white-space: nowrap;
        margin: 0;
        color: ${th.colors.tertiary};
    }

    .selectValue {
        pointer-events: none;
        display: flex;
        align-items: center;
    }

    .selectOption:hover,
    .selectOption:focus {
        outline: none;
        background: ${th.colors.secondary};
        color: ${th.colors.white};
    }

    .selectFlag {
        display: inline-flex;
        margin-right: 13px;
    }

    .icon {
        display: inline-flex;
        font-size: 1em;
    }

    .selectOptionValue {
        display: inline-block;
    }

    .flagOption {
        display: inline-block;
        vertical-align: middle;
        padding: 6px 0;
    }

    .flagOption > .flag {
        max-width: 20px;
    }

    .selectOptionWithlabel {
        padding: 4px 10px;
    }

    .selectOptions {
        position: absolute;
        z-index: 999999;
        background: #ffffff;
        max-height: 180px;
        overflow: auto;
        font-size: 1rem;
        margin-top: 0;
        padding: 5px 0 0 0;
        width: 65px;
    }
`
