export const WarehouseBoxPrice = {
  name: 'WarehouseBoxPrice',
  attrs: {
    key: {
      name: 'key',
      id: 'WarehouseBoxPrice.key',
      type: {
        name: 'enum',
        id: 'Box$BoxSize',
        of: ['XS', 'SMALL', 'MEDIUM', 'LARGE']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'Box$BoxSize'
      }
    },
    value: {
      name: 'value',
      id: 'WarehouseBoxPrice.value',
      type: {
        name: 'object',
        of: 'Money'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
