export const InvoicingDetails = {
  name: 'InvoicingDetails',
  attrs: {
    address: {
      name: 'address',
      id: 'InvoicingDetails.address',
      type: {
        name: 'object',
        of: 'Address'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    company: {
      name: 'company',
      id: 'InvoicingDetails.company',
      type: {
        name: 'object',
        of: 'CompanyDetails'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    email: {
      name: 'email',
      id: 'InvoicingDetails.email',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [
        {
          name: 'pattern',
          value: /^[a-zA-Z0-9+._%-]{1,256}@[a-zA-Z0-9][a-zA-Z0-9-]{0,64}(\.[a-zA-Z0-9][a-zA-Z0-9-]{0,64})*\.[a-zA-Z0-9][a-zA-Z0-9-]{0,25}$/
        }
      ],
      extra: {}
    },
    firstName: {
      name: 'firstName',
      id: 'InvoicingDetails.firstName',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    lastName: {
      name: 'lastName',
      id: 'InvoicingDetails.lastName',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    telephoneNumber: {
      name: 'telephoneNumber',
      id: 'InvoicingDetails.telephoneNumber',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [
        {
          name: 'pattern',
          value: /^(\+|00)(\s?\d){7,15}$/
        }
      ],
      extra: {}
    },
    type: {
      name: 'type',
      id: 'InvoicingDetails.type',
      type: {
        name: 'enum',
        id: 'InvoicingDetails$Type',
        of: ['PravnickaOsoba', 'FyzickaOsoba']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'InvoicingDetails$Type'
      }
    }
  }
};
