import { useStoreValue } from '@ps-aux/react-app-core'
import React from 'react'
import DP from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { getAppContext } from 'src/context/AppContext'
import { DateTimePickerComp } from 'src/ui/basic/api/DateTimePicker'
import { LanguageFormat } from 'src/types/LanguageFormat'

const dateTimeDefaultFormatEU = 'dd.MM.yyyy HH:mm'
const dateDefaultFormatEU = 'dd.MM.yyyy'

const dateTimeDefaultFormatUS = 'MM/dd/yyyy HH:mm'
const dateDefaultFormatUS = 'MM/dd/yyyy'

export const DateTimePicker: DateTimePickerComp = ({
    timeInput = true,
    minDate,
    input: { value, onChange },
    className,
    enabled = true
}) => {
    const localeFormat = useStoreValue(getAppContext().i18n.format)

    const dateTimeFormat =
        localeFormat === LanguageFormat.EN
            ? dateTimeDefaultFormatUS
            : dateTimeDefaultFormatEU

    const dateFormat =
        localeFormat === LanguageFormat.EN
            ? dateDefaultFormatUS
            : dateDefaultFormatEU

    const format = timeInput ? dateTimeFormat : dateFormat

    return (
        <DP
            className={`form-control ${className}`}
            selected={value || null}
            dateFormat={format}
            timeIntervals={15}
            minDate={minDate}
            disabled={!enabled}
            showYearDropdown
            scrollableYearDropdown
            timeFormat="HH:mm"
            showTimeSelect={timeInput}
            onChange={onChange}
        />
    )
}
