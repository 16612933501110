export const OrderInputs = {
  name: 'OrderInputs',
  attrs: {
    boxId: {
      name: 'boxId',
      id: 'OrderInputs.boxId',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    camera: {
      name: 'camera',
      id: 'OrderInputs.camera',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    insurance: {
      name: 'insurance',
      id: 'OrderInputs.insurance',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    invoicing: {
      name: 'invoicing',
      id: 'OrderInputs.invoicing',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    invoicingDetails: {
      name: 'invoicingDetails',
      id: 'OrderInputs.invoicingDetails',
      type: {
        name: 'object',
        of: 'InvoicingDetails'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    returnUrl: {
      name: 'returnUrl',
      id: 'OrderInputs.returnUrl',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
