import React, { ComponentType } from 'react'
import { useFormatters } from 'src/formatters/useFormatters'
import { RenderValue } from 'src/model/value'

export const DateValue: ComponentType<{ value: Date }> = ({ value }) => {
    const { strDate } = useFormatters()
    const date = strDate(value)
    return <>{date}</>
}

export const RenderDateValue: RenderValue<Date> = value => (
    <DateValue value={value} />
)
