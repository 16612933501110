export const BoxDimensions = {
  name: 'BoxDimensions',
  attrs: {
    area: {
      name: 'area',
      id: 'BoxDimensions.area',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    height: {
      name: 'height',
      id: 'BoxDimensions.height',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    length: {
      name: 'length',
      id: 'BoxDimensions.length',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    width: {
      name: 'width',
      id: 'BoxDimensions.width',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
