export const OrderResult = {
  name: 'OrderResult',
  attrs: {
    contractId: {
      name: 'contractId',
      id: 'OrderResult.contractId',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'OrderResult.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    paymentUrl: {
      name: 'paymentUrl',
      id: 'OrderResult.paymentUrl',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    status: {
      name: 'status',
      id: 'OrderResult.status',
      type: {
        name: 'enum',
        id: 'Order$Status',
        of: ['SUBMITTED', 'FULFILLED', 'FAILED', 'CANCELLED']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'Order$Status'
      }
    }
  }
};
