import { Money } from 'src/api/defs/Money.type'
import { formatLocale } from 'src/i18n/formatSettings'
import { locales } from 'src/i18n/locale'
import { LanguageFormat } from 'src/types/LanguageFormat'
import { NumberFormatOptions } from '@formatjs/ecma402-abstract'

export const strMoney = (
    m: Money,
    format?: LanguageFormat,
    hideCurrency?: boolean
): string => {
    const options: NumberFormatOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: formatLocale === locales.CS ? 'CZK' : 'EUR'
    }

    if (hideCurrency) {
        options.style = 'decimal'
        options.currency = undefined
    }

    const f = new Intl.NumberFormat(format ?? formatLocale, options)

    return f.format(m.value / 100)
}
