export const ReferralRewardTransactionFilter = {
  name: 'ReferralRewardTransactionFilter',
  attrs: {
    box: {
      name: 'box',
      id: 'ReferralRewardTransactionFilter.box',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    referredUserName: {
      name: 'referredUserName',
      id: 'ReferralRewardTransactionFilter.referredUserName',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
