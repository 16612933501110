import { HigherApiClient } from 'src/api/client/ApiClient'
import { apiOps } from 'src/api/defs/apiOps'
import { Page } from 'src/model/api/Page'
import { ListReq, NoListFilter } from 'src/model/api/ListReq'
import { m } from 'src/api/model/model'
import { BrowserErrorReport } from 'src/api/defs/BrowserErrorReport.type'

export class BrowserErrorsApi {
    constructor(private api: HigherApiClient) {}

    getAll = (req: ListReq<NoListFilter>): Promise<Page<BrowserErrorReport>> =>
        this.api.getPage(
            apiOps.SuperAdminErrorReporting.errors,
            m.BrowserErrorReport,
            req
        )
}
