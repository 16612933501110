export const WarehouseFilterForAdmin = {
  name: 'WarehouseFilterForAdmin',
  attrs: {
    code: {
      name: 'code',
      id: 'WarehouseFilterForAdmin.code',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    country: {
      name: 'country',
      id: 'WarehouseFilterForAdmin.country',
      type: {
        name: 'enum',
        id: 'Country',
        of: ['SVK', 'CZE', 'POL']
      },
      required: false,
      validationRules: [],
      extra: {
        enumId: 'Country'
      }
    },
    deactivated: {
      name: 'deactivated',
      id: 'WarehouseFilterForAdmin.deactivated',
      type: {
        name: 'boolean'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'WarehouseFilterForAdmin.name',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    owner: {
      name: 'owner',
      id: 'WarehouseFilterForAdmin.owner',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
