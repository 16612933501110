export const WarehouseContractFilter = {
  name: 'WarehouseContractFilter',
  attrs: {
    box: {
      name: 'box',
      id: 'WarehouseContractFilter.box',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    businessId: {
      name: 'businessId',
      id: 'WarehouseContractFilter.businessId',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    status: {
      name: 'status',
      id: 'WarehouseContractFilter.status',
      type: {
        name: 'enum',
        id: 'Contract$Status',
        of: ['ACTIVE', 'TERMINATING', 'EVICTED', 'TERMINATED']
      },
      required: false,
      validationRules: [],
      extra: {
        enumId: 'Contract$Status'
      }
    },
    tenant: {
      name: 'tenant',
      id: 'WarehouseContractFilter.tenant',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
