export const FranchiseContactFilter = {
  name: 'FranchiseContactFilter',
  attrs: {
    email: {
      name: 'email',
      id: 'FranchiseContactFilter.email',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
