import React, { ComponentType } from 'react'
import { hot } from 'react-hot-loader'
import { AppContext, AppContextProvider } from 'src/context/AppContext'
import { App } from 'src/App'
import { StylingProvider } from 'src/ui/style/StylingProvider'
import {
    I18nProvider,
    RoutingProvider,
    useStoreValue
} from '@ps-aux/react-app-core'
import { messagesSource } from 'src/i18n/texts'
import { ModelRenderingProvider } from 'src/model/ModelRenderingProvider'
import { valueObjectsRenderConfigResolver } from 'src/app/value/valueObjectsRenderConfig'
import { ModalProvider } from 'src/ui/modal/Modal'
import { appTheme } from 'src/ui/style/theme/appTheme'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type RootProps = {
    ctx: AppContext
    rootElement: HTMLElement
}

const logI18nErrors = false

const queryClient = new QueryClient()

const MyModalProvider: ComponentType<{ rootElement: HTMLElement }> = ({
    children,
    rootElement
}) => {
    const theme = appTheme()

    return (
        <ModalProvider
            appElement={rootElement}
            style={{
                borderRadius: theme.borderRadius.primary
            }}
        >
            {children}
        </ModalProvider>
    )
}

export const Root: ComponentType<RootProps> = ({ ctx, rootElement }) => {
    const locale = useStoreValue(ctx.i18n.language)

    return (
        <AppContextProvider value={ctx}>
            <RoutingProvider router={ctx.router}>
                <I18nProvider
                    messagesSource={messagesSource}
                    onError={err => {
                        if (!logI18nErrors) return

                        // TODO
                        console.error('Translation error', err.name)
                    }}
                    locale={locale.toLocaleLowerCase()}
                >
                    <QueryClientProvider client={queryClient}>
                        <ModelRenderingProvider
                            renderConfigResolver={
                                valueObjectsRenderConfigResolver
                            }
                        >
                            <StylingProvider>
                                <MyModalProvider rootElement={rootElement}>
                                    <App pages={ctx.router.pages} />
                                </MyModalProvider>
                            </StylingProvider>
                        </ModelRenderingProvider>
                    </QueryClientProvider>
                </I18nProvider>
            </RoutingProvider>
            <ToastContainer position="top-right" theme="colored" />
        </AppContextProvider>
    )
}

export default hot(module)(Root)
