export const SuperAdminOrder = {
  name: 'SuperAdminOrder',
  attrs: {
    box: {
      name: 'box',
      id: 'SuperAdminOrder.box',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    businessId: {
      name: 'businessId',
      id: 'SuperAdminOrder.businessId',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'SuperAdminOrder.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    status: {
      name: 'status',
      id: 'SuperAdminOrder.status',
      type: {
        name: 'enum',
        id: 'Order$Status',
        of: ['SUBMITTED', 'FULFILLED', 'FAILED', 'CANCELLED']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'Order$Status'
      }
    },
    time: {
      name: 'time',
      id: 'SuperAdminOrder.time',
      type: {
        name: 'date'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    user: {
      name: 'user',
      id: 'SuperAdminOrder.user',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
