import { AxiosInstance } from 'axios'
import { AuthApi, LoginRequest } from 'src/auth/AuthApi'
import { Store } from '@ps-aux/react-app-core'
import { User } from 'src/auth/User'

export class UserService {
    private readonly api: AuthApi

    constructor(
        private http: AxiosInstance,
        private store: Store<User | null>
    ) {
        this.api = new AuthApi(http)
    }

    logIn = async (req: LoginRequest): Promise<any> => {
        const user = await this.api.logIn(req)

        this.store.change(user)
    }

    logOut = (): void => {
        this.store.change(null)
    }
}
