import { useTran } from '@ps-aux/react-app-core'
import { AppError } from 'src/error/AppError'
import { ErrorDetail } from '../http/HttpError'

export type TranslateError = (err: AppError) => string
export const useErrorTran = (): TranslateError => {
    const t = useTran()
    return err => {
        if (err.detail && Array.isArray(err.detail)) {
            return (
                t('error.' + err.code) +
                `: ${err?.detail
                    ?.map(
                        (detail: ErrorDetail) =>
                            `${detail?.field} - ${detail?.code}`
                    )
                    .join(', ')}`
            )
        } else {
            const params =
                typeof err.detail === 'object' ? err.detail : undefined
            // If it is not an object or invalid object
            if (!err.code) return t('error.unexpected')
            return t('error.' + err.code, params) || t('error.unexpected')
        }
    }
}
