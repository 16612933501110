import { BoxDimensions } from 'src/api/defs/BoxDimensions.type'
import { strArea } from 'src/business/Warehouse/values/strArea'
import { formatNum } from 'src/i18n/formats'
import { LanguageFormat } from 'src/types/LanguageFormat'

export const strDimensions = (
    { area, height, length, width }: BoxDimensions,
    format?: LanguageFormat
): string =>
    `${strArea(area, format)} (${formatNum(width, format)} x ${formatNum(
        length,
        format
    )} x ${formatNum(height, format)} m)`
