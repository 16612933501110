import { RouterLocation } from '@ps-aux/react-app-core'

export const superadminContext = '/superadmin'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const SuperadminPages = {
    Root: {
        path: superadminContext,
        comp: () => import('src/superadmin/Superadmin'),
        to: (): string => superadminContext
    },
    JobRunList: {
        path: `${superadminContext}/jobs`,
        comp: () => import('src/superadmin/jobs/JobRunsPage'),
        to: (): string => `${superadminContext}/jobs`
    },
    JobRunDetail: {
        path: `${superadminContext}/jobs/:id`,
        comp: () => import('src/superadmin/jobs/JobRunDetailPage'),
        to: (id: string): string => `${superadminContext}/jobs/${id}`
    },
    Orders: {
        path: `${superadminContext}/orders`,
        comp: () => import('src/superadmin/orders/OrdersPage'),
        to: (): string => `${superadminContext}/orders`
    },
    Documents: {
        path: '/documents',
        comp: () => import('src/superadmin/Documents/DocumentsPage'),
        to: (): string => '/documents'
    },
    Rents: {
        path: `${superadminContext}/rents`,
        comp: () => import('src/superadmin/Rent/SuperAdminRentsPage'),
        to: (): string => `${superadminContext}/rents`
    },
    BrowserErrors: {
        path: `${superadminContext}/browser-errors`,
        comp: () => import('src/superadmin/BrowserErrors/BrowserErrorsPage'),
        to: (): string => `${superadminContext}/browser-errors`
    },
    NotificationAudit: {
        path: `${superadminContext}/notification-audit`,
        comp: () =>
            import('src/superadmin/NotificationAudit/NotificationAuditPage'),
        to: (): string => `${superadminContext}/notification-audit`
    }
}

export const isSuperadminPage = (loc: RouterLocation): boolean =>
    loc.path.startsWith(superadminContext)
