import { apiOps } from '../../api/defs/apiOps'
import { HigherApiClient } from '../../api/client/ApiClient'
import { Coordinates } from '../../api/defs/Coordinates.type'
import { getGeolocation } from '../location/getLocationService'

const ops = apiOps.AdminCommonLockV2

export class AdminCommonLockApi {
    constructor(private api: HigherApiClient) {}

    unlockBox = async (id: number): Promise<number> =>
        this.api.doForId<number, Coordinates>(
            ops.unlockBox,
            null,
            id.toString(),
            await getGeolocation()
        )

    unlockWarehouse = async (id: number): Promise<number> =>
        this.api.doForId<number, Coordinates>(
            ops.unlockWarehouse,
            null,
            id.toString(),
            await getGeolocation()
        )

    unlockBackupWarehouse = async (id: number): Promise<number> =>
        this.api.do<number>(ops.unlockBackupWarehouse, {
            pathParams: { id: id },
            data: await getGeolocation()
        })

    getStates = async (): Promise<string[]> =>
        this.api.do<string[]>(ops.GetStates)
}
