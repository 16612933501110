export const KpiObsadenostDaily = {
  name: 'KpiObsadenostDaily',
  attrs: {
    date: {
      name: 'date',
      id: 'KpiObsadenostDaily.date',
      type: {
        name: 'object',
        of: 'Day'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    obsadenost: {
      name: 'obsadenost',
      id: 'KpiObsadenostDaily.obsadenost',
      type: {
        name: 'object',
        of: 'KpiObsadenost'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
