export const ClientUser = {
  name: 'ClientUser',
  attrs: {
    address: {
      name: 'address',
      id: 'ClientUser.address',
      type: {
        name: 'object',
        of: 'Address'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    company: {
      name: 'company',
      id: 'ClientUser.company',
      type: {
        name: 'object',
        of: 'CompanyDetails'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    dateOfBirth: {
      name: 'dateOfBirth',
      id: 'ClientUser.dateOfBirth',
      type: {
        name: 'object',
        of: 'Day'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    email: {
      name: 'email',
      id: 'ClientUser.email',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [
        {
          name: 'pattern',
          value: /^[a-zA-Z0-9+._%-]{1,256}@[a-zA-Z0-9][a-zA-Z0-9-]{0,64}(\.[a-zA-Z0-9][a-zA-Z0-9-]{0,64})*\.[a-zA-Z0-9][a-zA-Z0-9-]{0,25}$/
        }
      ],
      extra: {}
    },
    exportId: {
      name: 'exportId',
      id: 'ClientUser.exportId',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    firstName: {
      name: 'firstName',
      id: 'ClientUser.firstName',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    gender: {
      name: 'gender',
      id: 'ClientUser.gender',
      type: {
        name: 'enum',
        id: 'Gender',
        of: ['Male', 'Female']
      },
      required: false,
      validationRules: [],
      extra: {
        enumId: 'Gender'
      }
    },
    id: {
      name: 'id',
      id: 'ClientUser.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    lastName: {
      name: 'lastName',
      id: 'ClientUser.lastName',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    phoneNumber: {
      name: 'phoneNumber',
      id: 'ClientUser.phoneNumber',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [
        {
          name: 'pattern',
          value: /^(\+|00)(\s?\d){7,15}$/
        }
      ],
      extra: {}
    },
    userLanguage: {
      name: 'userLanguage',
      id: 'ClientUser.userLanguage',
      type: {
        name: 'enum',
        id: 'Language',
        of: ['SK', 'CS', 'EN', 'UK', 'PL']
      },
      required: false,
      validationRules: [],
      extra: {
        enumId: 'Language'
      }
    }
  }
};
