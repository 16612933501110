import { InputComponent } from 'src/ui/basic/input/types'
import { m } from 'src/api/model/model'
import { EntityInput } from 'src/model/modify/form/EntityInput'
import React, { ComponentType } from 'react'
import { RenderValue } from 'src/model/value'
import { AttributeRenderConfig } from 'src/model/attribute/rendering'
import { ValidationFunction } from 'src/validation/types'
import { notNull, treeValidator } from '@ps-aux/revalid'
import { CompanyDetails } from 'src/api/defs/CompanyDetails.type'
import { View } from 'src/ui/basic/View'
import { useTran } from '@ps-aux/react-app-core'
import { msg } from 'src/i18n/texts/keys'
import { LabeledDataValue } from 'src/model/detail/LabeledDataValue'

export const companyDetailsSchema = {
    name: [notNull()],
    ico: [notNull()],
    dic: [notNull()],
    paysDph: [notNull()],
    icoDph: []
}

export const icoDphError = [
    {
        code: 'revalid/rule/basic/not-null',
        error: 'cannot be null'
    }
]

const validate: () => ValidationFunction = () => data => {
    let errors = validateCompanyDetails(data)

    if (data?.paysDph && !data?.icoDph) {
        if (errors) {
            errors.icoDph = icoDphError
        } else {
            errors = {
                icoDph: icoDphError
            }
        }
    }

    return errors
}

const validateCompanyDetails: ValidationFunction =
    treeValidator(companyDetailsSchema).validate

export const CompanyDetailsEditor: InputComponent<CompanyDetails> = ({
    input,
    onFocus,
    onBlur
}) => {
    const onChange = (val: CompanyDetails) => {
        val.icoDph = val?.paysDph ? val.icoDph : undefined
        return val
    }

    return (
        <EntityInput
            model={m.CompanyDetails}
            validate={validate()}
            input={input}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
        />
    )
}
const CompanyDetailsValue: ComponentType<{ value: CompanyDetails }> = ({
    value
}) => {
    const t = useTran()
    return (
        <View>
            <LabeledDataValue label={t(msg.CompanyDetails.entity.attr.name)}>
                {value.name}
            </LabeledDataValue>
            <LabeledDataValue label={t(msg.CompanyDetails.entity.attr.ico)}>
                {value.ico}
            </LabeledDataValue>
            <LabeledDataValue label={t(msg.CompanyDetails.entity.attr.dic)}>
                {value.dic}
            </LabeledDataValue>
            <LabeledDataValue label={t(msg.CompanyDetails.entity.attr.paysDph)}>
                {value.paysDph}
            </LabeledDataValue>
            <LabeledDataValue label={t(msg.CompanyDetails.entity.attr.icoDph)}>
                {value.icoDph}
            </LabeledDataValue>
        </View>
    )
}

export const RenderCompanyDetailsValue: RenderValue<CompanyDetails> = value => (
    <CompanyDetailsValue value={value} />
)

export const CompanyDetailsRenderConfig: AttributeRenderConfig<CompanyDetails> =
    {
        editor: CompanyDetailsEditor,
        renderer: RenderCompanyDetailsValue,
        defaultValue: () => ({}),
        onChange: val => val
    }
