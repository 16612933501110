import { AttributeRenderConfig } from 'src/model/attribute/rendering'
import { Day } from 'src/api/defs/Day.type'
import { readOnlyObjectRenderConfig } from 'src/model/attribute/rendering/readOnlyObjectRenderconfig'
import React, { ComponentType } from 'react'
import { RenderValue } from 'src/model/value'
import { useFormatters } from 'src/formatters/useFormatters'
import { View } from 'src/ui/basic/View'

export const DayValue: ComponentType<{ value: Day }> = ({ value }) => {
    const { strDay } = useFormatters()
    const day = strDay(value)
    return <>{day}</>
}

export const RenderDayValue: RenderValue<Day> = value => (
    <View>
        <DayValue value={value} />
    </View>
)

export const DayValueFromTo: ComponentType<{ from: Day; to: Day }> = ({
    from,
    to
}) => {
    const { strDay } = useFormatters()
    const fromDay = strDay(from)
    const toDay = strDay(to)

    return (
        <>
            {fromDay} - {toDay}
        </>
    )
}

export const DayRenderConfig: AttributeRenderConfig<Day> =
    readOnlyObjectRenderConfig(RenderDayValue)
