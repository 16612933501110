import { FluentModel, FluentModelProp } from '@ps-aux/api-model-extensions'
import { AliasMappings } from 'src/model/lib/text/ModelTranslator'

// @ts-ignore
// Cannot  use FluentModel<any> https://www.youtrack.software/issue/BOXY-647
export type RawFluentModel = FluentModel

// @ts-ignore
export type RawProp = FluentModelProp

export class ModelAliasesBuilder {
    private entityMappings = new Map<string, FluentModel<any>>()

    private propMappings = new Map<RawProp, RawProp>()

    private propsToEntity = new Map<
        FluentModelProp<any, any>,
        FluentModel<any>
    >()

    entityProps = (
        e1: RawFluentModel,
        e2: RawFluentModel
    ): ModelAliasesBuilder => {
        this.entityMappings.set(e1._meta.name, e2)
        return this
    }

    prop = (p1: RawProp, p2: RawProp): ModelAliasesBuilder => {
        this.propMappings.set(p1, p2)

        return this
    }

    propAsEntity = (p: RawProp, e: RawFluentModel): ModelAliasesBuilder => {
        this.propsToEntity.set(p, e)

        return this
    }

    done = (): AliasMappings => ({
        props: this.propMappings,
        entityProps: this.entityMappings,
        propsToEntity: this.propsToEntity
    })
}
