export const JobRestApiTypes = {
  name: 'JobRestApiTypes',
  attrs: {
    job: {
      name: 'job',
      id: 'JobRestApiTypes.job',
      type: {
        name: 'enum',
        id: 'SuperAdminJobsRestApi$Job',
        of: [
          'PAYMENT_PROCESSING',
          'CANCEL_CONTRACTS',
          'NOTIFY_RENTS_WITH_PAYMENT_OVERDUE',
          'NOTIFY_PAYMENTS_TO_BE_ANNOUNCED',
          'NOTIFY_CONTRACTS_BEFORE_TERMINATION'
        ]
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'SuperAdminJobsRestApi$Job'
      }
    }
  }
};
