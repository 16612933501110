export const UserPages = {
    ClientAppUserList: {
        path: '/app-user/client',
        comp: () =>
            import('src/business/user/AppUser/screens/ClientAppUsersPage'),
        to: (): string => '/app-user/client'
    },
    AdminAppUserList: {
        path: '/app-user/admin',
        comp: () =>
            import('src/business/user/AppUser/screens/AdminAppUsersPage'),
        to: (): string => '/app-user/admin'
    },
    OwnerAppUserList: {
        path: '/app-user/owner',
        comp: () =>
            import('src/business/user/AppUser/screens/OwnerAppUsersPage'),
        to: (): string => '/app-user/owner'
    }
}
