import React, { ComponentType, createContext, useContext } from 'react'
import {
    AttributeRenderConfig,
    ResolveRenderConfig
} from 'src/model/attribute/rendering'
import { resolveBasicAttributeRenderConfig } from 'src/model/attribute/rendering/basicAttributeRendering'
import { Attribute } from '@ps-aux/swagger-codegen'
import { ModelTranslator } from 'src/model/lib/text/ModelTranslator'
import { useTran } from '@ps-aux/react-app-core'
import { m } from 'src/api/model/model'
import { ModelAliasesBuilder } from 'src/model/lib/text/ModelAliasesBuilder'
import { useUser } from 'src/auth/useUser'
import { UserRole } from '../auth/User'

export type ModelRenderingProviderProps = {
    renderConfigResolver?: ResolveRenderConfig
}

export type ModelRenderingContext = {
    resolveRenderConfig: (attr: Attribute) => AttributeRenderConfig<any>
    translator: ModelTranslator
    globalReadonly: boolean
}

const aliases = new ModelAliasesBuilder()
    // Owner
    .entityProps(m.Owner, m.Owner)
    // Warehouse
    .entityProps(m.WarehouseListItem, m.Warehouse)
    .entityProps(m.WarehouseFilter, m.Warehouse)
    .propAsEntity(m.WarehouseFilter.owner, m.Owner)
    .propAsEntity(m.WarehouseListItem.ownerName, m.Owner)
    .propAsEntity(m.KpiPerWarehouse.per.and().ownerName, m.Owner)
    .prop(m.KpiPerWarehouse.per.and().code, m.Warehouse.code)
    // Contract
    .entityProps(m.AdminContractListItem, m.MyContract)
    .propAsEntity(m.AdminContractListItem.contractBusinessId, m.AdminContract)
    .propAsEntity(m.AdminContractListItem.boxName, m.Box)
    .propAsEntity(m.AdminContractListItem.tenant.and().name, m.Tenant)
    .propAsEntity(m.AdminContract.contract.and().boxName, m.Box)
    .propAsEntity(m.AdminContract.contract.and().boxDimensions, m.BoxDimensions)
    .propAsEntity(m.WarehouseContractFilter.box, m.Box)
    .propAsEntity(m.WarehouseContractFilter.tenant, m.Tenant)
    .prop(m.WarehouseContractFilter.status, m.MyContract.status)
    .prop(
        m.WarehouseContractFilter.businessId,
        m.AdminContract.contract.and().businessId
    )
    .prop(m.AdminContractListItem.state, m.MyContract.status)
    .propAsEntity(m.OwnerAndClientContractFilter.box, m.Box)
    .propAsEntity(m.OwnerAndClientContractFilter.warehouse, m.Warehouse)
    .prop(m.OwnerAndClientContractFilter.status, m.MyContract.status)
    .prop(
        m.OwnerAndClientContractFilter.businessId,
        m.AdminContract.contract.and().businessId
    )
    // Rent
    .propAsEntity(m.AdminRent.myRent.and().boxName, m.Box)
    .propAsEntity(m.AdminRent.myRent.and().contractId, m.AdminContract)
    .propAsEntity(m.AdminRent.tenant.and().name, m.Tenant)
    .propAsEntity(m.AdminRentFilter.box, m.Box)
    .propAsEntity(m.AdminRentFilter.owner, m.Owner)
    .propAsEntity(m.AdminRentFilter.tenant, m.Tenant)
    .propAsEntity(m.AdminRentFilter.warehouse, m.Warehouse)
    .prop(m.AdminRentFilter.paid, m.AdminRent.paying.and().payMethod)
    // Referral
    .propAsEntity(
        m.AdminReferralRewardTransaction.contractBusinessId,
        m.AdminContract
    )
    .propAsEntity(m.AdminReferralRewardTransaction.boxName, m.Box)
    .propAsEntity(m.ReferralRewardTransactionFilter.box, m.Box)
    .prop(
        m.ReferralRewardTransactionFilter.referredUserName,
        m.ClientUser.firstName
    )
    // Box
    .entityProps(m.BoxFilter, m.Box)
    .propAsEntity(m.BoxFilter.tenant, m.Tenant)
    // App User
    .prop(m.AppUserFilter.name, m.ClientUser.firstName)
    .prop(m.AppUserFilter.email, m.ClientUser.email)
    // Dashboard
    .propAsEntity(m.DashboardFilter.warehouseId, m.Warehouse)
    .done()

// @ts-ignore
const ctx = createContext<ModelRenderingContext>(null)

export const ModelRenderingProvider: ComponentType<
    ModelRenderingProviderProps
> = ({ renderConfigResolver, children }) => {
    const t = useTran()
    return (
        <ctx.Provider
            value={{
                resolveRenderConfig: t => {
                    if (renderConfigResolver) {
                        const custom = renderConfigResolver(t)
                        if (custom) return custom
                    }
                    const basic = resolveBasicAttributeRenderConfig(t)

                    if (!basic)
                        throw new Error(
                            `Could not resolve rendering config for ${JSON.stringify(
                                t
                            )}`
                        )

                    return basic
                },
                translator: new ModelTranslator(t, aliases),
                globalReadonly: useUser()?.role === UserRole.OwnerReadOnly
            }}
        >
            {children}
        </ctx.Provider>
    )
}

export const useModelRendering = (): ModelRenderingContext => useContext(ctx)
