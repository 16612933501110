export const CreateUnlockTokenCmd = {
  name: 'CreateUnlockTokenCmd',
  attrs: {
    boxId: {
      name: 'boxId',
      id: 'CreateUnlockTokenCmd.boxId',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    email: {
      name: 'email',
      id: 'CreateUnlockTokenCmd.email',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    from: {
      name: 'from',
      id: 'CreateUnlockTokenCmd.from',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    to: {
      name: 'to',
      id: 'CreateUnlockTokenCmd.to',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    warehouseId: {
      name: 'warehouseId',
      id: 'CreateUnlockTokenCmd.warehouseId',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
