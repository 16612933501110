export const KpiObsadenost = {
  name: 'KpiObsadenost',
  attrs: {
    areal: {
      name: 'areal',
      id: 'KpiObsadenost.areal',
      type: {
        name: 'object',
        of: 'KpiObsadenostAreal'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    numeral: {
      name: 'numeral',
      id: 'KpiObsadenost.numeral',
      type: {
        name: 'object',
        of: 'KpiObsadenostNumeral'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
