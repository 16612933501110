import { FormErrors } from '@ps-aux/react-app-core/src/form/types'
import { ValidationError } from '@ps-aux/revalid'
import { useErrorTran } from 'src/validation/useErrorTran'
import { ValidationFunction } from 'src/validation/types'

export type Validate = (data: any) => FormErrors | null

export type RecursiveAttrErrors = {
    [key: string]: ValidationError[] | RecursiveAttrErrors
}

export const isAttrErrors = (obj: any): obj is ValidationError[] =>
    Array.isArray(obj)

export type RecursiveObjectValidationResult = null | RecursiveAttrErrors

const map = (
    errs: RecursiveAttrErrors,
    translate: (err: ValidationError) => string
): FormErrors => {
    const res: FormErrors = {}

    Object.entries(errs).forEach(([key, val]) => {
        if (isAttrErrors(val)) res[key] = val.map(translate)
        else {
            res[key] = map(val, translate)
        }
    })

    return res
}

export type TranslateValidationErrors = (
    data: RecursiveObjectValidationResult
) => FormErrors | null

// TODO bcs of invoicing details order page
export const useValidationErrorTranslation = (): TranslateValidationErrors => {
    const translate = useErrorTran()

    return errs => {
        if (!errs) return null
        return map(errs, e =>
            translate({
                code: e.code,
                detail: e.error
            })
        )
    }
}

export const useValidate = (validate: ValidationFunction): Validate => {
    const translate = useValidationErrorTranslation()

    return (data: any) => {
        const errs = validate(data)

        return translate(errs)
    }
}
