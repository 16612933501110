import React, { ComponentType, ReactNode } from 'react'
import { css } from 'src/ui/style/css'
import { Theme } from 'src/ui/style/theme/Theme'

type Level = 1 | 2

const getFontSize = (level: Level) => {
    let fontSize = '1em'
    switch (level) {
        case 1:
            fontSize = '1.7em'
            break
        case 2:
            fontSize = '1.4em'
            break
        default:
            throw new Error('Unsupported level of Heading')
    }
    return fontSize
}

export type HeadingProps = {
    level: Level
    children: ReactNode
    className?: string
}

type HeadingTag = 'h1' | 'h2'

export const Heading: ComponentType<HeadingProps> = ({
    level,
    children,
    className
}) => {
    const Comp = `h${level}` as HeadingTag
    const fontSize = getFontSize(level)
    return (
        <Comp
            className={className}
            css={(th: Theme) =>
                css`
                    font-size: ${fontSize};
                    font-weight: ${th.fontWeight.bold};
                `
            }
        >
            {children}
        </Comp>
    )
}
