export const UnlockTokenInfo = {
    name: 'UnlockTokenInfo',
    attrs: {
        boxId: {
            name: 'boxId',
            id: 'UnlockTokenInfo.boxId',
            type: {
                name: 'integer'
            },
            required: false,
            validationRules: [],
            extra: {}
        },
        createdAt: {
            name: 'createdAt',
            id: 'UnlockTokenInfo.createdAt',
            type: {
                name: 'string'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        email: {
            name: 'email',
            id: 'UnlockTokenInfo.email',
            type: {
                name: 'string'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        id: {
            name: 'id',
            id: 'UnlockTokenInfo.id',
            type: {
                name: 'integer'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        token: {
            name: 'token',
            id: 'UnlockTokenInfo.token',
            type: {
                name: 'string'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        validFrom: {
            name: 'validFrom',
            id: 'UnlockTokenInfo.validFrom',
            type: {
                name: 'string'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        validTo: {
            name: 'validTo',
            id: 'UnlockTokenInfo.validTo',
            type: {
                name: 'string'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        warehouseId: {
            name: 'warehouseId',
            id: 'UnlockTokenInfo.warehouseId',
            type: {
                name: 'integer'
            },
            required: true,
            validationRules: [],
            extra: {}
        }
    }
}
