import { Coordinates } from '../../api/defs/Coordinates.type'

export const getGeolocation = (): Promise<Coordinates> => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            position => {
                const { latitude, longitude } = position.coords
                resolve({ lat: latitude, lng: longitude })
            },
            error => {
                reject(error)
            }
        )
    })
}
