export const ContractCancellationOptions = {
  name: 'ContractCancellationOptions',
  attrs: {
    from: {
      name: 'from',
      id: 'ContractCancellationOptions.from',
      type: {
        name: 'object',
        of: 'Day'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
