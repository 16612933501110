import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useNonRerenderState } from 'src/ui/react/useNonRerenderState'

// Improved useState hook which detects, if component is mounted, so it wont try to call set function on unmounted comp
export function useMountedState<S>(
    initialState: S | (() => S)
): [S, Dispatch<SetStateAction<S>>] {
    const [isMounted, setIsMounted] = useNonRerenderState(false)
    const [val, setVal] = useState(initialState)
    useEffect(() => {
        setIsMounted(true)
        return () => {
            setIsMounted(false)
        }
    }, [])
    const setValIfMounted: Dispatch<SetStateAction<S>> = v => {
        if (isMounted.value) setVal(v)
    }
    return [val, setValIfMounted]
}
