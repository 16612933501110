export const BoxInsurance = {
  name: 'BoxInsurance',
  attrs: {
    coverage: {
      name: 'coverage',
      id: 'BoxInsurance.coverage',
      type: {
        name: 'object',
        of: 'Money'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'BoxInsurance.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    monthlyPrice: {
      name: 'monthlyPrice',
      id: 'BoxInsurance.monthlyPrice',
      type: {
        name: 'object',
        of: 'Money'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
