export const InvoiceItemVatPrice = {
  name: 'InvoiceItemVatPrice',
  attrs: {
    total: {
      name: 'total',
      id: 'InvoiceItemVatPrice.total',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    unit: {
      name: 'unit',
      id: 'InvoiceItemVatPrice.unit',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    vatRate: {
      name: 'vatRate',
      id: 'InvoiceItemVatPrice.vatRate',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
