export const Dashboard = {
  name: 'Dashboard',
  attrs: {
    kpisForInterval: {
      name: 'kpisForInterval',
      id: 'Dashboard.kpisForInterval',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'KpiPerInterval'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    kpisPerWarehouse: {
      name: 'kpisPerWarehouse',
      id: 'Dashboard.kpisPerWarehouse',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'KpiPerWarehouse'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    obsadenostCurrentDay: {
      name: 'obsadenostCurrentDay',
      id: 'Dashboard.obsadenostCurrentDay',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'CurrentObsadenost'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    obsadenostDaily: {
      name: 'obsadenostDaily',
      id: 'Dashboard.obsadenostDaily',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'KpiObsadenostDaily'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
