export const FranchiseContactPages = {
    List: {
        path: '/franchise-contact',
        comp: () =>
            import('src/business/FranchiseContact/FranchiseContactListPage'),
        to: (): string => '/franchise-contact'
    },
    Detail: {
        path: '/franchise-contact/:id',
        comp: () =>
            import('src/business/FranchiseContact/FranchiseContactDetailPage'),
        to: (id: string): string => `/franchise-contact/${id}`
    }
}
