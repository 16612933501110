// @ts-ignore
import CS from 'src/ui/country-selector/flags/cs.svg'
// @ts-ignore
import EN from 'src/ui/country-selector/flags/en.svg'
// @ts-ignore
import SK from 'src/ui/country-selector/flags/sk.svg'

export default {
    CS,
    EN,
    SK
}
