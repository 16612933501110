export const Interval = {
  name: 'Interval',
  attrs: {
    from: {
      name: 'from',
      id: 'Interval.from',
      type: {
        name: 'object',
        of: 'Day'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    to: {
      name: 'to',
      id: 'Interval.to',
      type: {
        name: 'object',
        of: 'Day'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
