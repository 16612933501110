import React from 'react'
import { ViewComp } from 'src/ui/basic/api/View'
import { css } from 'src/ui/style/css'

export const View: ViewComp = ({
    children,
    className,
    onClick,
    horizontal = false
}) => (
    <div
        onClick={onClick}
        css={css`
            display: flex;
            flex-direction: ${horizontal ? 'row' : 'column'};
            align-items: ${horizontal ? 'center' : ''};
            justify-content: center;
        `}
        className={className}
    >
        {children}
    </div>
)
