import { DictMessageSource } from '@ps-aux/react-app-core'

export const createMessageSource = (
    rows: any,
    languages: string[]
): DictMessageSource => {
    const res: Record<string, any> = {}

    languages.forEach(l => {
        res[l] = {}
    })
    rows.forEach((row: any) => {
        languages.forEach(l => {
            const key = row.key
            const text = row[l]
            if (!text)
                throw new Error(`Missing language ${l} text for key ${key}`)

            res[l][key] = row[l]
        })
    })

    return res
}
