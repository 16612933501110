export const SetLocalizationCmd = {
  name: 'SetLocalizationCmd',
  attrs: {
    country: {
      name: 'country',
      id: 'SetLocalizationCmd.country',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    language: {
      name: 'language',
      id: 'SetLocalizationCmd.language',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
