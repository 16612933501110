import { ResolveRenderConfig } from 'src/model/attribute/rendering'
import {
    MoneyRenderingCfg,
    MoneyRenderingCfgWithoutCurrency
} from 'src/app/value/MoneyRenderingCfg'
import { AddressRenderConfig } from 'src/app/value/AddressRenderConfig'
import { isValueObjectOfType } from 'src/app/value/isValueObjectOfType'
import { m } from 'src/api/model/model'
import { CompanyDetailsRenderConfig } from 'src/app/value/CompanyDetailsRenderConfig'
import { VatPriceRenderingCfg } from 'src/app/value/VatPriceRenderingCfg'
import { DayRenderConfig } from 'src/app/value/DayRenderConfig'

export const valueObjectsRenderConfigResolver: ResolveRenderConfig = attr => {
    if (isValueObjectOfType(attr, m.Money)) return MoneyRenderingCfg
    if (isValueObjectOfType(attr, m.MoneyWithoutCurrency))
        return MoneyRenderingCfgWithoutCurrency
    if (isValueObjectOfType(attr, m.VatPrice)) return VatPriceRenderingCfg
    if (isValueObjectOfType(attr, m.Address)) return AddressRenderConfig
    if (isValueObjectOfType(attr, m.CompanyDetails))
        return CompanyDetailsRenderConfig
    if (isValueObjectOfType(attr, m.Day)) return DayRenderConfig
}
