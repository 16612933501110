import React from 'react'
import { icon } from 'src/ui/basic/icon/icons'
import { Icon } from 'src/ui/basic/icon/Icon'
import { RenderValue } from 'src/model/value/index'
import { Text } from 'src/ui/basic/Text'
import { iconStyle } from 'src/ui/style/mixins/other'
import { DateValue } from 'src/app/value/DateRenderConfig'

export const renderString: RenderValue<string> = val => <Text>{val}</Text>

export const renderBoolean: RenderValue<boolean> = val => (
    <Icon icon={val ? icon.Ok : icon.Close} css={iconStyle()} />
)

export const renderDate: RenderValue<Date> = value => (
    <span>{value ? <DateValue value={value} /> : ''}</span>
)
