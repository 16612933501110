import { css } from 'src/ui/style/css'
import React, { ComponentType } from 'react'
import { NavItem } from 'src/layout/header/Header'
import { View } from 'src/ui/basic/View'
import { useTran } from '@ps-aux/react-app-core'
import { NavLink } from 'src/layout/header/NavLink'
import { useTheme } from 'src/ui/style/theme/useTheme'
import { UserPanel } from 'src/layout/header/UserPanel'

type DesktopNavigationProps = {
    navItems: NavItem[]
}

export const DesktopNavigation: ComponentType<DesktopNavigationProps> = ({
    navItems
}) => {
    const t = useTran()
    const th = useTheme()
    return (
        <View
            horizontal
            css={css`
                justify-content: flex-end;
                > * {
                    margin-left: ${th.spacing.md};
                }
            `}
        >
            {navItems.map(([label, path]) => (
                <NavLink key={label} to={path}>
                    {t(label)}
                </NavLink>
            ))}
            <View
                horizontal
                css={th => css`
                    > *:not(:first-child) {
                        margin-left: ${th.spacing.md};
                    }
                `}
            >
                <UserPanel />
            </View>
        </View>
    )
}
