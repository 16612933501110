import { AxiosInstance } from 'axios'

export class VersionProvider {
    constructor(private http: AxiosInstance) {}

    private getVersionFromEndpoint = (path: string): Promise<string> =>
        this.http.get(path).then(r => r.data.version as string)

    getVersion = (): Promise<string> => this.getVersionFromEndpoint('/_info')

    getApiVersion = (): Promise<string> =>
        this.getVersionFromEndpoint('/api/info')
}
