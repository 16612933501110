import { Select } from 'src/ui/basic/Select'
import React, { ComponentType } from 'react'
import { EnumDef } from 'src/model/enum/EnumDef'
import { css } from 'src/ui/style/css'
import { InputComponentProps } from 'src/ui/basic/input/types'
import { useModelRendering } from 'src/model/ModelRenderingProvider'

export type EnumSelectProps = InputComponentProps & {
    of: EnumDef
    placeholder?: string
    nullable?: boolean
    width?: number
}

export const EnumSelect: ComponentType<EnumSelectProps> = ({
    of,
    nullable = false,
    placeholder,
    width,
    input
}) => {
    const { value, onChange } = input
    const { translator } = useModelRendering()
    const options = of.of.map(value => ({
        value,
        label: translator.enum(of, value)
    }))
    return (
        <Select
            value={value}
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            noValueOption={nullable}
            displayDropdownIndicator
            css={
                width &&
                css`
                    width: ${width}em;
                `
            }
        />
    )
}
