import React, { ComponentType } from 'react'
import { css } from 'src/ui/style/css'

export type ImageProps = {
    src: string
    className?: string
    height?: number
    width?: number
    fitToParent?: boolean
}

const fitToParentStyle = css`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

export const Image: ComponentType<ImageProps> = ({
    src,
    height,
    width,
    className,
    fitToParent
}) => (
    <img
        height={height}
        width={width}
        src={src}
        css={css`
            ${fitToParent && fitToParentStyle};
        `}
        className={className}
    />
)
