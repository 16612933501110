export const KpiStatistikyZmluv = {
  name: 'KpiStatistikyZmluv',
  attrs: {
    newContracts: {
      name: 'newContracts',
      id: 'KpiStatistikyZmluv.newContracts',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    terminatedContracts: {
      name: 'terminatedContracts',
      id: 'KpiStatistikyZmluv.terminatedContracts',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
