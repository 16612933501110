export const RuntimeInfo = {
  name: 'RuntimeInfo',
  attrs: {
    apiVersion: {
      name: 'apiVersion',
      id: 'RuntimeInfo.apiVersion',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    defaultCountry: {
      name: 'defaultCountry',
      id: 'RuntimeInfo.defaultCountry',
      type: {
        name: 'enum',
        id: 'Country',
        of: ['SVK', 'CZE', 'POL']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'Country'
      }
    },
    defaultLanguage: {
      name: 'defaultLanguage',
      id: 'RuntimeInfo.defaultLanguage',
      type: {
        name: 'enum',
        id: 'Language',
        of: ['SK', 'CZ', 'EN', 'UA', 'PL']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'Language'
      }
    },
    name: {
      name: 'name',
      id: 'RuntimeInfo.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    version: {
      name: 'version',
      id: 'RuntimeInfo.version',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
