export const BoxGroup = {
  name: 'BoxGroup',
  attrs: {
    boxes: {
      name: 'boxes',
      id: 'BoxGroup.boxes',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'BoxItem'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    size: {
      name: 'size',
      id: 'BoxGroup.size',
      type: {
        name: 'enum',
        id: 'Box$BoxSize',
        of: ['XS', 'SMALL', 'MEDIUM', 'LARGE']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'Box$BoxSize'
      }
    }
  }
};
