export const KpiPerWarehouse = {
  name: 'KpiPerWarehouse',
  attrs: {
    data: {
      name: 'data',
      id: 'KpiPerWarehouse.data',
      type: {
        name: 'object',
        of: 'Kpi'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    per: {
      name: 'per',
      id: 'KpiPerWarehouse.per',
      type: {
        name: 'object',
        of: 'AdminWarehouseListItem'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
