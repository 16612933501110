import { ApiOpDef, HigherApiClient } from 'src/api/client/ApiClient'
import { NoListFilter } from 'src/model/api/ListReq'
import { AdminWarehouse } from 'src/api/defs/AdminWarehouse.type'
import { AdminContractListItem } from 'src/api/defs/AdminContractListItem.type'
import { m } from 'src/api/model/model'
import { AdminWarehouseListItem } from 'src/api/defs/AdminWarehouseListItem.type'
import { BoxWithInUseInfo } from 'src/api/defs/BoxWithInUseInfo.type'
import { apiOps } from 'src/api/defs/apiOps'
import { FetchList } from 'src/model/list/FetchList'
import { FetchRelated } from 'src/model/related/FetchRelated'
import { WarehouseFilterForAdmin as ApiWarehouseFilter } from 'src/api/defs/WarehouseFilterForAdmin.type'
import { AllNullable } from 'src/api/AllNullable'
import { DeactivateWarehouseCmd } from '../../api/defs/DeactivateWarehouseCmd.type'
import { WarehouseEditCmd } from '../../api/defs/WarehouseEditCmd.type'

type WarehouseMixedOps = {
    detail: ApiOpDef
    getAll: ApiOpDef
    contracts: ApiOpDef
    boxes: ApiOpDef
    deactivate: ApiOpDef
    cancelDeactivation: ApiOpDef
    edit: ApiOpDef
}

export type WarehouseFilter = AllNullable<ApiWarehouseFilter>

// TODO merge with MultiRoleWarehouseApi
export class WarehouseMultiRoleReadApi {
    constructor(private api: HigherApiClient, private isOwner: () => boolean) {}

    private ops = (): WarehouseMixedOps =>
        this.isOwner() ? apiOps.OwnerUserWarehouse : apiOps.AdminWarehouse

    all: FetchList<AdminWarehouseListItem, WarehouseFilter> = req =>
        this.api.getPage<AdminWarehouseListItem, NoListFilter>(
            this.ops().getAll,
            m.WarehouseListItem,
            req
        )

    contracts: FetchRelated<AdminContractListItem> = (id, req) =>
        this.api.getPage<AdminContractListItem, any>(
            this.ops().contracts,
            m.AdminContractListItem,
            req,
            {
                id
            }
        )

    boxes: FetchRelated<BoxWithInUseInfo> = (id, req) =>
        this.api.getPage<BoxWithInUseInfo, any>(
            this.ops().boxes,
            m.BoxWithInUseInfo,
            req,
            {
                id
            }
        )

    detail = (id: string): Promise<AdminWarehouse> =>
        this.api.doForId<AdminWarehouse>(this.ops().detail, m.Warehouse, id)

    deactivate = (id: string, cmd: DeactivateWarehouseCmd): Promise<any> =>
        this.api.doForId<void, DeactivateWarehouseCmd>(
            this.ops().deactivate,
            null,
            id,
            cmd
        )

    cancelDeactivation = (id: string): Promise<any> =>
        this.api.doForId<void>(this.ops().cancelDeactivation, null, id)

    edit = (id: string, cmd: WarehouseEditCmd): Promise<any> =>
        this.api.doForId<void, WarehouseEditCmd>(this.ops().edit, null, id, cmd)
}
