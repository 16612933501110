import React, { ComponentType, ReactNode } from 'react'
import { View } from 'src/ui/basic/View'
import { css } from 'src/ui/style/css'

type PageContentProps = { children: ReactNode; className?: string }
export const PageContent: ComponentType<PageContentProps> = ({
    children,
    className
}) => (
    <View
        className={className}
        css={th => css`
            width: 100%;
            max-width: 1600px;
            margin: 0 auto;
            padding: ${th.spacing.md};
        `}
    >
        {children}
    </View>
)
