import { FluentModel, mapObjectValues } from '@ps-aux/api-model-extensions'

const mapFromApiDate = (val: any, info: { type: { name: string } }): any => {
    if (info.type.name !== 'date') return val
    return new Date(val)
}

export const mapDateValues = <T = any>(
    m: FluentModel<T>,
    obj: Record<string, any>
): any => mapObjectValues(m, mapFromApiDate, obj)
