import { AxiosInstance } from 'axios'
import {
    ApiPageResponse,
    fromPageRes,
    pageReq
} from 'src/api/collection/ApiPageRequest'
import { ListFilter, ListReq } from 'src/model/api/ListReq'
import { sortReq } from 'src/api/collection/Sort'
import { Page } from 'src/model/api/Page'
import { FluentModel } from '@ps-aux/api-model-extensions'
import { mapDateValues } from 'src/api/model/mapDateProps'
// @ts-ignore
import _flatten from 'flat'
import { toast } from 'react-toastify'
import { AppError } from '../../error/AppError'
import { getErrorMessage, translate } from '../../i18n/customTranslate'

// @ts-ignore circular reference
type Obj = Record<string, Obj>

export type PathParams = Record<string, string | number>

const flattenQueryPrams = (obj: Obj): Record<string, string | number> => {
    return _flatten(obj)
}

export type ApiOpOpts = {
    data?: any
    pathParams?: PathParams
    // eslint-disable-next-line @typescript-eslint/ban-types
    queryParams?: Obj
}

export type ApiOpDef = {
    method: string
    path: string
}

const paramRegex = /{.*}/

const findUnreplaced = (str: string) => str.match(paramRegex)

export class ApiClient {
    constructor(private http: AxiosInstance) {}

    do = <T>(
        op: ApiOpDef,
        opts: ApiOpOpts = {
            data: {}
        }
    ): Promise<T> => {
        let url = op.path

        if (opts.pathParams)
            Object.entries(opts.pathParams).forEach(([a, b]) => {
                if (!b) throw new Error(`Path param cannot be null (${a}=${b})`)
                url = url.replace(`{${a}}`, b + '')
            })

        const unreplaced = findUnreplaced(url)

        if (unreplaced) {
            const formatted = unreplaced.map(p => `'${p}'`)
            throw new Error(
                `Url ${url} contains unreplaced params: [${formatted}]`
            )
        }

        const method = op.method.toUpperCase()

        return this.http
            .request({
                // @ts-ignore
                method,
                url,
                params: opts.queryParams
                    ? flattenQueryPrams(opts.queryParams)
                    : {},
                data: opts.data
            })
            .then(r => {
                if ((r.status > 200 || r.status <= 300) && method !== 'GET') {
                    toast(r.statusText, {
                        type: 'success',
                        autoClose: 2000
                    })
                }
                return r.data
            })
            .catch((e: AppError) => {
                let errorMessages = ''

                // Unauthorized - redirect to log in
                if (e.status === 401) {
                    window.location.reload()
                    return Promise.reject(e)
                }

                const detailErrorMessage = getErrorMessage(e)

                // handle custom error translations
                if (detailErrorMessage !== 'NO_ERROR_MESSAGE_FOUND') {
                    errorMessages = `Request ${method.toUpperCase()}(${
                        e.status
                    }) -> '${op.path}' - ${detailErrorMessage}`

                    toast(errorMessages, {
                        type: 'error',
                        autoClose: false
                    })

                    return Promise.reject(e)
                }

                if (!e.code) {
                    errorMessages = `Request ${method.toUpperCase()}(${
                        e.status
                    }) -> '${op.path}' - Unexpected Error, StatusCode: ${
                        e.status
                    }`
                } else if (e.code) {
                    const translatedError = translate(e.code)

                    errorMessages = `Request ${method.toUpperCase()}(${
                        e.status
                    }) -> '${op.path}' - ${translatedError}`
                } else if (e.detail) {
                    if (Array.isArray(e.detail)) {
                        errorMessages = `Request ${method.toUpperCase()}(${
                            e.status
                        }) ->'${op.path}' - ${e.detail
                            .map(d => d?.field + ': ' + d?.defaultMessage)
                            .join(', ')}`
                    } else {
                        errorMessages = `Request ${method.toUpperCase()}(${
                            e.status
                        }) -> '${op.path}' - ${e?.detail}`
                    }
                }

                if (errorMessages) {
                    toast(errorMessages, {
                        type: 'error',
                        autoClose: false
                    })
                }

                return Promise.reject(e)
            })
    }
}

// TODO make cleaner - composition and factor out, quick solution
export class HigherApiClient extends ApiClient {
    private myHttp: AxiosInstance

    constructor(http: AxiosInstance) {
        super(http)
        this.myHttp = http
    }

    getPage = <T, Filter extends ListFilter>(
        op: ApiOpDef,
        m: FluentModel<T> | null, // TODO maybe another method
        req: ListReq<Filter>,
        pathParams: PathParams = {}
    ): Promise<Page<T>> =>
        this.do<ApiPageResponse<T>>(op, {
            pathParams,
            queryParams: {
                ...pageReq(req.page),
                ...sortReq(req.sort),
                ...req.filter
            }
        })
            .then((p: ApiPageResponse<T>) => fromPageRes(p))
            .then(p =>
                m
                    ? {
                          ...p,
                          items: p.items.map(i => this.deserialize(m, i))
                      }
                    : p
            )

    doForId = <T, D = any>(
        op: ApiOpDef,
        m: FluentModel<T> | null, // TODO maybe another method
        id: string,
        data?: D
    ): Promise<T> =>
        this.do<T>(op, {
            pathParams: {
                id
            },
            data
        }).then(r => (m ? this.deserialize(m, r) : r))

    uploadFile = (
        path: string,
        file: File,
        method: 'post' | 'put'
    ): Promise<any> => {
        const form = new window.FormData()
        form.append('file', file)
        return this.myHttp({
            url: path,
            method,
            data: form,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(r => r.data)
            .then(r => {
                toast(r, {
                    type: 'success',
                    autoClose: 2000
                })
            })
    }

    // We have any instead of T bcs of this error: https://github.com/microsoft/TypeScript/issues/31619
    private deserialize = <T>(m: FluentModel<any>, obj: any): T => {
        return mapDateValues(m, obj)
    }
}
