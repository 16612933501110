import React, { ComponentType } from 'react'
import { IconType } from 'react-icons'
import { css } from 'src/ui/style/css'

type IconProps = {
    icon: IconType
    onClick?: () => void
    className?: string
}
export const Icon: ComponentType<IconProps> = ({
    icon: IconComp,
    onClick,
    className
}) => (
    <IconComp
        onClick={onClick}
        className={className}
        css={css`
            cursor: ${onClick ? 'pointer' : 'inherit'};
        `}
    />
)
