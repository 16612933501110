export const BoxFrom = {
  name: 'BoxFrom',
  attrs: {
    areaFrom: {
      name: 'areaFrom',
      id: 'BoxFrom.areaFrom',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    priceFrom: {
      name: 'priceFrom',
      id: 'BoxFrom.priceFrom',
      type: {
        name: 'object',
        of: 'BoxConsumerPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
