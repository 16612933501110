export const BoxV1Lock = {
  name: 'BoxV1Lock',
  attrs: {
    // id: {
    //   name: 'id',
    //   id: 'BoxV1Lock.id',
    //   type: {
    //     name: 'string'
    //   },
    //   required: false,
    //   validationRules: [],
    //   extra: {}
    // },
    pin: {
      name: 'pin',
      id: 'BoxV1Lock.pin',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
