import React, {
    ComponentType,
    CSSProperties,
    ReactNode,
    useContext,
    useEffect,
    useState
} from 'react'
import ReactModal from 'react-modal'

export type RenderModelContent = (props: { close: () => void }) => ReactNode

export type ModalContext = {
    open: (render: RenderModelContent) => void
}

// @ts-ignore
const Context = React.createContext<ModalContext>(null)
const { Provider } = Context

const styles = (style: CSSProperties) => ({
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        overflow: 'visible',
        ...style
    },
    overlay: {
        zIndex: 9999
    }
})

export const useModal = (): ModalContext => useContext(Context)

export const ModalProvider: ComponentType<{
    appElement: HTMLElement
    style?: CSSProperties
}> = ({ children, appElement, style = {} }) => {
    const [open, setOpen] = useState(false)
    // TODO hack to prevent stale closure
    const [renderer, setRenderer] = useState<{
        render: RenderModelContent
    }>({
        render: () => <div>No Content</div>
    })

    useEffect(() => {
        ReactModal.setAppElement(appElement)
    }, [])

    const { render } = renderer
    return (
        <Provider
            value={{
                open: render => {
                    setRenderer({
                        render
                    })
                    setOpen(true)
                }
            }}
        >
            <ReactModal
                isOpen={open}
                // onAfterOpen={this.afterOpenModal}
                onRequestClose={() => {
                    // @ts-ignore, clean - to show errors in logic (should not happen that render is called when null)
                    setRenderer({})
                    setOpen(false)
                }}
                style={styles(style)}
            >
                {open &&
                    render({
                        close: () => setOpen(false)
                    })}
            </ReactModal>
            {children}
        </Provider>
    )
}
