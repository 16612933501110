export const DeactivationInfo = {
  name: 'DeactivationInfo',
  attrs: {
    availableFrom: {
      name: 'availableFrom',
      id: 'DeactivationInfo.availableFrom',
      type: {
        name: 'object',
        of: 'Day'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    deactivated: {
      name: 'deactivated',
      id: 'DeactivationInfo.deactivated',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
