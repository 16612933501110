export const OwnerPages = {
    Create: {
        path: '/owner/create',
        comp: () => import('src/business/Owner/OwnerCreatePage'),
        to: (): string => '/owner/create'
    },
    Detail: {
        path: '/owner/:id',
        comp: () => import('src/business/Owner/OwnerDetailPage'),
        to: (id: string): string => `/owner/${id}`
    },
    WarehouseCreate: {
        path: '/owner/:id/create-warehouse',
        comp: () => import('src/business/Warehouse/WarehouseCreatePage'),
        to: (id: string): string => `/owner/${id}/create-warehouse`
    },
    List: {
        path: '/owner',
        comp: () => import('src/business/Owner/OwnerListPage'),
        to: (): string => '/owner'
    }
}
