export const PromoDiscountEffect = {
  name: 'PromoDiscountEffect',
  attrs: {
    after: {
      name: 'after',
      id: 'PromoDiscountEffect.after',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    before: {
      name: 'before',
      id: 'PromoDiscountEffect.before',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    hasPromo: {
      name: 'hasPromo',
      id: 'PromoDiscountEffect.hasPromo',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    rate: {
      name: 'rate',
      id: 'PromoDiscountEffect.rate',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
