export const WarehousePodorys = {
  name: 'WarehousePodorys',
  attrs: {
    geometry: {
      name: 'geometry',
      id: 'WarehousePodorys.geometry',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'PodorysBox'
        }
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    obrazokUrl: {
      name: 'obrazokUrl',
      id: 'WarehousePodorys.obrazokUrl',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
