export const LockPin = {
  name: 'LockPin',
  attrs: {
    value: {
      name: 'value',
      id: 'LockPin.value',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
