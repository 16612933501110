export const DebitCmd = {
  name: 'DebitCmd',
  attrs: {
    amount: {
      name: 'amount',
      id: 'DebitCmd.amount',
      type: {
        name: 'object',
        of: 'Money'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
