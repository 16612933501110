export const WarehouseDiscount = {
  name: 'WarehouseDiscount',
  attrs: {
    key: {
      name: 'key',
      id: 'WarehouseDiscount.key',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    value: {
      name: 'value',
      id: 'WarehouseDiscount.value',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
