export const BoxFilter = {
  name: 'BoxFilter',
  attrs: {
    active: {
      name: 'active',
      id: 'BoxFilter.active',
      type: {
        name: 'boolean'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'BoxFilter.name',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    tenant: {
      name: 'tenant',
      id: 'BoxFilter.tenant',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    waitingForReadinessCheck: {
      name: 'waitingForReadinessCheck',
      id: 'BoxFilter.waitingForReadinessCheck',
      type: {
        name: 'boolean'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
