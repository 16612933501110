import React, { ComponentType } from 'react'
import { View } from 'src/ui/basic/View'
import { css } from 'src/ui/style/css'
import { detailPropLabel } from 'src/model/style/entityDetail'
import { Error as ErrorComp } from 'src/form/ui/Error'
import { FieldUiProps } from 'src/form/ui/UiField'
import { useTheme } from 'src/ui/style/theme/useTheme'

// Copied from WEB
export const FieldUi: ComponentType<FieldUiProps> = ({
    label,
    errors,
    labelPosition = 'top',
    children,
    required
}) => {
    const th = useTheme()
    const topLabel = labelPosition === 'top'
    return (
        <View
            css={
                errors?.length &&
                css`
                    > * {
                        color: ${th.colors.error};
                    }
                `
            }
        >
            <label
                css={css`
                    // Align label by topLabel prop either to top or to right
                    display: flex;
                    justify-content: flex-end;
                    align-items: ${topLabel ? 'inherit' : 'baseline'};
                    flex-direction: ${topLabel ? 'column' : 'row-reverse'};
                    color: ${th.fontColor.primary};
                `}
            >
                <span css={detailPropLabel()}>
                    {label}
                    {required && ' *'}
                </span>
                {children}
            </label>
            {/* For constant space under field */}
            <View
                css={th =>
                    css`
                        height: ${th.spacing.md};
                        font-size: ${th.spacing.md};
                        line-height: 1;
                        margin-top: 0.25em;
                    `
                }
            >
                {errors && <ErrorComp>{errors.join(', ')}</ErrorComp>}
            </View>
        </View>
    )
}
