export const FranchiseContact = {
  name: 'FranchiseContact',
  attrs: {
    businessExperience: {
      name: 'businessExperience',
      id: 'FranchiseContact.businessExperience',
      type: {
        name: 'enum',
        id: 'FranchiseContact$BusinessExperience',
        of: ['NO_EXPERIENCE', 'ONE_TO_FIVE', 'GREATER_THAN_FIVE']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'FranchiseContact$BusinessExperience'
      }
    },
    capitalInvestment: {
      name: 'capitalInvestment',
      id: 'FranchiseContact.capitalInvestment',
      type: {
        name: 'enum',
        id: 'FranchiseContact$CapitalInvestment',
        of: [
          'LESS_THAN_30',
          'BETW_30_AND_40',
          'BETW_40_AND_50',
          'GREATER_THAN_50'
        ]
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'FranchiseContact$CapitalInvestment'
      }
    },
    cityToOpen: {
      name: 'cityToOpen',
      id: 'FranchiseContact.cityToOpen',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    createdAt: {
      name: 'createdAt',
      id: 'FranchiseContact.createdAt',
      type: {
        name: 'date'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    currentPositionAndArea: {
      name: 'currentPositionAndArea',
      id: 'FranchiseContact.currentPositionAndArea',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    email: {
      name: 'email',
      id: 'FranchiseContact.email',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [
        {
          name: 'pattern',
          value: /^[a-zA-Z0-9+._%-]{1,256}@[a-zA-Z0-9][a-zA-Z0-9-]{0,64}(\.[a-zA-Z0-9][a-zA-Z0-9-]{0,64})*\.[a-zA-Z0-9][a-zA-Z0-9-]{0,25}$/
        }
      ],
      extra: {}
    },
    exportId: {
      name: 'exportId',
      id: 'FranchiseContact.exportId',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    fullName: {
      name: 'fullName',
      id: 'FranchiseContact.fullName',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    hasReadFranchiseBrochure: {
      name: 'hasReadFranchiseBrochure',
      id: 'FranchiseContact.hasReadFranchiseBrochure',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'FranchiseContact.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    isPrimaryIncome: {
      name: 'isPrimaryIncome',
      id: 'FranchiseContact.isPrimaryIncome',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    motivation: {
      name: 'motivation',
      id: 'FranchiseContact.motivation',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    phoneNumber: {
      name: 'phoneNumber',
      id: 'FranchiseContact.phoneNumber',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [
        {
          name: 'pattern',
          value: /^(\+|00)(\s?\d){7,15}$/
        }
      ],
      extra: {}
    },
    propertyAreaInM2: {
      name: 'propertyAreaInM2',
      id: 'FranchiseContact.propertyAreaInM2',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    propertyStatus: {
      name: 'propertyStatus',
      id: 'FranchiseContact.propertyStatus',
      type: {
        name: 'enum',
        id: 'FranchiseContact$PropertyStatus',
        of: ['HAS_PROPERTY', 'WANTS_RENT', 'WANTS_BUY_PROPERTY']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'FranchiseContact$PropertyStatus'
      }
    },
    returnOnInvestment: {
      name: 'returnOnInvestment',
      id: 'FranchiseContact.returnOnInvestment',
      type: {
        name: 'enum',
        id: 'FranchiseContact$InvestmentReturnPeriod',
        of: ['ONE_TO_TWO_YEARS', 'THREE_TO_FIVE_YEARS', 'SIX_AND_MORE']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'FranchiseContact$InvestmentReturnPeriod'
      }
    }
  }
};
