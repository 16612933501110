import {
    AttributeValueEditor,
    ResolveRenderConfig
} from 'src/model/attribute/rendering/index'
import {
    renderBoolean,
    renderDate,
    renderString
} from 'src/model/value/renderers'
import { TextInput } from 'src/ui/basic/input/TextInput'
import { CheckBox } from 'src/ui/basic/input/CheckBox'
import { EnumSelect } from 'src/model/enum/EnumSelect'
import { EnumDef } from 'src/model/enum/EnumDef'
import React, { ComponentType } from 'react'
import { DateTimePicker } from 'src/ui/basic/DateTimePicker'
import { Text } from 'src/ui/basic/Text'
import { NumberInput } from 'src/ui/basic/input/NumberInput'
import { useModelRendering } from 'src/model/ModelRenderingProvider'

const StringEditor: AttributeValueEditor<string> = TextInput

const NumberEditor: AttributeValueEditor<number> = NumberInput

const EnumRenderer: ComponentType<{ enm: EnumDef; value: string }> = ({
    enm,
    value
}) => {
    const { translator } = useModelRendering()

    return <Text>{translator.enum(enm, value)}</Text>
}

export const resolveBasicAttributeRenderConfig: ResolveRenderConfig = attr => {
    const t = attr.type.name
    if (t === 'string')
        return {
            renderer: renderString,
            editor: StringEditor,
            defaultValue: () => ''
        }

    if (t === 'enum') {
        // @ts-ignore
        const enm: EnumDef = attr.type
        return {
            renderer: value => <EnumRenderer value={value} enm={enm} />,
            editor: ({ input }) => <EnumSelect input={input} of={enm} />,
            defaultValue: () => enm.of[0]
        }
    }

    if (t === 'double' || t === 'integer')
        return {
            renderer: (x: number) => renderString(x.toString()),
            editor: NumberEditor,
            defaultValue: () => 0
        }

    if (t === 'date') {
        return {
            renderer: renderDate,
            editor: ({ input }) => (
                <DateTimePicker timeInput={false} input={input} />
            ),
            defaultValue: () => new Date()
        }
    }

    if (t === 'boolean') {
        return {
            renderer: renderBoolean,
            editor: CheckBox,
            defaultValue: () => false
        }
    }

    if (t === 'object') {
        return {
            renderer: (obj: any) => renderString(JSON.stringify(obj)),
            editor: ({ input }) => {
                return renderString(JSON.stringify(input.value))
            },
            defaultValue: () => ({})
        }
    }

    throw new Error(
        'Could not resolve rendering config for attribute' +
            JSON.stringify(attr)
    )
}
