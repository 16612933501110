export const SubscribeToNewFreeBoxBody = {
  name: 'SubscribeToNewFreeBoxBody',
  attrs: {
    email: {
      name: 'email',
      id: 'SubscribeToNewFreeBoxBody.email',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    subscribeToNewsletter: {
      name: 'subscribeToNewsletter',
      id: 'SubscribeToNewFreeBoxBody.subscribeToNewsletter',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
