import { apiOps } from 'src/api/defs/apiOps'
import { HigherApiClient } from 'src/api/client/ApiClient'
import { WarehouseEditCmd } from 'src/api/defs/WarehouseEditCmd.type'
import { WarehouseCreateCmd } from 'src/api/defs/WarehouseCreateCmd.type'
import { DeactivateWarehouseCmd } from 'src/api/defs/DeactivateWarehouseCmd.type'
import { SetGeometryCmd } from 'src/api/defs/SetGeometryCmd.type'
import { BoxGeometryInput } from 'src/api/defs/BoxGeometryInput.type'
import { WarehousePodorys } from 'src/api/defs/WarehousePodorys.type'
import { PromoWithBoxes } from 'src/api/defs/PromoWithBoxes.type'
import { PromoDiscount } from 'src/api/defs/PromoDiscount.type'
import { Box } from 'src/api/defs/Box.type'

const ops = apiOps.AdminWarehouse

// TODO merge with WarehouseMultiRoleReadApi
export class MultiRoleWarehouseApi {
    constructor(private api: HigherApiClient, private isOwner: () => boolean) {}

    create = (cmd: WarehouseCreateCmd): Promise<any> =>
        this.api.do<WarehouseCreateCmd>(ops.create, { data: cmd })

    edit = (id: string, cmd: WarehouseEditCmd): Promise<any> =>
        this.api.doForId<void, WarehouseEditCmd>(ops.edit, null, id, cmd)

    deactivate = (id: string, cmd: DeactivateWarehouseCmd): Promise<any> =>
        this.api.doForId<void, DeactivateWarehouseCmd>(
            ops.deactivate,
            null,
            id,
            cmd
        )

    cancelDeactivation = (id: string): Promise<any> =>
        this.api.doForId<void>(ops.cancelDeactivation, null, id)

    private warehouseImagesOps = () =>
        this.isOwner()
            ? apiOps.OwnerUserWarehouseImages
            : apiOps.AdminWarehouseImages

    image = {
        getAll: (data: { id: string }): Promise<string[]> =>
            this.api.doForId<string[]>(
                this.warehouseImagesOps().getAll,
                null,
                data.id
            ),
        add: (id: string, f: File): Promise<void> =>
            this.api.uploadFile(
                this.warehouseImagesOps().add.path.replace('{id}', id),
                f,
                'post'
            ),
        change: (id: string, index: string, f: File): Promise<void> =>
            this.api.uploadFile(
                this.warehouseImagesOps()
                    .change.path.replace('{id}', id)
                    .replace('{index}', index),
                f,
                'put'
            ),
        delete: (id: string, index: string): Promise<void> =>
            this.api.do<void>(this.warehouseImagesOps().delete, {
                pathParams: {
                    id,
                    index
                }
            })
    }

    private warehousePodorysOps = () =>
        this.isOwner()
            ? apiOps.OwnerUserWarehousePodorys
            : apiOps.AdminUserWarehousePodorys

    podorys = {
        getGeometry: (id: string): Promise<BoxGeometryInput[]> =>
            this.api.doForId<BoxGeometryInput[]>(
                this.warehousePodorysOps().getGeometryInput,
                null,
                id
            ),
        get: (id: string): Promise<WarehousePodorys> =>
            this.api.doForId<WarehousePodorys>(
                this.warehousePodorysOps().get,
                null,
                id
            ),
        uploadImage: (id: string, f: File): Promise<void> =>
            this.api.uploadFile(
                this.warehousePodorysOps().setObrazok.path.replace('{id}', id),
                f,
                'put'
            ),

        uploadGeometry: (id: string, cmd: SetGeometryCmd): Promise<any> =>
            this.api.doForId<void>(
                this.warehousePodorysOps().setGeometry,
                null,
                id,
                cmd
            )
    }

    private warehousePromoOps = () =>
        this.isOwner()
            ? apiOps.OwnerUserWarehousePromo
            : apiOps.AdminWarehousePromo

    promo = {
        assignableBoxes: ({
            id,
            version
        }: {
            id: string
            version: number
        }): Promise<Box[]> =>
            this.api.do<Box[]>(this.warehousePromoOps().assignableBoxes, {
                pathParams: {
                    warehouseId: id
                }
            }),

        get: ({
            id,
            version
        }: {
            id: string
            version: number
        }): Promise<PromoWithBoxes> =>
            this.api.do<PromoWithBoxes>(this.warehousePromoOps().get, {
                pathParams: {
                    warehouseId: id
                }
            }),

        set: (id: string, data: PromoDiscount): Promise<void> =>
            this.api.do<void>(this.warehousePromoOps().set, {
                pathParams: {
                    warehouseId: id
                },
                data
            }),

        addBox: (id: string, boxId: string): Promise<void> =>
            this.api.do<void>(this.warehousePromoOps().add, {
                pathParams: {
                    warehouseId: id,
                    boxId
                }
            }),

        removeBox: (id: string, boxId: string): Promise<void> =>
            this.api.do<void>(this.warehousePromoOps().remove, {
                pathParams: {
                    warehouseId: id,
                    boxId
                }
            })
    }
}
