import React, { ComponentType, ReactNode } from 'react'
import { detailPropLabel } from 'src/model/style/entityDetail'
import { View } from 'src/ui/basic/View'
import { css } from 'src/ui/style/css'

export const LabeledDataValue: ComponentType<{
    label: string
    children: ReactNode
}> = ({ label, children }) => (
    <View
        css={css`
            // Align children to the left
            align-items: flex-start;
        `}
    >
        <label css={detailPropLabel()}>{label}</label>
        {children}
    </View>
)
