export const ReferralRewardAccountState = {
  name: 'ReferralRewardAccountState',
  attrs: {
    balance: {
      name: 'balance',
      id: 'ReferralRewardAccountState.balance',
      type: {
        name: 'object',
        of: 'Money'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    totalCredit: {
      name: 'totalCredit',
      id: 'ReferralRewardAccountState.totalCredit',
      type: {
        name: 'object',
        of: 'Money'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    totalDebit: {
      name: 'totalDebit',
      id: 'ReferralRewardAccountState.totalDebit',
      type: {
        name: 'object',
        of: 'Money'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
