import { formatLocale } from 'src/i18n/formatSettings'

export const formatNum = (v: number, locale?: string): string => {
    const _NumFormat = new Intl.NumberFormat(locale ?? formatLocale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })

    return _NumFormat.format(v)
}
