export const AdminReferralRewardTransaction = {
  name: 'AdminReferralRewardTransaction',
  attrs: {
    amount: {
      name: 'amount',
      id: 'AdminReferralRewardTransaction.amount',
      type: {
        name: 'object',
        of: 'Money'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    boxName: {
      name: 'boxName',
      id: 'AdminReferralRewardTransaction.boxName',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    client: {
      name: 'client',
      id: 'AdminReferralRewardTransaction.client',
      type: {
        name: 'object',
        of: 'ClientInfo'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    contractBusinessId: {
      name: 'contractBusinessId',
      id: 'AdminReferralRewardTransaction.contractBusinessId',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    time: {
      name: 'time',
      id: 'AdminReferralRewardTransaction.time',
      type: {
        name: 'date'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    type: {
      name: 'type',
      id: 'AdminReferralRewardTransaction.type',
      type: {
        name: 'enum',
        id: 'ReferralRewardTransaction$Type',
        of: ['CREDIT', 'DEBIT']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'ReferralRewardTransaction$Type'
      }
    },
    warehouse: {
      name: 'warehouse',
      id: 'AdminReferralRewardTransaction.warehouse',
      type: {
        name: 'object',
        of: 'WarehouseInfo'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
