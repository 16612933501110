import { View } from 'src/ui/basic/View'
import { css } from 'src/ui/style/css'
import { Text } from 'src/ui/basic/Text'
import { TextWithLink } from 'src/ui/text/TextWithLink'
import React, { ComponentType } from 'react'

export type SubmitSuccessProps = {
    text: string
    callToAction?: {
        text: string
        link: { to: string; text: string }
    }
}

export const SubmitSuccess: ComponentType<SubmitSuccessProps> = ({
    text,
    callToAction
}) => (
    <View
        css={css`
            text-align: center;
        `}
    >
        <Text>{text}</Text>
        {callToAction && (
            <TextWithLink
                text={callToAction.text}
                link={{
                    to: callToAction.link.to,
                    text: callToAction.link.text
                }}
            />
        )}
    </View>
)
