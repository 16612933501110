export const getEnvConfVal = (name: string): string => {
    return get(name)
}

const get = (key: string): string => {
    let val
    const meta = document.querySelector(`meta[name=${key}]`)
    if (meta) val = meta.getAttribute('content')
    if (!val) throw new Error(`Missing "${key}" config value`)

    return val
}
