import { HigherApiClient } from 'src/api/client/ApiClient'
import { ListReq, NoListFilter } from 'src/model/api/ListReq'
import { Page } from 'src/model/api/Page'
import { apiOps } from 'src/api/defs/apiOps'
import { m } from 'src/api/model/model'
import { SuperAdminOrder } from 'src/api/defs/SuperAdminOrder.type'

export class OrdersApi {
    constructor(private api: HigherApiClient) {}

    list = (req: ListReq<NoListFilter>): Promise<Page<SuperAdminOrder>> =>
        this.api.getPage<SuperAdminOrder, NoListFilter>(
            apiOps.SuperadminOrders.list,
            m.Order,
            req
        )

    cancel = (id: number): Promise<void> =>
        this.api.doForId(apiOps.SuperadminOrders.cancel, null, id.toString())
}
