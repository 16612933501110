import { useTran } from '@ps-aux/react-app-core'
import { Button } from 'src/ui/basic/Button'
import React, { ComponentType } from 'react'
import { getAppContext } from 'src/context/AppContext'
import { NavLink } from 'src/layout/header/NavLink'
import { useUser } from 'src/auth/useUser'
import { msg } from 'src/i18n/texts/keys'
import { LanguageSwitcher } from './LanguageSwitcher'

type UserPanelProps = {
    onClick?: () => void
}

export const UserPanel: ComponentType<UserPanelProps> = ({ onClick }) => {
    const t = useTran()
    const user = useUser()
    if (!user) return null
    const { logOut } = getAppContext().user.service

    return (
        <>
            <LanguageSwitcher />
            <NavLink
                // TODO @qubis741 need real pages
                // to={r.linkTo(pages.MyProfile)}
                to={'/'}
                onClick={onClick}
            >
                {user.username}({user.role})
            </NavLink>
            <Button
                onClick={() => {
                    logOut()
                    onClick && onClick()
                }}
            >
                {/* // TODO proper translation generated from Web Client */}
                {t(msg.ui.nav.items.logout)}
            </Button>
        </>
    )
}
