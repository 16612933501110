export const BoxInUseInfo = {
  name: 'BoxInUseInfo',
  attrs: {
    from: {
      name: 'from',
      id: 'BoxInUseInfo.from',
      type: {
        name: 'date'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    to: {
      name: 'to',
      id: 'BoxInUseInfo.to',
      type: {
        name: 'date'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    usedBy: {
      name: 'usedBy',
      id: 'BoxInUseInfo.usedBy',
      type: {
        name: 'object',
        of: 'Tenant'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
