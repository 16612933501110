import { Sort } from 'src/model/api/ListReq'

export type ApiSort = {
    sort: string
}

export const sortReq = (s: Sort | null) =>
    s
        ? {
              sort: `${s.column},${s.direction}`
          }
        : {}
