import { formatLocale } from 'src/i18n/formatSettings'
import { LanguageFormat } from 'src/types/LanguageFormat'

export const strDateTime = (d: Date, format?: LanguageFormat): string => {
    const f = new Intl.DateTimeFormat(format ?? formatLocale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    })
    return f.format(d)
}
