import { createPersistentStore, ReadOnlyStore } from '@ps-aux/react-app-core'
import { User } from 'src/auth/User'
import { UserService } from 'src/auth/UserService'
import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { HttpError } from 'src/http/HttpError'

export type UserContext = {
    store: ReadOnlyStore<User | null>
    service: UserService
    httpRequestInterceptor: (req: AxiosRequestConfig) => AxiosRequestConfig
    handleHttpError: (err: HttpError) => void
}

export const userContext = (
    storage: Storage,
    createHttp: () => AxiosInstance
): UserContext => {
    const store = createPersistentStore<User | null>(
        window.localStorage,
        'user',
        null
    )

    const service = new UserService(createHttp(), store)
    return {
        store,
        service,
        httpRequestInterceptor: req => {
            const user = store.value()
            if (!user) throw new Error('Not logged in')

            req.headers = {
                ...req.headers,
                Authorization: `Bearer ${user.token}`
            }

            return req
        },
        handleHttpError: err => {
            // TODO check the code as well !!
            if (err.status === 401) {
                console.log('Token expired. Logging out.')
                service.logOut()
            }
        }
    }
}
