import { AppContext, getAppContext } from 'src/context/AppContext'
import { useEffect, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

export type FetchFromAppContext<T, Input> = (
    ctx: AppContext,
    input: Input
) => Promise<T>

const isPrimitive = (val: any) => val == null || /^[sbn]/.test(typeof val)

const getHook = (val: any) =>
    isPrimitive(val) ? useEffect : useDeepCompareEffect

export const useCtxFetch = <Res, Req>(
    f: (ctx: AppContext) => (r: Req) => Res
): ((r: Req) => Res) => {
    const ctx = getAppContext()

    return f(ctx)
}

export const ctxQuery =
    <Res, Req>(
        fetcher: (ctx: AppContext) => (i: Req) => Promise<Res>
    ): ((r?: Req) => Res | null) =>
    req => {
        const [val, set] = useState<Res | null>(null)

        const ctx = getAppContext()

        getHook(req)(() => {
            // @ts-ignore could be undefined - no args fun
            fetcher(ctx)(req).then(set)
        }, [req])

        return val
    }

export const createUseContextObjectFetcher =
    <CtxObj>(get: (ctx: AppContext) => CtxObj) =>
    <T, Input>(
        fetcher: (ctxObj: CtxObj) => (input: Input) => Promise<T>,
        input?: Input
    ): T | null =>
        ctxQuery<T, Input>(ctx => fetcher(get(ctx)))(input)
