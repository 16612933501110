import { useEffect } from 'react'
import throttle from 'lodash/throttle'

export const useWindowResize = (
    handle: (width: number, height: number) => void
) => {
    useEffect(() => {
        const listener = () => handle(window.innerWidth, window.innerHeight)
        window.addEventListener('resize', throttle(listener, 300))

        return () => {
            window.removeEventListener('resize', listener)
        }
    }, [])
}
