import { apiOps } from '../../api/defs/apiOps'
import { HigherApiClient } from '../../api/client/ApiClient'
import { CreateUnlockTokenCmd } from 'src/api/defs/CreateUnlockTokenCmd.type'
import { UnlockTokenInfo } from '../../api/defs/UnlockTokenInfo.type'
import { m } from 'src/api/model/model'
import { Page } from 'src/model/api/Page'
import { ListReq } from '../../model/api/ListReq'

const ops = apiOps.AdminCommonUnlockToken

// eslint-disable-next-line @typescript-eslint/ban-types
export type UnlockTokenFilter = {}

export class AdminCommonUnlockTokenApi {
    constructor(private api: HigherApiClient) {}

    generateUnlockToken = async (req: CreateUnlockTokenCmd): Promise<string> =>
        this.api.do<string>(ops.generateUnlockToken, { data: req })

    // unlockTokens = async (): Promise<UnlockTokenInfo[]> =>
    //     this.api.do<UnlockTokenInfo[]>(ops.unlockTokens)

    unlockTokens = async (
        req: ListReq<UnlockTokenFilter>
    ): Promise<Page<UnlockTokenInfo>> =>
        this.api.getPage<UnlockTokenInfo, UnlockTokenFilter>(
            ops.unlockTokens,
            m.UnlockTokenInfo,
            req
        )
}
