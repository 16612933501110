export const CompanyDetails = {
  name: 'CompanyDetails',
  attrs: {
    dic: {
      name: 'dic',
      id: 'CompanyDetails.dic',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    ico: {
      name: 'ico',
      id: 'CompanyDetails.ico',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    icoDph: {
      name: 'icoDph',
      id: 'CompanyDetails.icoDph',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'CompanyDetails.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    paysDph: {
      name: 'paysDph',
      id: 'CompanyDetails.paysDph',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
