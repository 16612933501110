export const Features = {
    name: 'Features',
    attrs: {
        barrierFreeEntrance: {
            name: 'barrierFreeEntrance',
            id: 'Features.barrierFreeEntrance',
            type: {
                name: 'boolean'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        depositRequired: {
            name: 'depositRequired',
            id: 'Features.depositRequired',
            type: {
                name: 'boolean'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        firstMonthFreeDiscountRequired: {
            name: 'firstMonthFreeDiscountRequired',
            id: 'Features.firstMonthFreeDiscountRequired',
            type: {
                name: 'boolean'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        heating: {
            name: 'heating',
            id: 'Features.heating',
            type: {
                name: 'boolean'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        nonstopEntry: {
            name: 'nonstopEntry',
            id: 'Features.nonstopEntry',
            type: {
                name: 'boolean'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        parking: {
            name: 'parking',
            id: 'Features.parking',
            type: {
                name: 'boolean'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        smart: {
            name: 'smart',
            id: 'Features.smart',
            type: {
                name: 'boolean'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        videomonitoring: {
            name: 'videomonitoring',
            id: 'Features.videomonitoring',
            type: {
                name: 'boolean'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        vouchering: {
            name: 'vouchering',
            id: 'Features.vouchering',
            type: {
                name: 'boolean'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        cooperationWithPlusim: {
            name: 'cooperationWithPlusim',
            id: 'Features.cooperationWithPlusim',
            type: {
                name: 'boolean'
            },
            required: true,
            validationRules: [],
            extra: {}
        }
    }
}
