export const RegisterUserCmd = {
  name: 'RegisterUserCmd',
  attrs: {
    password: {
      name: 'password',
      id: 'RegisterUserCmd.password',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    referralToken: {
      name: 'referralToken',
      id: 'RegisterUserCmd.referralToken',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    subscribeToNewsletter: {
      name: 'subscribeToNewsletter',
      id: 'RegisterUserCmd.subscribeToNewsletter',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    user: {
      name: 'user',
      id: 'RegisterUserCmd.user',
      type: {
        name: 'object',
        of: 'WriteUserCmd'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
