export const VatPrice = {
  name: 'VatPrice',
  attrs: {
    price: {
      name: 'price',
      id: 'VatPrice.price',
      type: {
        name: 'object',
        of: 'Money'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    priceWithVat: {
      name: 'priceWithVat',
      id: 'VatPrice.priceWithVat',
      type: {
        name: 'object',
        of: 'Money'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    vat: {
      name: 'vat',
      id: 'VatPrice.vat',
      type: {
        name: 'object',
        of: 'Money'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
