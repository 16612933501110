import React, { ComponentType, ReactNode, useState } from 'react'
import { UiForm, UiFormProps } from 'src/form/ui/UiForm'
import { View } from 'src/ui/basic/View'
import { css } from 'src/ui/style/css'
import { SubmitSuccess, SubmitSuccessProps } from 'src/app/SubmitSuccess'
import { FormSubmissionPanel } from 'src/form/ui/FormSubmissionPanel'
import { verticalChildSpacing } from 'src/ui/style/mixins/layout'
import { columnMaxWidth } from 'src/form/ui/mixins'
import { useTheme } from 'src/ui/style/theme/useTheme'

export type SimpleFormProps<Data> = UiFormProps<Data> & {
    success?: SubmitSuccessProps
    submitText: string
    submitPanelContent?: ReactNode
}

export const SimpleForm: ComponentType<SimpleFormProps<any>> = ({
    children,
    onSubmit,
    success: successProps,
    submitText,
    submitPanelContent,
    ...rest
}) => {
    const th = useTheme()
    const [isSuccess, setSuccess] = useState(false)
    return isSuccess && successProps ? (
        // Has to be outside of UiForm, because of subscription error when success is rendered
        <SubmitSuccess {...successProps} />
    ) : (
        <UiForm
            {...rest}
            onSubmit={async data => {
                await onSubmit(data)
                successProps && setSuccess(true)
            }}
        >
            <View
                css={[
                    columnMaxWidth()(th),
                    css`
                        align-items: center;
                    `
                ]}
            >
                {children}
                <View
                    css={[
                        verticalChildSpacing(th.spacing.md),
                        css`
                            align-items: center;
                        `
                    ]}
                >
                    <FormSubmissionPanel>{submitText}</FormSubmissionPanel>
                    {submitPanelContent}
                </View>
            </View>
        </UiForm>
    )
}
