import { commonPrefix } from 'src/multirole/commonPrefix'

export const ContractPages = {
    ContractDetail: {
        path: `${commonPrefix}/contract/:id`,
        comp: () => import('src/business/Contract/ContractDetailPage'),
        to: (id: string): string => `${commonPrefix}/contract/${id}`
    },
    UploadDocument: {
        path: '/client-user/:id/upload-document',
        comp: () => import('src/business/Contract/UploadDocumentPage'),
        to: (id: string): string => `/client-user/${id}/upload-document`
    }
}
