export enum UserRole {
    Admin = 'Admin',
    Superadmin = 'Superadmin',
    OwnerReadOnly = 'OwnerReadOnly',
    OwnerReadWrite = 'OwnerReadWrite'
}
export type User = {
    username: string
    token: string
    superadmin: boolean
    owner: boolean
    admin: boolean
    role: UserRole
}
