import React, { ComponentType, ReactNode } from 'react'
import { Button } from 'src/ui/basic/Button'
import { Loader } from 'src/ui/basic/Loader'
import { Error } from 'src/form/ui/Error'
import { View } from 'src/ui/basic/View'
import { useFormSubmitState } from '@ps-aux/react-app-core'
import { css } from 'src/ui/style/css'
import { useErrorTran } from 'src/validation/useErrorTran'
import { AppError } from 'src/error/AppError'

export const FormSubmissionPanel: ComponentType<{ children: ReactNode }> = ({
    children
}) => {
    const { submitting, error, valid } = useFormSubmitState()

    const tranError = useErrorTran()

    return (
        <View
            css={css`
                align-items: inherit;
            `}
        >
            <View
                css={css`
                    // For preventing of height of content to jump, since Loader is smaller than button
                    height: 5em;
                `}
            >
                {submitting ? (
                    <Loader pending small />
                ) : (
                    <Button
                        enabled={valid}
                        onClick={() => null}
                        submittable={true}
                    >
                        {children}
                    </Button>
                )}
            </View>

            {error && (
                <View
                    horizontal
                    css={th =>
                        css`
                            margin-top: ${th.spacing.sm};
                        `
                    }
                >
                    {/* Assume error is AppError if not  error translation layer will take care of it */}
                    <Error>{tranError(error as AppError)}</Error>
                </View>
            )}
        </View>
    )
}
