import { FluentModel, FluentModelProp } from '@ps-aux/api-model-extensions'
import { EnumDef } from 'src/model/enum/EnumDef'

export class ModelLabelProvider {
    // @ts-ignore
    entityLabel = (p: FluentModel): string => {
        return this.entityNameLabel(p._meta.name)
    }

    // @ts-ignore
    entityPluralLabel = (p: FluentModel): string => {
        const name = p._meta.name
        return name + '.entity.plural'
    }

    entityNameLabel = (name: string): string => {
        return name + '.entity.name'
    }

    // @ts-ignore
    propLabel = (p: FluentModelProp): string => {
        // TODO cache
        const id = p.attr.id

        const split = id.split('.')

        return [split[0], 'entity.attr', split[1]].join('.')
    }

    // @ts-ignore
    rootEntityPropLabel = (p: FluentModelProp): string => {
        return p.model._meta.name + '.entity.attr.' + p.paths.str
    }

    enumValueLabel = (enm: EnumDef, value: string): string =>
        `enum.${enm.id}.${value}`
}
