import { Page, PageRequest } from 'src/model/api/Page'

export type ApiPageRequest = {
    page: number
    size: number
}

export type ApiPageResponse<T> = {
    content: T[]
    first: boolean
    last: boolean
    totalPages: number
}

export const pageReq = (p: PageRequest): ApiPageRequest => ({
    page: p.number - 1,
    size: p.size
})

export const fromPageRes = <T>(p: ApiPageResponse<T>): Page<T> => {
    if (!p.content)
        throw new Error(
            `Response ${JSON.stringify(p)} does not appear to be a page`
        )
    return {
        items: p.content,
        hasNext: !p.last,
        hasPrevious: !p.first,
        pageCount: p.totalPages
    }
}
