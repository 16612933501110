export const BoxItem = {
  name: 'BoxItem',
  attrs: {
    active: {
      name: 'active',
      id: 'BoxItem.active',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    dimensions: {
      name: 'dimensions',
      id: 'BoxItem.dimensions',
      type: {
        name: 'object',
        of: 'BoxDimensions'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    free: {
      name: 'free',
      id: 'BoxItem.free',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'BoxItem.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'BoxItem.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    price: {
      name: 'price',
      id: 'BoxItem.price',
      type: {
        name: 'object',
        of: 'BoxConsumerPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    visible: {
      name: 'visible',
      id: 'BoxItem.visible',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    waitingForReadinessCheck: {
      name: 'waitingForReadinessCheck',
      id: 'BoxItem.waitingForReadinessCheck',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
