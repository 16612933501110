export const BoxV2Lock = {
  name: 'BoxV2Lock',
  attrs: {
    enabled: {
      name: 'enabled',
      id: 'BoxV2Lock.enabled',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    facilityId: {
      name: 'facilityId',
      id: 'BoxV2Lock.facilityId',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'BoxV2Lock.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
