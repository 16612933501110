export const ClientUserPages = {
    Detail: {
        path: '/client-user/:id',
        comp: () => import('src/business/ClientUser/ClientUserDetailPage'),
        to: (id: string): string => `/client-user/${id}`
    },
    DebitReferral: {
        path: '/client-user/:id/referral/debit',
        comp: () => import('src/business/ClientUser/DebitReferralPage'),
        to: (id: string): string => `/client-user/${id}/referral/debit`
    }
}
