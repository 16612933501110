export const JobRun = {
  name: 'JobRun',
  attrs: {
    changes: {
      name: 'changes',
      id: 'JobRun.changes',
      type: {
        name: 'list',
        of: {
          name: 'string'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    data: {
      name: 'data',
      id: 'JobRun.data',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    error: {
      name: 'error',
      id: 'JobRun.error',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    finish: {
      name: 'finish',
      id: 'JobRun.finish',
      type: {
        name: 'date'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'JobRun.id',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'JobRun.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    runBy: {
      name: 'runBy',
      id: 'JobRun.runBy',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    start: {
      name: 'start',
      id: 'JobRun.start',
      type: {
        name: 'date'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    status: {
      name: 'status',
      id: 'JobRun.status',
      type: {
        name: 'enum',
        id: 'JobRun$Status',
        of: ['IN_PROGRESS', 'FINISHED', 'FAILED']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'JobRun$Status'
      }
    },
    trigger: {
      name: 'trigger',
      id: 'JobRun.trigger',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
