export const ClientWarehouseWithPrices = {
  name: 'ClientWarehouseWithPrices',
  attrs: {
    accessibility: {
      name: 'accessibility',
      id: 'ClientWarehouseWithPrices.accessibility',
      type: {
        name: 'object',
        of: 'Accessibility'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    address: {
      name: 'address',
      id: 'ClientWarehouseWithPrices.address',
      type: {
        name: 'object',
        of: 'Address'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    code: {
      name: 'code',
      id: 'ClientWarehouseWithPrices.code',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    deactivation: {
      name: 'deactivation',
      id: 'ClientWarehouseWithPrices.deactivation',
      type: {
        name: 'object',
        of: 'DeactivationInfo'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    discounts: {
      name: 'discounts',
      id: 'ClientWarehouseWithPrices.discounts',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'WarehouseDiscount'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    features: {
      name: 'features',
      id: 'ClientWarehouseWithPrices.features',
      type: {
        name: 'object',
        of: 'Features'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'ClientWarehouseWithPrices.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    images: {
      name: 'images',
      id: 'ClientWarehouseWithPrices.images',
      type: {
        name: 'list',
        of: {
          name: 'string'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'ClientWarehouseWithPrices.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    prices: {
      name: 'prices',
      id: 'ClientWarehouseWithPrices.prices',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'WarehouseBoxPrice'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    promoWithBoxes: {
      name: 'promoWithBoxes',
      id: 'ClientWarehouseWithPrices.promoWithBoxes',
      type: {
        name: 'object',
        of: 'ClientWarehousePromoDiscount'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
