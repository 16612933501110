export const BoxGeometryInput = {
  name: 'BoxGeometryInput',
  attrs: {
    data: {
      name: 'data',
      id: 'BoxGeometryInput.data',
      type: {
        name: 'object',
        of: 'BoxGeometry'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'BoxGeometryInput.id',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
