import React, { ComponentType } from 'react'
import { css } from 'src/ui/style/css'
import BeatLoader from 'react-spinners/BeatLoader'
import { useTheme } from 'src/ui/style/theme/useTheme'

export type LoaderProps = {
    pending?: boolean
    small?: boolean
}

export const Loader: ComponentType<LoaderProps> = ({
    pending = true,
    small = false
}) => {
    const th = useTheme()
    const color = th.colors.gray
    const size = small ? 15 : 30

    return pending ? (
        <div
            css={css`
                display: flex;
                justify-content: center;
            `}
        >
            <BeatLoader loading={pending} color={color} size={size} />
        </div>
    ) : null
}
