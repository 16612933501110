import { FluentModel, FluentModelProp } from '@ps-aux/api-model-extensions'
import { ModelLabelProvider } from 'src/model/lib/label/ModelLabelProvider'
import { EnumDef } from 'src/model/enum/EnumDef'

// @ts-ignore
// Cannot  use FluentModel<any> https://www.youtrack.software/issue/BOXY-647
export type RawFluentModel = FluentModel

// @ts-ignore
export type RawProp = FluentModelProp

const findProp = <T>(
    strPath: string,
    m: FluentModel<T>
): FluentModelProp<T, any> | null => {
    const p = m._meta.props.find(p => p.paths.str === strPath)

    return p || null
}

export type AliasMappings = {
    entityProps: Map<string, FluentModel<any>>
    props: Map<RawProp, RawProp>
    propsToEntity: Map<FluentModelProp<any, any>, FluentModel<any>>
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
const dbg = (cond: boolean | (() => boolean), ...args: any) => {
    const holds = typeof cond === 'function' ? cond() : cond
    if (!holds) return
    console.log('[debugging translator]', ...args)
}

export class ModelTranslator {
    private labelProvider: ModelLabelProvider
    private readonly t: (key: string) => string | null
    private readonly debug = true

    constructor(
        translate: (key: string) => string,
        private mappings: AliasMappings
    ) {
        this.labelProvider = new ModelLabelProvider()

        this.t = (key: string) => {
            const res = translate(key)
            // Not translated
            if (res === key) return null

            return res
        }
    }

    private propFromPropAlias = (p: RawProp): string | null => {
        const mapped = this.mappings.props.get(p)

        if (mapped) return this.translateProp(mapped)
        return null
    }

    private propFromEntityPropsAlias = (p: RawProp): string | null => {
        const mappedModel = this.mappings.entityProps.get(p.model._meta.name)

        if (!mappedModel) return null

        const mappedP = findProp(p.paths.str, mappedModel)

        if (!mappedP) return null

        let res = this.translateProp(mappedP)
        if (res) return res

        res = this.propFromPropAlias(res)
        if (res) return res
        return null
    }

    private propFromEntityAlias = (p: RawProp): string | null => {
        const mapped = this.mappings.propsToEntity.get(p)

        if (mapped) return this.entity(mapped)

        return null
    }

    private translateProp = (p: RawProp): string | null => {
        // First try full name from the root entity
        const r = this.t(this.labelProvider.rootEntityPropLabel(p))

        if (r) return r
        return this.t(this.labelProvider.propLabel(p))
    }

    private or = (first: string | null, alternative: string): string => {
        if (first) return first

        return alternative
    }

    prop = (p: RawProp): string => {
        let r = this.translateProp(p)
        if (r) return r
        r = this.propFromPropAlias(p)
        if (r) return r
        r = this.propFromEntityPropsAlias(p)
        if (r) return r
        r = this.propFromEntityAlias(p)
        if (r) return r
        r = this.propEntityName(p)

        return this.or(r, p.model._meta.name + '|' + p.paths.str)
    }

    private propEntityName = (p: RawProp): string | null => {
        if (!p.composite)
            // Not an object
            return null
        // It is an object - try to translate the entity name
        const entityName = p.attr.type.of
        return this.t(this.labelProvider.entityNameLabel(entityName))
    }

    entity = (m: RawFluentModel): string => {
        const r = this.t(this.labelProvider.entityLabel(m))
        return this.or(r, m._meta.name)
    }

    entityPlural = (m: RawFluentModel): string => {
        const r = this.t(this.labelProvider.entityPluralLabel(m))
        return this.or(r, m._meta.name + '[plural]')
    }

    enum = (def: EnumDef, value: string): string => {
        const r = this.t(this.labelProvider.enumValueLabel(def, value))
        return this.or(r, value)
    }
}
