import { FluentModel, FluentModelProp } from '@ps-aux/api-model-extensions'
import { InputComponent, InputComponentProps } from 'src/ui/basic/input/types'
import React, { ComponentType, ReactElement, useContext, useState } from 'react'
import { View } from 'src/ui/basic/View'

import { FormErrors } from '@ps-aux/react-app-core'
import { FieldUi } from 'src/form/ui/FieldUi'
import { ValidationFunction } from 'src/validation/types'
import { assocPath } from 'ramda'
import { useModelRendering } from 'src/model/ModelRenderingProvider'
import { useValidate } from 'src/validation/useValidate'

export type EntityInputProps<T> = InputComponentProps<T> & {
    model: FluentModel<T>
    validate?: ValidationFunction
}

// Copied from WEB
type ValueCtxType<T> = {
    onChange: (v: T) => void
    value: T
    errors: FormErrors | null
    onFocus?: () => void
    onBlur?: () => void
}

// Copied from WEB
// @ts-ignore
const ValueCtx = React.createContext<ValueCtxType>(null)

// Copied from WEB
const MyField: ComponentType<{
    // @ts-ignore
    prop: FluentModelProp
    label: string
    required?: boolean
    comp: InputComponent
}> = ({ prop, label, required, comp: Comp }) => {
    const { value, onChange, errors, onFocus, onBlur } = useContext(ValueCtx)
    const errs = errors ? prop.get(errors, false) : undefined
    const val = prop.get(value, false)
    return (
        <FieldUi required={required} label={label} errors={errs}>
            <Comp
                input={{
                    // `val` could be undefined, which leads to uncontrolled component
                    value: val === null || val === undefined ? null : val,
                    onChange: val => {
                        if (val === '') val = null
                        onChange(assocPath(prop.path, val, value))
                    }
                }}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </FieldUi>
    )
}

export function EntityInput<T>({
    input,
    model,
    onFocus,
    onBlur,
    validate,
    onChange
}: EntityInputProps<T>): ReactElement {
    const [errors, setErrors] = useState<FormErrors | null>(null)
    const { resolveRenderConfig, translator } = useModelRendering()

    const props = model._meta.props as FluentModelProp<T, any>[]

    const runValidation = useValidate(validate!)

    return (
        <View>
            {/* Based on web code */}
            <ValueCtx.Provider
                value={{
                    value: input.value,
                    onChange: (data: T) => {
                        input.onChange(data)
                        validate && setErrors(runValidation(data))
                        if (onChange) {
                            onChange(data)
                        }
                    },
                    errors,
                    onFocus,
                    onBlur
                }}
            >
                {props.map(p => {
                    const attr = p.attr
                    const { editor } = resolveRenderConfig(attr)
                    return (
                        <MyField
                            key={p.attr.id}
                            prop={p}
                            comp={editor}
                            label={translator.prop(p)}
                        />
                    )
                })}
            </ValueCtx.Provider>
        </View>
    )
}
