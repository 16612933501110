export const Kpi = {
  name: 'Kpi',
  attrs: {
    contractStatistics: {
      name: 'contractStatistics',
      id: 'Kpi.contractStatistics',
      type: {
        name: 'object',
        of: 'KpiStatistikyZmluv'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    normalizedTrzba: {
      name: 'normalizedTrzba',
      id: 'Kpi.normalizedTrzba',
      type: {
        name: 'object',
        of: 'Money'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    obsadenost: {
      name: 'obsadenost',
      id: 'Kpi.obsadenost',
      type: {
        name: 'object',
        of: 'KpiObsadenost'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    platbyWithVat: {
      name: 'platbyWithVat',
      id: 'Kpi.platbyWithVat',
      type: {
        name: 'object',
        of: 'Money'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    platbyWithoutVat: {
      name: 'platbyWithoutVat',
      id: 'Kpi.platbyWithoutVat',
      type: {
        name: 'object',
        of: 'Money'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
