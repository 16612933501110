import { formatLocale } from 'src/i18n/formatSettings'
import { LanguageFormat } from 'src/types/LanguageFormat'

export const strNumber = (v: number, format?: LanguageFormat): string => {
    const _NumFormat = new Intl.NumberFormat(format ?? formatLocale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })

    return _NumFormat.format(v)
}
