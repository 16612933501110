import { ERROR_EN, ErrorKeys } from './texts/error_en'
import { ERROR_SK } from './texts/error_sk'
import { Language } from '../api/defs/enums.type'

export const translate = (key: ErrorKeys): string => {
    const lang = localStorage.getItem('user.lang')?.includes('SK') ? 'SK' : 'EN'

    if (lang === Language.SK) {
        const { messages } = ERROR_SK
        return (
            (messages as Record<ErrorKeys, string>)?.[key] ||
            `Translation not found for key: ${key}`
        )
    } else {
        const { messages } = ERROR_EN
        return (
            (messages as Record<ErrorKeys, string>)?.[key] ||
            `Translation not found for key: ${key}`
        )
    }
}

export const getErrorMessage = (errorObject: any): string => {
    // Safely navigate to the detail.codes array
    if (errorObject?.detail?.[0]?.codes) {
        // Look for the most specific code (the first one in the list)
        const specificCode = errorObject.detail[0].codes[0]

        // Attempt to translate the specific code
        const translation = translate(specificCode as ErrorKeys)

        // If no translation is found, return the defaultMessage or a fallback
        return translation !== `Translation not found for key: ${specificCode}`
            ? translation
            : errorObject.detail[0].defaultMessage || 'Unknown error occurred'
    }

    // Fallback if the structure doesn't match
    return 'NO_ERROR_MESSAGE_FOUND'
}
