export const BoxInsuranceOption = {
  name: 'BoxInsuranceOption',
  attrs: {
    coverage: {
      name: 'coverage',
      id: 'BoxInsuranceOption.coverage',
      type: {
        name: 'object',
        of: 'Money'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'BoxInsuranceOption.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    monthlyPrice: {
      name: 'monthlyPrice',
      id: 'BoxInsuranceOption.monthlyPrice',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
