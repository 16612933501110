// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"color-primary": "#9ddbf4",
	"color-secondary": "#f3d03e",
	"color-font-primary": "#434342",
	"borderRadius-primary": "0.25rem",
	"inputHeight": "calc(2.25rem + 2px)",
	"inputPadding": "0.5rem",
	"inputFocusBorderColor": "#9ddbf4",
	"inputFocusShadow": "0 0 0 3px rgba(157, 219, 244, 0.25)",
	"inputBorderWidth": "1px",
	"inputBorderColor": "#ced4da",
	"inputBorder": "1px solid #9ddbf4",
	"inputFontSize": "1em"
};
export default ___CSS_LOADER_EXPORT___;
