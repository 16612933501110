import React from 'react'
import { render } from 'react-dom'
import Root from 'src/Root'
import { createAppContext } from 'src/context/AppContext'
import { getEnvConfVal } from 'src/conf/getEnvConfVal'
import { AppConfig } from './context/AppConfig'

const formatLocale = getEnvConfVal('FORMAT_LOCALE')
const appVersion = getEnvConfVal('APP_VERSION')

const cfg: AppConfig = {
    formatLocale: formatLocale,
    appVersion: appVersion
}

const ctx = createAppContext(cfg, window)

const element = document.getElementById('root')!
render(<Root ctx={ctx} rootElement={element} />, element)
