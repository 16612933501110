import React, { ComponentType, ReactNode } from 'react'
import { CacheProvider, Global, ThemeProvider } from '@emotion/react'
import { appTheme } from 'src/ui/style/theme/appTheme'
import { createGlobalCss } from 'src/ui/style/globalCss'
import createCache from '@emotion/cache'
import 'src/ui/style/bootstrap/index.scss'
import { createCountrySelectorStyle } from 'src/ui/style/countrySelectorStyle'

export type StylingProviderProps = {
    children: ReactNode
}

export const StylingProvider: ComponentType<StylingProviderProps> = ({
    children
}) => {
    const theme = appTheme()
    const globalCss = createGlobalCss(theme)
    const countrySelectorStyle = createCountrySelectorStyle(theme)
    const myCache = createCache({
        key: 'my-emotion-cache'
    })
    myCache.compat = true

    return (
        <CacheProvider value={myCache}>
            <Global styles={globalCss} />
            <Global styles={countrySelectorStyle} />
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </CacheProvider>
    )
}
