import React, { ComponentType } from 'react'
import { View } from 'src/ui/basic/View'
import { Text } from 'src/ui/basic/Text'
import { css } from 'src/ui/style/css'
import { DesktopNavigation } from 'src/layout/header/navigation/DesktopNavigation'
import { isMobileScreen } from 'src/ui/style/ScreenSize'
import { MobileNavigation } from 'src/layout/header/navigation/MobileNavigation'
import { Link } from 'react-router-dom'
import { useRouter } from '@ps-aux/react-app-core'
import { Logo } from 'src/brand/Logo'
import { useTheme } from 'src/ui/style/theme/useTheme'
import { Version } from 'src/version/VersionInfo'
import { useNavigationItems } from 'src/layout/header/navigation/navigationItems'

const LogoComp = () => {
    const th = useTheme()
    return (
        <View
            horizontal
            css={css`
                height: 100%;
                align-items: stretch;
            `}
        >
            <Link
                to={'/'}
                css={css`
                    height: 100%;
                `}
            >
                <Logo />
            </Link>
            <View
                css={css`
                    margin-left: ${th.spacing.md};
                    color: ${th.fontColor.primary};
                    height: 100%;
                `}
            >
                <Text
                    css={css`
                        font-weight: ${th.fontWeight.bold};
                        font-size: ${th.fontSize.md};
                    `}
                >
                    Admin
                </Text>
                <Version />
            </View>
        </View>
    )
}

export type HeaderProps = any

export type NavItem = [string, string]

export const Header: ComponentType<HeaderProps> = () => {
    const isMobile = isMobileScreen()

    const items = useNavigationItems()
    const r = useRouter()
    const navItems: NavItem[] = items.map(([label, page]) => [
        label,
        r.linkTo(page)
    ])

    return (
        <View
            horizontal
            css={th => css`
                padding: ${th.spacing.md};
                position: sticky;
                top: 0;
                background: ${th.colors.white};
                justify-content: space-between;
                z-index: 100;
                height: 5em;
                box-shadow: ${th.boxShadow.bottomOnly};
            `}
        >
            <LogoComp />
            {isMobile ? (
                <MobileNavigation navItems={navItems} />
            ) : (
                <DesktopNavigation navItems={navItems} />
            )}
        </View>
    )
}
