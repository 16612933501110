export const MyRent = {
  name: 'MyRent',
  attrs: {
    amount: {
      name: 'amount',
      id: 'MyRent.amount',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    boxId: {
      name: 'boxId',
      id: 'MyRent.boxId',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    boxName: {
      name: 'boxName',
      id: 'MyRent.boxName',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    contractId: {
      name: 'contractId',
      id: 'MyRent.contractId',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    contractTechnicalId: {
      name: 'contractTechnicalId',
      id: 'MyRent.contractTechnicalId',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    gatewayId: {
      name: 'gatewayId',
      id: 'MyRent.gatewayId',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    gatewayPaymentClientId: {
      name: 'gatewayPaymentClientId',
      id: 'MyRent.gatewayPaymentClientId',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    gatewayType: {
      name: 'gatewayType',
      id: 'MyRent.gatewayType',
      type: {
        name: 'enum',
        id: 'Payment$GatewayType',
        of: ['STRIPE', 'GOPAY', 'MOCK']
      },
      required: false,
      validationRules: [],
      extra: {
        enumId: 'Payment$GatewayType'
      }
    },
    id: {
      name: 'id',
      id: 'MyRent.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    interval: {
      name: 'interval',
      id: 'MyRent.interval',
      type: {
        name: 'object',
        of: 'DayInterval'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    issuedOn: {
      name: 'issuedOn',
      id: 'MyRent.issuedOn',
      type: {
        name: 'object',
        of: 'Day'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    paidAt: {
      name: 'paidAt',
      id: 'MyRent.paidAt',
      type: {
        name: 'date'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    payManually: {
      name: 'payManually',
      id: 'MyRent.payManually',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    status: {
      name: 'status',
      id: 'MyRent.status',
      type: {
        name: 'enum',
        id: 'RentStatus',
        of: ['CREATED', 'INVOICE_SUBMITTED', 'PAID']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'RentStatus'
      }
    }
  }
};
