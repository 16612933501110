export const BrowserErrorContext = {
  name: 'BrowserErrorContext',
  attrs: {
    browser: {
      name: 'browser',
      id: 'BrowserErrorContext.browser',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    localStorage: {
      name: 'localStorage',
      id: 'BrowserErrorContext.localStorage',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    location: {
      name: 'location',
      id: 'BrowserErrorContext.location',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    screenSize: {
      name: 'screenSize',
      id: 'BrowserErrorContext.screenSize',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    sessionId: {
      name: 'sessionId',
      id: 'BrowserErrorContext.sessionId',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    username: {
      name: 'username',
      id: 'BrowserErrorContext.username',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
