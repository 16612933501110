export const ClientReferralInfo = {
  name: 'ClientReferralInfo',
  attrs: {
    currentState: {
      name: 'currentState',
      id: 'ClientReferralInfo.currentState',
      type: {
        name: 'object',
        of: 'ReferralRewardAccountState'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    referralCount: {
      name: 'referralCount',
      id: 'ClientReferralInfo.referralCount',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    token: {
      name: 'token',
      id: 'ClientReferralInfo.token',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    url: {
      name: 'url',
      id: 'ClientReferralInfo.url',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
