import { apiOps } from 'src/api/defs/apiOps'
import { HigherApiClient } from 'src/api/client/ApiClient'
import { ListReq, NoListFilter } from 'src/model/api/ListReq'
import { Page } from 'src/model/api/Page'
import { AdminContractListItem } from 'src/api/defs/AdminContractListItem.type'
import { ClientUser } from 'src/api/defs/ClientUser.type'
import { m } from 'src/api/model/model'
import { DebitCmd } from 'src/api/defs/DebitCmd.type'
import { ClientReferralInfo } from 'src/api/defs/ClientReferralInfo.type'
import { AdminReferralRewardTransaction } from 'src/api/defs/AdminReferralRewardTransaction.type'

export class ClientUserApi {
    constructor(private api: HigherApiClient) {}

    detail = (id: string): Promise<ClientUser> =>
        this.api.doForId<ClientUser>(
            apiOps.AdminClientUser.readDetail,
            m.ClientUser,
            id
        )

    edit = (id: string, data: ClientUser): Promise<ClientUser> =>
        this.api.doForId(apiOps.AdminClientUser.edit, null, id, data)

    contracts = (
        id: string,
        req: ListReq<NoListFilter>
    ): Promise<Page<AdminContractListItem>> =>
        this.api.getPage<AdminContractListItem, any>(
            apiOps.AdminClientUser.contracts,
            m.AdminContractListItem,
            req,
            {
                id
            }
        )

    referralDebit = (id: string, data: DebitCmd): Promise<void> =>
        this.api.doForId(
            apiOps.AdminClientUser.debitFromReferralRewardAccount,
            null,
            id,
            data
        )

    referralTransactions = (
        id: string,
        req: ListReq<NoListFilter>
    ): Promise<Page<AdminReferralRewardTransaction>> =>
        this.api.getPage<AdminReferralRewardTransaction, any>(
            apiOps.AdminClientUser.referralTransactions,
            m.AdminReferralRewardTransaction,
            req,
            {
                id
            }
        )

    referralInfo = (id: string): Promise<ClientReferralInfo> =>
        this.api.doForId(apiOps.AdminClientUser.clientReferralInfo, null, id)
}
