export const BoxWithInUseInfo = {
  name: 'BoxWithInUseInfo',
  attrs: {
    box: {
      name: 'box',
      id: 'BoxWithInUseInfo.box',
      type: {
        name: 'object',
        of: 'Box'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'BoxWithInUseInfo.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    inUseInfo: {
      name: 'inUseInfo',
      id: 'BoxWithInUseInfo.inUseInfo',
      type: {
        name: 'object',
        of: 'BoxInUseInfo'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
