export const WarehouseLock = {
  name: 'WarehouseLock',
  attrs: {
    v1: {
      name: 'v1',
      id: 'WarehouseLock.v1',
      type: {
        name: 'object',
        of: 'WarehouseV1Lock'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    v2: {
      name: 'v2',
      id: 'WarehouseLock.v2',
      type: {
        name: 'object',
        of: 'WarehouseV2Lock'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    v2FacilityId: {
      name: 'v2FacilityId',
      id: 'WarehouseLock.v2FacilityId',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    version: {
      name: 'version',
      id: 'WarehouseLock.version',
      type: {
        name: 'enum',
        id: 'LockVersion',
        of: ['V1', 'V2']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'LockVersion'
      }
    }
  }
};
