export const ContractInvoicingType = {
  name: 'ContractInvoicingType',
  attrs: {
    id: {
      name: 'id',
      id: 'ContractInvoicingType.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    periodInMonths: {
      name: 'periodInMonths',
      id: 'ContractInvoicingType.periodInMonths',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
