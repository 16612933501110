export const AppUser = {
  name: 'AppUser',
  attrs: {
    active: {
      name: 'active',
      id: 'AppUser.active',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    email: {
      name: 'email',
      id: 'AppUser.email',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'AppUser.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'AppUser.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    type: {
      name: 'type',
      id: 'AppUser.type',
      type: {
        name: 'enum',
        id: 'AppUser$Type',
        of: ['ClientUser', 'OwnerUser', 'AdminUser']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'AppUser$Type'
      }
    }
  }
};
