export const KpiPerInterval = {
  name: 'KpiPerInterval',
  attrs: {
    data: {
      name: 'data',
      id: 'KpiPerInterval.data',
      type: {
        name: 'object',
        of: 'Kpi'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    per: {
      name: 'per',
      id: 'KpiPerInterval.per',
      type: {
        name: 'object',
        of: 'DayInterval'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
