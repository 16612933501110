export const Accessibility = {
  name: 'Accessibility',
  attrs: {
    bus: {
      name: 'bus',
      id: 'Accessibility.bus',
      type: {
        name: 'object',
        of: 'PublicTransportAccessibility'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    car: {
      name: 'car',
      id: 'Accessibility.car',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    metro: {
      name: 'metro',
      id: 'Accessibility.metro',
      type: {
        name: 'object',
        of: 'PublicTransportAccessibility'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    tram: {
      name: 'tram',
      id: 'Accessibility.tram',
      type: {
        name: 'object',
        of: 'PublicTransportAccessibility'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    trolley: {
      name: 'trolley',
      id: 'Accessibility.trolley',
      type: {
        name: 'object',
        of: 'PublicTransportAccessibility'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
