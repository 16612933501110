import { css } from 'src/ui/style/css'
import { Theme } from 'src/ui/style/theme/Theme'

export const iconStyle =
    (clickable = false) =>
    (th: Theme) =>
        css`
            color: ${th.colors.primary};
            font-size: ${th.iconToTextSizeRatio};
            cursor: ${clickable ? 'pointer' : 'inherit'};
        `

export const contactViewStyle = () => (th: Theme) =>
    css`
        border: 1px solid ${th.colors.lightGray};
        border-radius: ${th.borderRadius.primary};
        padding: ${th.spacing.sm};
    `
