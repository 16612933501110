import { HigherApiClient } from 'src/api/client/ApiClient'
import {
    AppRouter,
    createAppContextApi,
    StorageDataCompatibilityGuard,
    createPersistentStore,
    Store
} from '@ps-aux/react-app-core'
import Axios from 'axios'
import { userContext, UserContext } from 'src/auth/userContext'
import { pageList } from 'src/routing/pages'
import { extractHttpError, extractValidationError } from 'src/http/HttpError'
import { MultiRoleWarehouseApi } from '../business/Warehouse/MultiRoleWarehouseApi'
import { AppUserApi } from 'src/business/user/AppUser/AppUserApi'
import { ClientUserApi } from 'src/business/ClientUser/ClientUserApi'
import { VersionProvider } from 'src/version/VersionProvider'
import { ContractApi } from 'src/business/Contract/ContractApi'
import { JobsApi } from 'src/superadmin/jobs/JobsApi'
import { OrdersApi } from 'src/superadmin/orders/OrdersApi'
import { RentApi } from 'src/business/Rent/RentApi'
import { DocumentsApi } from 'src/superadmin/Documents/DocumentsApi'
import { BoxApi } from 'src/business/Box/BoxApi'
import { OwnerApi } from 'src/business/Owner/OwnerApi'
import { SuperadminRentApi } from 'src/superadmin/Rent/SuperadminRentApi'
import { WarehouseMultiRoleReadApi } from 'src/business/Warehouse/WarehouseMultiRoleReadApi'
import { isOwner } from 'src/multirole/isOwner'
import { DashboardApi } from 'src/business/Dashboard/DashboardApi'
import { ExportApi } from 'src/business/Export/ExportApi'
import { BrowserErrorsApi } from 'src/superadmin/BrowserErrors/BrowserErrorsApi'
import { FranchiseContactApi } from 'src/business/FranchiseContact/FranchiseContactApi'
import { AppConfig } from './AppConfig'
import { Language } from 'src/api/defs/enums.type'
import { LanguageFormat } from '../types/LanguageFormat'
import { AdminCommonLockApi } from '../business/lock/AdminCommonLockApi'
import { RysAuditApi } from '../business/RysAudit/RysAuditApi'
import { AdminCommonUnlockTokenApi } from '../business/lock/AdminCommonUnlockTokenApi'
import { NotificationAuditApi } from '../superadmin/NotificationAudit/NotificationAuditApi'

export type AppContext = {
    config: AppConfig
    router: AppRouter
    api: HigherApiClient
    user: UserContext
    warehouse: {
        api: {
            admin: MultiRoleWarehouseApi
            read: WarehouseMultiRoleReadApi
        }
    }
    box: {
        api: BoxApi
    }
    rent: {
        api: RentApi
    }
    appUser: {
        api: AppUserApi
    }
    clientUser: {
        api: ClientUserApi
    }
    contract: {
        api: ContractApi
    }
    dashboard: {
        api: DashboardApi
    }
    owner: {
        api: OwnerApi
    }
    export: {
        api: ExportApi
    }
    franchiseContact: {
        api: FranchiseContactApi
    }
    version: VersionProvider
    i18n: {
        language: Store<Language>
        format: Store<LanguageFormat>
    }
    lockV2: {
        api: AdminCommonLockApi
    }
    unlockToken: {
        api: AdminCommonUnlockTokenApi
    }
    superadmin: {
        jobs: {
            api: JobsApi
        }
        browsersErrors: {
            api: BrowserErrorsApi
        }
        orders: {
            api: OrdersApi
        }
        documents: {
            api: DocumentsApi
        }
        rents: {
            api: SuperadminRentApi
        }
        rysAudit: {
            api: RysAuditApi
        }
    }
    notifications: {
        api: NotificationAuditApi
    }
}

// TODO move to the core lib
const scrollToTopOnPathChange = (win: Window, r: AppRouter) => {
    let path = r.getLocation().path

    r.locationChanges().subscribe(l => {
        if (l.path !== path) {
            win.scrollTo(0, 0)
        }
        path = l.path
    })
}

const storageDataSchemaVersion = '1.0'

export const createAppContext = (
    config: AppConfig,
    win: Window
): AppContext => {
    const router = new AppRouter(pageList, {
        onFetchPageError: err => {
            console.log('Page fetch error ' + err.message)
            if (err.name === 'ChunkLoadError') {
                console.error('Is chunk page loading error. Will reload.')
                win.location.reload()
            }
        }
    })

    const storage = win.localStorage

    new StorageDataCompatibilityGuard(storage).ensure(storageDataSchemaVersion)

    scrollToTopOnPathChange(win, router)

    const http = Axios.create()

    const api = new HigherApiClient(http)

    const user = userContext(storage, () => {
        const http = Axios.create()

        http.interceptors.response.use(
            x => x,
            err => {
                return Promise.reject(extractHttpError(err))
            }
        )

        return http
    })

    http.interceptors.request.use(user.httpRequestInterceptor)
    http.interceptors.response.use(
        x => x,
        err => {
            if (
                Object.keys(err.response?.data).includes('code') ||
                Object.keys(err.response?.data).includes('detail')
            ) {
                user.handleHttpError(err.response)
                return Promise.reject(extractValidationError(err))
            }
            user.handleHttpError(extractHttpError(err))
            return Promise.reject(extractHttpError(err))
        }
    )

    const isOwnerFromCtx = () => isOwner(ctx)

    const ctx: AppContext = {
        config,
        router,
        api,
        user,
        warehouse: {
            api: {
                admin: new MultiRoleWarehouseApi(api, isOwnerFromCtx),
                read: new WarehouseMultiRoleReadApi(api, isOwnerFromCtx)
            }
        },
        i18n: {
            language: createPersistentStore(
                storage,
                'user.lang',
                Language.SK as Language
            ),
            format: createPersistentStore(
                storage,
                'user.format',
                LanguageFormat[Language.EN] as LanguageFormat
            )
        },
        box: {
            api: new BoxApi(api, isOwnerFromCtx)
        },
        rent: {
            api: new RentApi(api, isOwnerFromCtx)
        },
        appUser: {
            api: new AppUserApi(api)
        },
        clientUser: {
            api: new ClientUserApi(api)
        },
        contract: {
            api: new ContractApi(api, isOwnerFromCtx)
        },
        dashboard: {
            api: new DashboardApi(api)
        },
        owner: {
            api: new OwnerApi(api)
        },
        export: {
            api: new ExportApi(api)
        },
        franchiseContact: {
            api: new FranchiseContactApi(api)
        },
        lockV2: {
            api: new AdminCommonLockApi(api)
        },
        unlockToken: {
            api: new AdminCommonUnlockTokenApi(api)
        },
        superadmin: {
            browsersErrors: {
                api: new BrowserErrorsApi(api)
            },
            jobs: {
                api: new JobsApi(api)
            },
            orders: {
                api: new OrdersApi(api)
            },
            documents: {
                api: new DocumentsApi(api)
            },
            rents: {
                api: new SuperadminRentApi(api)
            },
            rysAudit: {
                api: new RysAuditApi(api, isOwnerFromCtx)
            }
        },
        notifications: {
            api: new NotificationAuditApi(api)
        },
        version: new VersionProvider(http)
    }

    return ctx
}

export const {
    AppContextProvider,
    getAppContext
} = createAppContextApi<AppContext>()

export const useAppCtx = getAppContext
