export const Point = {
  name: 'Point',
  attrs: {
    x: {
      name: 'x',
      id: 'Point.x',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    y: {
      name: 'y',
      id: 'Point.y',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
