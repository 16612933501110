export const Discount = {
  name: 'Discount',
  attrs: {
    fromInvoicingType: {
      name: 'fromInvoicingType',
      id: 'Discount.fromInvoicingType',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    fromPromo: {
      name: 'fromPromo',
      id: 'Discount.fromPromo',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    promoType: {
      name: 'promoType',
      id: 'Discount.promoType',
      type: {
        name: 'enum',
        id: 'PromoType',
        of: [
          'Easter',
          'Christmas',
          'Snowflake',
          'Gem',
          'Gift',
          'Heart',
          'Leaf',
          'Sun',
          'Umbrella'
        ]
      },
      required: false,
      validationRules: [],
      extra: {
        enumId: 'PromoType'
      }
    },
    total: {
      name: 'total',
      id: 'Discount.total',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
