export const BrowserErrorReport = {
  name: 'BrowserErrorReport',
  attrs: {
    context: {
      name: 'context',
      id: 'BrowserErrorReport.context',
      type: {
        name: 'object',
        of: 'BrowserErrorContext'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    error: {
      name: 'error',
      id: 'BrowserErrorReport.error',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'BrowserErrorReport.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    stacktrace: {
      name: 'stacktrace',
      id: 'BrowserErrorReport.stacktrace',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    time: {
      name: 'time',
      id: 'BrowserErrorReport.time',
      type: {
        name: 'date'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
