import { apiOps } from 'src/api/defs/apiOps'
import { HigherApiClient } from 'src/api/client/ApiClient'
import {
    DashboardFilter,
    toApiDashboardFilter
} from 'src/business/Dashboard/DashboardApi'
import { DownloadLink } from 'src/api/defs/DownloadLink.type'

const ops = apiOps.Export

export class ExportApi {
    constructor(private api: HigherApiClient) {}

    get = (filter: DashboardFilter): Promise<DownloadLink> =>
        this.api.do<DownloadLink>(ops.get, {
            queryParams: toApiDashboardFilter(filter)
        })
}
