import React, { ComponentType } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { PageComponent, useStoreValue } from '@ps-aux/react-app-core'
import { AppLayout } from 'src/layout/AppLayout'
import { getAppContext } from 'src/context/AppContext'
import { LoginPage } from 'src/auth/LoginPage'
import { User } from 'src/auth/User'
import { usePresentUser } from 'src/auth/useUser'
import { superadminContext } from 'src/superadmin/SuperadminPages'
import { commonPrefix } from 'src/multirole/commonPrefix'
import { DashBoardPages } from 'src/business/Dashboard/DashboardPages'

type RouteGuard = (path: string, use: User) => boolean

const MyRoute: ComponentType<{
    comp: ComponentType
    path: string
    guards: RouteGuard[]
    start: string
    // Exact prop MUST be in props here otherwise it won't work with Switch (omg)
    exact: boolean
}> = ({ path, comp: Comp, guards, start, exact }) => {
    const user = usePresentUser()
    return (
        <Route exact={exact} path={path}>
            {r => {
                const location = r.location
                if (path === start) return <Comp />
                for (const g of guards)
                    if (!g(location.pathname, user))
                        return <Redirect to={start} />

                return <Comp />
            }}
        </Route>
    )
}

const guards: RouteGuard[] = [
    // Only superadmin allowed in the superadmin context
    (path, user) => {
        if (path.startsWith(superadminContext)) return user.superadmin
        return true
    },
    // Owner User allowed only in the common context
    (path, user) => {
        if (user.owner) {
            return path.startsWith(commonPrefix)
        }

        return true
    }
]

export const App: ComponentType<{ pages: PageComponent[] }> = ({ pages }) => {
    const user = useStoreValue(getAppContext().user.store)

    if (!user) return <LoginPage />

    return (
        <AppLayout>
            <Switch>
                {pages.map(p => (
                    <MyRoute
                        exact={true}
                        key={p.path}
                        start={DashBoardPages.Dashboard.to()}
                        guards={guards}
                        path={p.path}
                        comp={p.comp}
                    />
                ))}
                <Redirect to={DashBoardPages.Dashboard.to()} />
            </Switch>
        </AppLayout>
    )
}
