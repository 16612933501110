export const BoxDepositOption = {
  name: 'BoxDepositOption',
  attrs: {
    singleUsePrice: {
      name: 'singleUsePrice',
      id: 'BoxDepositOption.singleUsePrice',
      type: {
        name: 'object',
        of: 'VatPrice'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
