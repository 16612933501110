export const ClientContractRestApiTypes = {
  name: 'ClientContractRestApiTypes',
  attrs: {
    reason: {
      name: 'reason',
      id: 'ClientContractRestApiTypes.reason',
      type: {
        name: 'enum',
        id: 'ContractCancellationRequest$Reason',
        of: [
          'PotrebujemPriestoryBlizsie',
          'NevyhovujeMiCena',
          'NevyhovujeMiVelkostPriestoru',
          'UzNepotrebujem',
          'Ine'
        ]
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'ContractCancellationRequest$Reason'
      }
    }
  }
};
