export const BoxLockInfo = {
  name: 'BoxLockInfo',
  attrs: {
    lockVersion: {
      name: 'lockVersion',
      id: 'BoxLockInfo.lockVersion',
      type: {
        name: 'enum',
        id: 'LockVersion',
        of: ['V1', 'V2']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'LockVersion'
      }
    },
    v1LockCode: {
      name: 'v1LockCode',
      id: 'BoxLockInfo.v1LockCode',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    v2LockEnabled: {
      name: 'v2LockEnabled',
      id: 'BoxLockInfo.v2LockEnabled',
      type: {
        name: 'boolean'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
