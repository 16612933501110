import { apiOps } from '../../api/defs/apiOps'
import { AdminNotificationAuditListItem } from '../../api/defs/AdminNotificationAuditListItem.type'
import { HigherApiClient } from '../../api/client/ApiClient'
import { Page } from '../../model/api/Page'
import { ListReq, NoListFilter } from '../../model/api/ListReq'
import { m } from '../../api/model/model'
import { DayInterval } from '../../api/defs/DayInterval.type'
import { ApiPageResponse } from '../../api/collection/ApiPageRequest'

const ops = apiOps.SuperadminAdminNotificationAudit

export type NotificationFilter = {
    interval: DayInterval
    emailId: string | null
}

export class NotificationAuditApi {
    constructor(private readonly api: HigherApiClient) {}

    adminNotificationAudits = (
        req: ListReq<NotificationFilter>
    ): Promise<Page<AdminNotificationAuditListItem>> =>
        this.api.getPage<AdminNotificationAuditListItem, NoListFilter>(
            ops.adminNotificationAudits,
            m.AdminNotificationAuditListItem,
            req
        )

    list = (
        filter: NotificationFilter
    ): Promise<ApiPageResponse<AdminNotificationAuditListItem>> =>
        this.api.do<ApiPageResponse<AdminNotificationAuditListItem>>(
            ops.adminNotificationAudits,
            {
                queryParams: filter
            }
        )
}
