import React, { ComponentType, ReactNode } from 'react'
import { css } from 'src/ui/style/css'

export type ErrorProps = {
    children: ReactNode
}

export const Error: ComponentType<ErrorProps> = ({ children }) => (
    <span
        css={th => css`
            color: ${th.colors.error};
            font-weight: ${th.fontWeight.thin};
        `}
    >
        {children}
    </span>
)
