export const PodorysBox = {
  name: 'PodorysBox',
  attrs: {
    box: {
      name: 'box',
      id: 'PodorysBox.box',
      type: {
        name: 'object',
        of: 'BoxItem'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    geometry: {
      name: 'geometry',
      id: 'PodorysBox.geometry',
      type: {
        name: 'object',
        of: 'BoxGeometry'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'PodorysBox.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
