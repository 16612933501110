import { apiOps } from 'src/api/defs/apiOps'
import { HigherApiClient } from 'src/api/client/ApiClient'
import { ListReq } from 'src/model/api/ListReq'
import { Page } from 'src/model/api/Page'
import { m } from 'src/api/model/model'
import { FranchiseContact } from 'src/api/defs/FranchiseContact.type'

const ops = apiOps.AdminFranchising

// eslint-disable-next-line @typescript-eslint/ban-types
export type FranchiseContactListFilter = {}

export class FranchiseContactApi {
    constructor(private api: HigherApiClient) {}

    detail = (id: string): Promise<FranchiseContact> =>
        this.api.doForId<FranchiseContact>(ops.detail, m.FranchiseContact, id)

    all = (
        req: ListReq<FranchiseContactListFilter>
    ): Promise<Page<FranchiseContact>> =>
        this.api.getPage<FranchiseContact, FranchiseContactListFilter>(
            ops.getAll,
            m.FranchiseContact,
            req
        )
}
