import { useStoreValue } from '@ps-aux/react-app-core'
import { useCallback } from 'react'
import { Day } from 'src/api/defs/Day.type'
import { Money } from 'src/api/defs/Money.type'
import { strArea } from 'src/business/Warehouse/values/strArea'
import { strDimensions } from 'src/business/Warehouse/values/strDimensions'
import { strDistance } from 'src/business/Warehouse/values/strDistance'
import { getAppContext } from 'src/context/AppContext'
import { formatNum } from 'src/i18n/formats'
import { strDate } from 'src/i18n/lib/format/strDate'
import { strDateTime } from 'src/i18n/lib/format/strDateTime'
import { strDay } from 'src/i18n/lib/format/strDay'
import { strMoney } from 'src/i18n/lib/format/strMoney'
import { strNumber } from 'src/i18n/lib/format/strNumber'
import { strAddress } from '../business/Contract/values/strAddress'
import { Address } from '../api/defs/Address.type'

export const useFormatters = () => {
    const format = useStoreValue(getAppContext().i18n.format)

    const strMoneyWithLocale = useCallback(
        (money: Money, hideCurrency?: boolean) =>
            strMoney(money, format, hideCurrency),
        [format]
    )

    const strDateWithLocale = useCallback(
        (date: Date) => strDate(date, format),
        [format]
    )

    const formatNumWithLocale = useCallback(
        (number: number) => formatNum(number, format),
        [format]
    )

    const strNumberWithLocale = useCallback(
        (number: number) => strNumber(number, format),
        [format]
    )

    const strDateTimeWithLocale = useCallback(
        (date: Date) => strDateTime(date, format),
        [format]
    )

    const strDayWithLocale = useCallback(
        (day: Day) => strDay(day, format),
        [format]
    )

    const strDistanceWithLocale = useCallback(
        (number: number) => strDistance(number, format),
        [format]
    )

    const strAreaWithLocale = useCallback(
        (number: number) => strArea(number, format),
        [format]
    )

    const strDimensionsWithLocale = useCallback(
        dimensions => strDimensions(dimensions, format),
        [format]
    )

    const formatAddress = useCallback(
        (address: Address) => strAddress(address),
        []
    )

    return {
        strMoney: strMoneyWithLocale,
        strDate: strDateWithLocale,
        formatNum: formatNumWithLocale,
        strNumber: strNumberWithLocale,
        strDateTime: strDateTimeWithLocale,
        strDay: strDayWithLocale,
        strDistance: strDistanceWithLocale,
        strArea: strAreaWithLocale,
        strDimensions: strDimensionsWithLocale,
        strAddress: formatAddress
    }
}
