export const SetGeometryCmd = {
  name: 'SetGeometryCmd',
  attrs: {
    data: {
      name: 'data',
      id: 'SetGeometryCmd.data',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'BoxGeometryInput'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
