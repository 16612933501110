export const ERROR_SK = {
    lang: 'sk',
    messages: {
        'error.invalid-credentials': 'Neplatné prihlasovacie údaje',
        'error.invalid-email': 'Neplatná emailová adresa',
        'error.mustBeChecked': 'Musí byť zaškrtnuté',
        'error.passwordsMustMatch': 'Heslá sa musia zhodovať',
        'error.validation/invalid-args': 'Neplatné argumenty',

        'error.revalid/rule/basic/not-null': 'Toto pole je povinné',
        'error.revalid/rule/basic/not-empty': 'Nemôže byť prázdne',
        'error.revalid/rule/basic/regex': 'Nesprávny formát',
        'error.revalid/rule/object/matches-schema': 'Neúplné informácie',

        'error.podorys/invalidGeometryFormat': 'Neplatný formát geometrie',
        'error.Warehouse/invalid-geometry-input': 'Neplatný vstup geometrie',

        'error.lock-v2/too-far-away': 'Nachádzate sa príliš ďaleko od zámku.',

        'error.isGreaterThanZero/invalid': 'Hodnota musí byť väčšia ako 0',

        // Konkrétne chybové kódy z poskytnutého objektu chyby
        'AssertTrue.createBoxCmd.lock.versionWithLockSet':
            'Verzia zámku musí byť pravdivá pre createBoxCmd.',
        'AssertTrue.lock.versionWithLockSet': 'Verzia zámku musí byť pravdivá.',
        'AssertTrue.versionWithLockSet': 'Verzia musí byť pravdivá.',
        'AssertTrue.boolean': 'Toto pole musí byť pravdivé.',
        AssertTrue: 'Hodnota musí spĺňať podmienku.',

        // Argument-specifická chyba
        'lock.versionWithLockSet':
            'Verzia zámku s nastaveným zámkom je neplatná.',

        // Náhradné správy pre neznáme prípady
        'error.unknown': 'Došlo k neznámej chybe.',

        'Internal Server Error': 'Chyba servera'
    }
}
